(function () {
    var urlFactoryModule = angular.module('urlFactory');

    urlFactoryModule.factory('apiurl.CashRegister', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/cashRegister';
        return {
            deleteCashRegisterById: base + '/deleteCashRegisterById/',
            getAllCashRegister: base + '/getAllCashRegister/',
            getCashRegisterById: base + '/getCashRegisterById/',
            createCashRegister: base + '/createCashRegister',
            updateCashRegister: base + '/updateCashRegister'
        };
    }]);

    urlFactoryModule.factory('apiurl.Product', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/product';
        return {
            allProduct: base + '/getAll',
            saveCategoryForProducts: base + '/saveCategoryForProducts',
            saveProduct: base + '/save',
            updateProduct: base + '/update',
            editProduct: base + '/editProduct',
            deleteProduct: base + '/delete',
            deactivateProduct: base + '/deactivate',
            getProductBaseData: base + '/getBaseData',
            getProductCategories: base + '/getProductCategories',
            getProducts: base + '/getProducts',
            getProductStatuses: base + '/getProductStatuses',
        };
    }]);

    urlFactoryModule.factory('apiurl.EnviromentProtectionGroup', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/EnviromentProtectionGroup';
        return {
            getEnviromentProtectionGroup: base + '/GetEnviromentProtectionGroup',
            getEnviromentProtectionGroups: base + '/GetEnviromentProtectionGroups',
            createEnviromentProtectionGroup: base + '/CreateEnviromentProtectionGroup',
            updateEnviromentProtectionGroup: base + '/UpdateEnviromentProtectionGroup',
            deleteEnviromentProtectionGroup: base + '/DeleteEnviromentProtectionGroup',
            getPrdouctSize: base + '/getProductSizes',
            createProductSizes: base + '/createProductSizes',
            updateProductSizes: base + '/updateProductSizes'
        };
    }]);


    urlFactoryModule.factory('apiurl.ContactInteraction', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/contactinteraction';
        return {
            allContactInteractionUrl: base + '/getContactInteractions/',
            saveContactInteractionUrl: base + '/saveContactInteraction/',
            getContactInteractionUrl: base + '/getContactInteraction/',
            deleteContactInteractionUrl: base + '/deleteContactInteraction/',
            getContactInteractionBaseDataUrl: base + '/getContactInteractionBaseData/'
        };
    }]);

    urlFactoryModule.factory('apiurl.InterWarehouse', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/interwarehouse';
        return {
            arrive: base + '/arrive/',
            getOwnerCompany: base + '/getOwnerCompany/',
            getProductsOfWarehouse: base + '/getProductsOfWarehouse/',
            getWarehouses: base + '/getWarehouses',
            takeOutStockMovement: base + '/send',
            getQuantityTypesByProductId: base + '/getQuantityTypesByProductId/',
            getInterWarehouse: base + '/getInterWarehouse/',
        };
    }]);

    urlFactoryModule.factory('apiurl.User', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/user';
        return {
            // Felhaszn�l�
            createUser: base + '/createUser',
            getUserById: base + '/getUserById/',
            getAllUser: base + '/getAllUser/',
            getUserListByUserName: base + '/getUserListByUserName/',
            getUserListByUserIdList: base + '/getUserListByUserIdList/',
            changeOwnPassword: base + '/changeOwnPassword/',
            deleteUserById: base + '/deleteUserById/',
            updateUser: base + '/updateUser',
            // Felhaszn�l�i csoportok
            getAllUserGroup: base + '/getAllUserGroup/',
            deleteUserGroupById: base + '/deleteUserGroupById/',
            createUserGroup: base + '/createUserGroup',
            updateUserGroup: base + '/updateUserGroup',
            getUserGroupById: base + '/getUserGroupById/',
            getAllUserRole: base + '/getAllUserRole/',
            getWarehousesByName: base + '/getWarehousesByName/',
            login: base + '/login/',
        };
    }]);


    urlFactoryModule.factory('apiurl.TaxRateController', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/taxRate';
        return {
            getAllTaxRate: base + '/getAllTaxRate/',
            deleteTaxRateById: base + '/deleteTaxRateById/',
            getTaxRateById: base + '/getTaxRateById/',
            createTaxRate: base + '/createTaxRate',
            updateTaxRate: base + '/updateTaxRate'
        };
    }]);

    urlFactoryModule.factory('apiurl.EmailController', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/email';
        return {
            saveEmailTemplateUrl: base + '/saveEmailTemplate/',
            deleteEmailTemplateUrl: base + '/deleteEmailTemplate/',
            getEmailTemplateUrl: base + '/getEmailTemplate/',
            updateEmailTemplateUrl: base + '/updateEmailTemplate/',
            getEmailTemplatesUrl: base + '/getEmailTemplates/',
            getEmailTypesUrl: base + '/getEmailTypes/',
            sendEmailUrl: base + '/sendEmail/',
            getPartnerNamesUrl: base + '/getPartnerNames/',
            getEmailAddressByPartnerUrl: base + '/getEmailAddressByPartner/',
            getEmailAddressByContactInteractionUrl: base + '/getEmailAddressByContactInteraction/',
            getEmailTemplateListByTemplateNameUrl: base + '/getEmailTemplateListByTemplateName/',
            getDebitOfCompaniesUrl: base + '/getDebitOfCompaniesWithPager/',
            getDebitOfVoucherListUrl: base + '/getDebitOfVoucherList/',
            getDebitOfVoucherListSumUrl: base + '/getDebitOfVoucherListSum/',
            getEmailPreviewViewUrl: base + '/getEmailPreviewViewList/',
            getDebitOfCompaniesSumUrl: base + '/getDebitOfCompaniesSum/'
        };
    }]);

    urlFactoryModule.factory('apiurl.Complain', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/complain';
        return {
            saveComplainUrl: base + '/saveComplain/',
            deleteComplainUrl: base + '/deleteComplain/',
            getComplainUrl: base + '/getComplain/',
            updateComplainUrl: base + '/updateComplain/',
            getComplainTypesUrl: base + '/getComplainTypes/',
            getComplainStatusUrl: base + '/getComplainStatus/',
            getCompanyNamesUrl: base + '/getCompanyNames/',
            getContactNamesUrl: base + '/getContactNames/',
            getComplainListUrl: base + '/getComplainList/',
            actComplainModifiedUrl: base + '/actComplainModified/',
            uploadFileUrl: base + '/uploadFile/',
            downloadFileUrl: base + '/downloadFile/',
            getFileUrl: base + '/getFile/',
            getFilesByComplainIdUrl: base + '/getFilesByComplainId/',
            deleteFileUrl: base + '/deleteFile/',
            getSupportedFileTypeUrl: base + '/getSupportedFileType/'
        };
    }]);

    urlFactoryModule.factory('apiurl.CostCenter', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/costCenter';
        return {
            getAllCostCenter: base + '/getCostCenters/',
            getCostCenter: base + '/getCostCenter/',
            createCostCenter: base + '/createCostCenter/',
            updateCostCenter: base + '/updateCostCenter/',
            getVouchersByCostCenterId: base + '/getVouchersByCostCenterId/',
            costCenterReport: base + '/costCenterReport',
            getProductsByVoucherId: base + '/getProductsByVoucherId',
            getCurrenciesListUrl: base + '/getCurrenciesList/',
            getTotalLimitsByCostCenterIdUrl: base + '/getTotalLimitsByCostCenterId/',
            deleteCostCenterById: base + '/deleteCostCenterById/'
        };
    }]);

    urlFactoryModule.factory('apiurl.Expenditure', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/expenditure';
        return {
            createExpenditure: base + '/createExpenditure'
        };
    }]);

    urlFactoryModule.factory('apiurl.Stock', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/stock';
        return {
            addCustomer: base + '/addCustomer',
            addProduct: base + '/addProduct',
            canRectifyInvoice: base + '/canRectifyInvoice',
            cancelRecifyInvoice: base + '/cancelRecifyInvoice',
            createCustomerOrder: base + '/customerOrder',
            createBid: base + '/createBid',
            createRecifyInvoice: base + '/createRectifyInvoice',
            createInvoiceFromDelivery: base + '/createInvoiceFromDelivery',
            createCustomerOrderFromBid: base + '/customerOrderFromBid',
            deliveryByCompanyId: base + '/deliveryByCompanyId/',
            generateDocument: base + '/generateDocument/',
            generateTestDocument: base + '/generateTestDocument/',
            getCompanyById: base + '/getCompanyById',
            getCompanies: base + '/getCompanies',
            getStockOfWareHouse: base + '/getStockOfWareHouse',
            getProductsOfWarehouse: base + '/getWarehouseProducts',
            getProducts: base + '/getProducts',
            getStockListSum: base + '/getStockListSum',
            getCostCenterByName: base + '/getCostCenter/',
            getProductPrices: base + '/getProductPrices/',
            getSupplierProductsWithName: base + '/getSupplierProductsWithName/',
            getOwnerCompanyDatas: base + '/getOwnerCompanyDatas',
            getBaseDataForTakeIn: base + '/getdataForTakeIn/',
            getBaseDataForTakeOut: base + '/getdataForTakeOut/',
            getOriginalPurchasePricesByProductId: base + '/getOriginalPurchasePricesByProductId',
            invoiceFromMoreDelivery: base + '/invoiceFromMoreDelivery',
            makeStockUnitDtoFromMoreDelivery: base + '/makeStockUnitDtoFromMoreDelivery',
            takeIn: base + '/takeIn',
            takeInInvoiceFromMoreDelivery: base + '/takeInInvoiceFromMoreDelivery',
            takeInStockWithoutStockMoving: base + '/takeInStockWithoutStockMoving',
            takeOut: base + '/takeOut',
            takeOutFromCustomerOrder: base + '/takeOutFromCustomerOrder',
            temporarySave: base + '/teporarySave',
            updateCostCenterOfInvoice: base + '/updateCostCentersOfInvoice',
            updateCustomerOrder: base + '/updatecustomerOrder',
            updateBid: base + '/updateBid',
            updatePrices: base + '/updatePrices/',
            getProductTypes: base + '/getProductTypes',
            getQuantityTypesByProductId: base + '/getQuantityTypesByProductId',
            getNetpriceForQuantityType: base + '/getNetpriceForQuantityType',
            getIncomingVoucherSetting: base + '/getIncomingVoucherSetting'
        };
    }]);

    urlFactoryModule.factory('apiurl.Warehouse', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/warehouse';
        return {
            createWarehouse: base + '/create',
            deleteWarehouse: base + '/deleteWarehouse/',
            getAllWarehouse: base + '/getAllWarehouse',
            getWarehouseById: base + '/getWarehouseById/',
            getOwnerCompanySites: siteBaseurl + '/api/assetDatasheet' + '/getOwnerCompanyAddresses',
            updateWarehouse: base + '/update'
        };
    }]);

    urlFactoryModule.factory('apiurl.Movement', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/movement';
        return {
            getMovementBySearch: base + '/getMovementBySearch',
            saveInterWarehouseDocument: base + '/saveInterWarehouseDocument/',
            saveDocument: base + '/saveDocument/',
            getmovementById: base + '/getmovementById/',
            editMovement: base + '/editMovement/',
            resave: base + '/resave/',
            finalizeMovement: base + '/finalizeMovement/',
        };
    }]);

    urlFactoryModule.factory('apiurl.Order', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/order';
        return {
            reOpenOrderById: base + '/reOpenOrderById',
            saveOrder: base + '/saveOrder',
            updateOrder: base + '/updateOrder',
            saveDocument: base + '/saveDocument',
            getCompaniesByName: base + '/getCompaniesByName/',
            getOrdersWithPager: base + '/getOrdersWithPager',
            updateOrderForPrint: base + '/updateOrderForPrint',
            getWarehousesByName: base + '/getWarehousesByName/',
            getStatuses: base + '/getStatuses',
            getProductsOfSupplier: base + '/getProductsOfSupplier'
        };
    }]);

    urlFactoryModule.factory('apiurl.Partner', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/partner';
        return {
            getAllCompany: base + '/getallcompany',
            getFilteredCompany: base + '/getfilteredcompany',
            editPartner: base + '/edit',
            updatePartner: base + '/update',
            savePartner: base + '/save',
            deleteCompany: base + '/deleteCompany',
            getBaseData: base + '/getBaseData',
            getExchangeRate: base + '/getExchangeRate',
            getAllCompanyWithPager: base + '/getAllCompanyWithPager',
            getOptionSettings: base + '/getOptionSettings/',
            getGroups: base + '/getGroups',
            getContractList: base + '/getContractList',
            existTaxNumberOtherPartner: base + '/existTaxNumberOtherPartner',
            getPartnerStatuses: base + '/getPartnerStatuses'
        };
    }]);

    urlFactoryModule.factory('apiurl.Discount', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/discount';
        return {
            getAllDiscount: base + '/GetAllDiscount',
            editDiscount: base + '/EditDiscount',
            saveDiscountForProduct: base + '/saveDiscountForProduct',
            saveDiscountForCategory: base + '/saveDiscountForCategory',
            updateDiscount: base + '/update',
            deleteDiscount: base + '/deleteDiscount'
        };
    }]);

    urlFactoryModule.factory('apiurl.LifeOfProduct', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/lifeofproduct';
        return {
            getLifeOfProduct: base + '/getLifeOfProduct'
        };
    }]);

    urlFactoryModule.factory('apiurl.ProductCategory', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/productCategory';
        return {
            getAll: base + '/getAll',
            getProductCategoryById: base + '/getProductCategoryById',
            saveProductCategory: base + '/create',
            updateProductCategory: base + '/update',
            deleteProductCategory: base + '/deleteProductCategory',
            updateProductsByProductsCategory: base + '/updateProductsByProductsCategory'
        };
    }]);

    urlFactoryModule.factory('apiurl.Voucher', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/voucher';
        return {
            cancelInvoiceById: base + '/cancelInvoiceByID/',
            deleteVoucherById: base + '/deleteVoucherByID/',
            getVoucherById: base + '/getVoucherById/',
            getVoucherListWithPager: base + '/getVoucherListWithPager/',
            getVoucherListSum: base + '/getVoucherListSum/',
            isIncomingVoucherHasParent: base + '/isIncomingVoucherHasParent/',
            paymentInvoice: base + '/paymentInvoice/',
            getSumRowsByVoucherId: base + '/getSumRowsByVoucherId/',
        }
    }]);

    urlFactoryModule.factory('apiurl.OwnerCompany', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/ownerCompany';
        return {
            uploadFileUrl: base + '/uploadFile/',
            getFileUrl: base + '/getFile/',
            getFilesByOwnerCompanyIdUrl: base + '/getFilesByOwnerCompanyId/',
            deleteFileUrl: base + '/deleteFile/',
            getOwnerCompanies: base + '/getOwnerCompanies/',
            edit: base + '/edit/',
            getFreeWarehouses: base + '/getFreeWarehouses/',
            getFreeCashRegisters: base + '/getFreeCashRegisters/',
            update: base + '/update/',
            save: base + '/save/',
            delete: base + '/delete/',
        }
    }]);

    urlFactoryModule.factory('apiurl.OrderRequest', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/orderRequest';
        return {
            getOrderRequest: base + '/getOrderRequest/',
            getOrderRequests: base + '/getOrderRequests/',
            getWarehouses: base + '/getWarehouses/',
            getCostCenterByWhId: base + '/getCostCenterByWhId/',
            createOrderRequest: base + '/createOrderRequest',
            isExistInPeriod: base + '/isExistInPeriod/',
            updateOrderRequest: base + '/updateOrderRequest',
            getStatuses: base + '/getStatuses',
            getProductsFromProductStock: base + '/getProductsFromProductStock',
            orderFromOrderRequests: base + '/orderFromOrderRequests',
            countedLimit: base + '/countedLimit/'
        }
    }]);

    urlFactoryModule.factory('apiurl.ExcelReport', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/excelReport';
        return {
            getExcelReports: base + '/getExcelReports',
            generateExcelReport: base + '/generateExcelReport/'
        };
    }]);

    urlFactoryModule.factory('apiurl.Inventory', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/inventory';
        return {
            getInventoryList: base + '/getInventoryList/',
            getWarehouse: base + '/getWarehouse/',
            getInventoryItems: base + '/getInventoryItems/',
            getProductsByName: base + '/getProductsByName/',
            getInventory: base + '/getInventory/',
            createInventory: base + '/createInventory/',
            saveInventory: base + '/saveInventory/',
            finalizeInventory: base + '/finalizeInventory/',
            checkProductsQuantitiesAreModified: base + '/checkProductsQuantitiesAreModified/',
            printInventory: base + '/printInventory/'
        };
    }]);

    urlFactoryModule.factory('apiurl.QuantityType', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/quantityType';
        return {
            createQuantityType: base + '/create',
            deleteQuantityType: base + '/deleteQuantityType',
            getAllQuantityType: base + '/getAll',
            updateQuantityType: base + '/update'
        }
    }]);

    urlFactoryModule.factory('apiurl.manufacturingSchedule', ['siteBaseurl', function (siteBaseurl) {
        var base = siteBaseurl + '/api/manufacturingSchedule';

        return {
            scheduleManufacturing: base + '/scheduleManufacturing',
            deleteScheduledManufacturing: base + '/deleteScheduledManufacturing',
            getManufacturingProcessesByProductId: base + '/getManufacturingProcessesByProductId',
            getProbableEndingDateOfManufacturing: base + '/getProbableEndingDateOfManufacturingByVoucherId'
        };
    }]);

    urlFactoryModule.factory('apiurl', [
        'apiurl.CashRegister',
        'apiurl.Product',
        'apiurl.ContactInteraction',
        'apiurl.TaxRateController',
        'apiurl.EnviromentProtectionGroup',
        'apiurl.InterWarehouse',
        'apiurl.EmailController',
        'apiurl.User',
        'apiurl.CostCenter',
        'apiurl.Expenditure',
        'apiurl.Stock',
        'apiurl.Warehouse',
        'apiurl.Movement',
        'apiurl.Order',
        'apiurl.Partner',
        'apiurl.Discount',
        'apiurl.Complain',
        'apiurl.LifeOfProduct',
        'apiurl.ProductCategory',
        'apiurl.Voucher',
        'apiurl.OrderRequest',
        'apiurl.OwnerCompany',
        'apiurl.ExcelReport',
        'apiurl.Inventory',
        'apiurl.QuantityType',
        'apiurl.manufacturingSchedule',
        function (
            cashRegister,
            product,
            contactInteraction,
            taxRateController,
            enviromentProtectionGroup,
            interWarehouse,
            email,
            user,
            costCenter,
            expenditure,
            stock,
            warehouse,
            movement,
            order,
            partner,
            discount,
            complain,
            lifeOfProduct,
            productCategory,
            voucher,
            orderRequest,
            ownerCompany,
            excelReport,
            inventory,
            quantityType,
            manufacturingSchedule
        ) {
            return {
                cashRegister: cashRegister,
                product: product,
                contactInteraction: contactInteraction,
                taxRateController: taxRateController,
                enviromentProtectionGroup: enviromentProtectionGroup,
                interWarehouse: interWarehouse,
                email: email,
                user: user,
                costCenter: costCenter,
                expenditure: expenditure,
                stock: stock,
                warehouse: warehouse,
                movement: movement,
                order: order,
                partner: partner,
                discount: discount,
                complain: complain,
                lifeOfProduct: lifeOfProduct,
                productCategory: productCategory,
                voucher: voucher,
                orderRequest: orderRequest,
                ownerCompany: ownerCompany,
                excelReport: excelReport,
                inventory: inventory,
                quantityType: quantityType,
                manufacturingSchedule: manufacturingSchedule
            };
        }
    ]);
})();
