(function () {

    angular.module('IMasterApp')
        .controller('EnviromentProtectionGroup_controller', EnviromentProtectionGroup_controller)
        .controller('EditEnviromentProtectionGroup_controller', EditEnviromentProtectionGroup_controller);

    EnviromentProtectionGroup_controller.$inject = ['$http', '$scope', 'siteurl', 'apiurl', 'toasterService'];
    function EnviromentProtectionGroup_controller($http, $scope, siteurl, apiurl, toasterService) {
        $scope.EnviromentProtectionGroups = [];

        $http.get(apiurl.enviromentProtectionGroup.getEnviromentProtectionGroups).success(function (data) {
            $scope.EnviromentProtectionGroups = data;
        }).error(function (data) {
            toasterService.errorFromServer(data);
        });

        $scope.editEnviromentProtectionGroup = function (id) {
            location.href = siteurl.editEnviromentProtectionGroup + '/' + id;
        }

        $scope.deleteEnviromentProtectionGroup = function (EnviromentProtectionGroup, index) {
            if (confirm("Biztos hogy törölni kívánja a környezetvédelmi csoportot?")) {
                $http.post(apiurl.enviromentProtectionGroup.deleteEnviromentProtectionGroup + '/' + EnviromentProtectionGroup.Id).success(function (data) {
                    toasterService.successToast("Törlés", "Sikeres törlés!");
                    $scope.EnviromentProtectionGroups.splice(index, 1);
                }).error(function (data) {
                    toasterService.errorFromServer(data);
                });
            }
        }
    }

    EditEnviromentProtectionGroup_controller.$inject = ['$http', '$scope', 'siteurl', 'apiurl', 'toasterService'];
    function EditEnviromentProtectionGroup_controller($http, $scope, siteurl, apiurl, toasterService) {
        var enviromentGroupId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        if (enviromentGroupId == 0) {
            $scope.pageTitle = "Környzetvédelmi csoport létrehozása";
            $scope.EnviromentProtectionGroup = {
                Name: '',
                KTCode: '',
                Price: '',
                Description: '',
                IsDeleted: false
            };
        } else {
            $scope.pageTitle = "Környzetvédelmi csoport módosítása";
            $http.get(apiurl.enviromentProtectionGroup.getEnviromentProtectionGroup + '/' + enviromentGroupId).success(function (data) {
                $scope.EnviromentProtectionGroup = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        }

        $scope.save = function () {
            if (confirm("Biztos hogy menti az adatokat?")) {
                if (enviromentGroupId == 0) {
                    $http.post(apiurl.enviromentProtectionGroup.createEnviromentProtectionGroup, $scope.EnviromentProtectionGroup).success(function (data) {
                        toasterService.saveSuccess();
                        location.href = siteurl.enviromentProtectionGroup;
                    }).error(function (data) {
                        alert(data.Message);
                    });
                } else {
                    $http.post(apiurl.enviromentProtectionGroup.updateEnviromentProtectionGroup, $scope.EnviromentProtectionGroup).success(function (data) {
                        toasterService.saveSuccess();
                        location.href = siteurl.enviromentProtectionGroup;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        }

        $scope.back = function () {
            location.href = siteurl.enviromentProtectionGroup;
        }
    }

})();
