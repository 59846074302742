(function () {

    angular.module('IMasterApp')
        .controller('HomeCtrl', HomeCtrl);

    HomeCtrl.$inject = ['$scope', '$http', 'siteurl', 'apiurl', '$window'];
    function HomeCtrl($scope, $http, siteurl, apiurl, $window) {
        $scope.siteurl = siteurl;

        $scope.initRoles = function () {
            var wid = window.location.href.substring(window.location.href.lastIndexOf("/"));
            if (!((wid == "/Index") || (wid == "/"))) {
                if ($window.sessionStorage.getItem('userid') == null) {
                    window.location.href = siteurl.index;
                }
            }

            if ($scope.ApplicantUser == null) {
                $scope.ApplicantUser =
                {
                    Username: $window.sessionStorage.getItem('username')
                };
                var userID = $window.sessionStorage.getItem('userid');
                $scope.roles = $window.sessionStorage.getItem('roleids');
                if ($scope.roles != null) {
                    $scope.roles = $scope.roles.split(',');
                    $scope.claims = [];
                    for (var i = 0; i < $scope.roles.length; i++) {
                        var v = parseInt($scope.roles[i]) - 1;
                        $scope.claims[v] = true
                    }
                }
            }

            $scope.loggedUser = function () {
                if (userID == null) {
                    return false;
                } else {
                    return true;
                }
            };
        }
    }

})();
