(function () {

    angular.module('IMasterApp')
        .factory('CheckVoucherItems', CheckVoucherItems);

    function CheckVoucherItems() {
        return {
            Check: function (products, voucherData, newTweak) {
                var hasStrictError = false;
                var hasRangeError = false;
                var hasProductIdError = false;
                var hasIllegalStrictError = false;
                var hasUniqueIdentifierError = false;
                var strictProduct = [];

                for (var i = 0; i < products.length; i++) {
                    products[i].LastModified = $('#TweakDatePicker').val() ? $('#TweakDatePicker').val() : products[i].LastModified;

                    if (products[i].ProductId == '') {
                        hasProductIdError = true;
                    }

                    if (products[i].Type.Id === 28 && voucherData.VoucherType !== 49 && voucherData.VoucherType !== 48) {
                        if (products[i].Prefix == '' || products[i].StartNumberOfRange == null || products[i].EndNumberOfRange == null) {
                            hasStrictError = true;
                        }
                        if (!hasStrictError) {
                            if (products[i].StartNumberOfRange > products[i].EndNumberOfRange || products[i].StartNumberOfRange.length != products[i].EndNumberOfRange.length) {
                                hasRangeError = true;
                            }
                            strictProduct.push(products[i]);
                        }
                    }

                    if (products[i].Type.Id === 39 && products[i].Identifier == null) {
                        var status = null;
                        if (voucherData.Status == undefined) {
                            status = voucherData.VoucherStatus;
                        } else {
                            status = voucherData.Status;
                        }
                        if (!((voucherData.VoucherType === 48 && status !== 63) || (voucherData.VoucherType === 49)) && !newTweak) {
                            hasUniqueIdentifierError = true;
                        }
                    }
                }

                if (strictProduct.length > 1) {
                    for (var i = 0; i < strictProduct.length - 1; i++) {
                        for (var j = i + 1; j < strictProduct.length; j++) {
                            if (strictProduct[i].Prefix === strictProduct[j].Prefix && strictProduct[i].ProductId === strictProduct[j].ProductId) {
                                var stnmbr = parseInt(strictProduct[i].StartNumberOfRange);
                                var endnmbr = parseInt(strictProduct[i].EndNumberOfRange);
                                var startNumber = parseInt(strictProduct[j].StartNumberOfRange);
                                var endNumber = parseInt(strictProduct[j].EndNumberOfRange);
                                for (var k = startNumber; k < endNumber + 1; k++) {
                                    if (stnmbr === k || endnmbr === k) {
                                        hasIllegalStrictError = true;
                                    }
                                }
                            }
                        }
                    }
                }

                if (products.length > 0 && !hasStrictError && !hasRangeError && !hasProductIdError && !hasIllegalStrictError && !hasUniqueIdentifierError) {
                    return true;
                } else if (products.length == 0) {
                    alert("Nem adott meg terméket.");
                    return false;
                } else if (hasStrictError) {
                    alert("A szigorú számadású termékek prefixének kezdő- és zárósorszámának kitöltése kötelező.");
                    return false;
                } else if (hasIllegalStrictError) {
                    alert("Ugyanazon a bizonylaton nem szerepelhet ugyanaz a prefix és tartomány (vagy egymást fedő tartományok)");
                    return false;
                } else if (hasRangeError) {
                    alert("Rosszul adta meg a szigorú számadású termék kezdő- és zárósorszámát. A két sorszám hosszának meg kell egyeznie! (pl:001-100)");
                    return false;
                } else if (hasProductIdError) {
                    alert("Termék kiválasztása kötelező!");
                    return false;
                } else if (hasUniqueIdentifierError) {
                    alert("Egyedi azonosító megadása kötelező!");
                    return false;
                }
            }
        }
    }

})();
