angular.module('IMasterApp').factory('PriceCounter', function () {
    return {
        GrossPrice: function (netPrice, vat) {
            if (!isNaN(netPrice) && !isNaN(vat)) {
                var result = netPrice * ((vat / 100) + 1);
                return Number(result.toFixed(2));
            } else {
                return 0;
            }
        },

        VatValue: function (netPrice, quantity, vat) {
            if (!isNaN(netPrice) && !isNaN(quantity) && !isNaN(vat)) {
                var result = netPrice * quantity * (vat / 100);
                return Number(result.toFixed(2));
            } else {
                return 0;
            }
        },

        NetValue: function (netPrice, quantity) {
            if (!isNaN(netPrice) && !isNaN(quantity)) {
                var result = netPrice * quantity;
                return Number(result.toFixed(2));
            } else {
                return 0;
            }
        },

        GrossValue: function (netPrice, quantity, vat) {
            if (!isNaN(netPrice) && !isNaN(quantity) && !isNaN(vat)) {
                var result = netPrice * quantity * ((vat / 100) + 1);
                return Number(result.toFixed(2));
            } else {
                return 0;
            }
        },

        Rounding: function (sumValue) {
            if (!isNaN(sumValue)) {
                if (sumValue >= 0) {
                    var sValue = Number(sumValue.toFixed(0)).toString();
                    var lastNumber = sValue.substring(sValue.length - 1);
                    if (lastNumber == '1' || lastNumber == '6') {
                        return -1;
                    } else if (lastNumber == '2' || lastNumber == '7') {
                        return -2;
                    } else if (lastNumber == '3' || lastNumber == '8') {
                        return 2;
                    } else if (lastNumber == '4' || lastNumber == '9') {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                else {
                    var sValue = Number(sumValue.toFixed(0)).toString();
                    var lastNumber = sValue.substring(sValue.length - 1);
                    if (lastNumber == '1' || lastNumber == '6') {
                        return 1;
                    } else if (lastNumber == '2' || lastNumber == '7') {
                        return 2;
                    } else if (lastNumber == '3' || lastNumber == '8') {
                        return -2;
                    } else if (lastNumber == '4' || lastNumber == '9') {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }
        }
    }
});
