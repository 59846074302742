(function () {

    angular.module('IMasterApp')
        .controller("UserGroup_controller", UserGroup_controller)
        .controller("EditUserGroup_controller", EditUserGroup_controller);

    UserGroup_controller.$inject = ['$http', '$scope', 'siteurl', 'toasterService', 'userGroupService'];
    function UserGroup_controller($http, $scope, siteurl, toasterService, userGroupService) {

        $scope.editUserGroupPageTitle = "";

        userGroupService.getAllUserGroup().success(function (data) {
            $scope.userGroups = data;
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });

        $scope.newUserGroup = function () {
            location.href = siteurl.editUserGroup + "/0";
        };

        $scope.editUserGroup = function (userGroup) {
            location.href = siteurl.editUserGroup + "/" + userGroup.Id;
        };

        $scope.deleteUserGroup = function (userGroup, index) {
            if (confirm("Biztos benne, hogy törli a felhasználói csoportot?")) {
                userGroupService.deleteUserGroupById(userGroup.Id).success(function () {
                    toasterService.saveSuccess();
                    $scope.userGroups.splice(index, 1);
                });
            }
        };
    }

    EditUserGroup_controller.$inject = ['$http', '$scope', 'siteurl', 'toasterService', 'userGroupService'];
    function EditUserGroup_controller($http, $scope, siteurl, toasterService, userGroupService) {

        var userGroupId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        $scope.tags = [];

        if (userGroupId == 0) {
            $scope.editUserGroupPageTitle = "Új felhasználói csoport létrehozása";
            $scope.userGroup = {
                UserGroupName: '',
                UserGroupDescription: '',
            };
        } else {
            $scope.editUserGroupPageTitle = "Felhasználói csoport módosítása";

            userGroupService.getUserGroupById(userGroupId).success(function (data) {
                $scope.userGroup = data;
                $scope.tags = data.UserRoles;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        }


        $scope.save = function () {
            $scope.userGroup.UserRoles = $scope.tags;
            if (confirm("Biztos benne, hogy menti az adatokat?")) {
                if (userGroupId == 0) {
                    userGroupService.createUserGroup($scope.userGroup).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    userGroupService.updateUserGroup(data).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.loadUserRoles = function (query) {
            return userGroupService.getAllUserRole(query);
        };

        $scope.back = function () {
            if (confirm("Biztos benne, hogy elveti a módosításokat és visszalép?")) {
                goBack();
            }
        };

        function goBack() {
            location.href = siteurl.userGroup;
        };
    }

})();
