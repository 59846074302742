
angular.module('IMasterApp').factory('CurrentCreditLineCounter', function () {
    return {
        //Az aktuális hitelkeret kiszámítására
        //aktuális hitelkeret = biztosított hitelkeret - tartozás
        CurrentCredit: function (creditFacilityProvideValue, debitAmount) {
            if (!isNaN(creditFacilityProvideValue) && !isNaN(debitAmount)) {
                var result = creditFacilityProvideValue - debitAmount; //Aktuális hitelkeret
                return Number(result.toFixed(0));
            } else if (!isNaN(creditFacilityProvideValue) && creditFacilityProvideValue !== "") {
                return Number(creditFacilityProvideValue.toFixed(0));
            } else {
                return 0;
            }
        }
    }
});
