(function () {

    angular.module('IMasterApp')
        .controller('inventory_controller', inventory_controller);

    inventory_controller.$inject = ['$scope', 'siteurl', 'apiurl', '$http', 'toasterService'];
    function inventory_controller($scope, siteurl, apiurl, $http, toasterService) {
        // Kereső objektum init
        $scope.search = {
            SortHeaderTitle: '',
            OrderDirection: true,
            PageIndex: 0,
            PageSize: 25,
            SearchText: '',
            IsOpenStatus: false,
            IsCloseStatus: false
        };

        $scope.currentPage = 1;

        // A lista adatainak lekérése
        $scope.getInventoryList = function (sortHeaderTitle) {
            // Fejlécre kattintás ellenőrzés
            if (!angular.isUndefined(sortHeaderTitle)) {
                if (sortHeaderTitle === $scope.search.SortHeaderTitle) {
                    $scope.search.OrderDirection = !$scope.search.OrderDirection;
                }
                else {
                    $scope.search.OrderDirection = true;
                    $scope.search.SortHeaderTitle = sortHeaderTitle;
                }
            }

            // Keresőmező szűrése
            if ($scope.search.SearchText === '' || $scope.search.SearchText.length > 2) {
                $scope.search.PageIndex = $scope.currentPage - 1;
                $http.post(apiurl.inventory.getInventoryList, $scope.search).success(function (data) {
                    $scope.InventoryList = data.Items;
                    $scope.totalItems = data.TotalItems;
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.getInventoryList();

        // Új leltár létrehozása
        $scope.createNewInventory = function () {
            window.location.href = siteurl.editInventory + '/0';
        };

        // Meglévő leltári bizonylat visszanyitása
        $scope.openInventory = function (inventoryId) {
            window.location.href = siteurl.editInventory + '/' + inventoryId;
        };
    }
})();
