(function () {

    angular.module('IMasterApp')
        .filter('convertTypeIdToName', convertTypeIdToName)
        .controller('store_stock_controller', store_stock_controller);

    convertTypeIdToName.$inject = ['$filter'];
    function convertTypeIdToName($filter) {
        return function (typeId, Types) {
            var tmp = $filter('filter')(Types, { Id: typeId })[0];
            return tmp.Name;
        };
    }
    store_stock_controller.$inject = ['$scope', '$http', 'siteurl', 'apiurl', '$q', '$filter', 'usSpinnerService', 'toasterService'];
    function store_stock_controller($scope, $http, siteurl, apiurl, $q, $filter, usSpinnerService, toasterService) {

        $scope.actWarehouse = null;
        $scope.readyToUse = false;
        $scope.productName = '';
        var NORMALPRODUCTID = 26;
        var STRICTPRODUCTID = 28;
        var UNIQUEPRODUCTID = 39;

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = 0;

        spinnerActive = false;

        var startSpin = function () {
            if (!spinnerActive) {
                usSpinnerService.spin('spinner-2');
                spinnerActive = true;
            }
        };

        var stopSpin = function () {
            if (spinnerActive) {
                usSpinnerService.stop('spinner-2');
                spinnerActive = false;
            }
        };

        $scope.searchObj = {
            SortIndex: "",
            OrderDirection: true,
            TypeIdList: [NORMALPRODUCTID, STRICTPRODUCTID, UNIQUEPRODUCTID],
            normalProduct: true,
            strictProduct: true,
            uniqueProduct: true
        };

        var setTypeList = function () {
            $scope.searchObj.TypeIdList = [];

            if ($scope.searchObj.normalProduct) {
                $scope.searchObj.TypeIdList.push(NORMALPRODUCTID);
            }
            if ($scope.searchObj.strictProduct) {
                $scope.searchObj.TypeIdList.push(STRICTPRODUCTID);
            }
            if ($scope.searchObj.uniqueProduct) {
                $scope.searchObj.TypeIdList.push(UNIQUEPRODUCTID);
            }
        };

        $scope.searchStock = function () {
            setTypeList();
            $scope.QueriesInquiry();
        };

        $scope.clear = function () {
            $scope.searchObj.TypeIdList = [NORMALPRODUCTID, STRICTPRODUCTID, UNIQUEPRODUCTID];

            $scope.searchObj.normalProduct = true;
            $scope.searchObj.strictProduct = true;
            $scope.searchObj.uniqueProduct = true;

            $scope.QueriesInquiry();
        };

        $http.get(apiurl.stock.getProductTypes).success(function (response) {
            $scope.productTypes = response;
        });

        $scope.productTypeIsIdentity = function (typeId) {
            var tmp = $filter('filter')($scope.productTypes, { Id: typeId })[0];
            return tmp.Define === 'Egyedi azonosítójú termék';
        };

        $scope.openAssetDatasheet = function (identityId, assetDatasheetId) {
            if (!assetDatasheetId) {
                assetDatasheetId = 0;
            }
            window.location.href = siteurl.assetDatasheet + '/#/assetdatasheet/edit/' + assetDatasheetId + "?identityId=" + identityId;
        };

        function getStockListSum(productSearchData) {

            var promise = $http.post(apiurl.stock.getStockListSum, productSearchData)
                .success(function (data) {
                    $scope.sumTotalNetValue = data.sumTotalNetValue;
                    $scope.sumTotalVatValue = data.sumTotalVatValue;
                    $scope.sumTotalGrossValue = data.sumTotalGrossValue;
                })
                .error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });

            return promise;
        }

        function initSelect(data) {
            $scope.warehouses = [];
            $scope.warehouses = data.Warehouses;
            for (var key in $scope.warehouses) {
                if ($scope.warehouses.hasOwnProperty(key)) {
                    if ($scope.warehouses[key].BillableStatus) {
                        $scope.warehouses[key].Name += " (Továbbszámlázás)";
                    }
                }
            }
            $scope.warehouses.unshift({ "Id": 0, "Name": "Összes" });
            $scope.actWarehouse = $scope.warehouses[0];
        }

        var tmpFunction = function (data) {
            if ($scope.actWarehouse == null) {
                initSelect(data);
            }

            $scope.products = data.StockItems;
            for (var i = 0; i < $scope.warehouses.length; i++) {
                for (var j = 0; j < $scope.products.length; j++) {
                    if (($scope.products[j].WarehouseName == $scope.warehouses[i].Name.replace(' (Továbbszámlázás)', '')) && $scope.warehouses[i].BillableStatus == true) {
                        $scope.products[j].WarehouseName += " (Továbbszámlázás)";
                    }
                }
            }
            $scope.warehouse = data.WarehouseName;
            $scope.currency = "Ft";

            $scope.readyToUse = true;

            $scope.currentPage = data.CurrentPage;
            $scope.pageSize = data.ItemsPerPage;
            $scope.totalPages = data.TotalPages;
            $scope.totalItems = data.TotalItems;
        };

        var searching = function (sortIndex) {
            $(document).ready(function () {
                startSpin();
            });

            var warehouseId = 0;
            if (angular.isObject($scope.actWarehouse)) {
                warehouseId = $scope.actWarehouse.Id;
            }

            var productSearchData = {
                Name: $scope.productName,
                WarehouseId: warehouseId,
                ProductTypeIdList: $scope.searchObj.TypeIdList
            };

            getStockListSum(productSearchData);

            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.searchObj.SortIndex) {
                    $scope.searchObj.OrderDirection = !$scope.searchObj.OrderDirection;
                }
                else {
                    $scope.searchObj.OrderDirection = true;
                    $scope.searchObj.SortIndex = sortIndex;
                }
            }

            $scope.search = {
                Name: $scope.productName,
                WarehouseId: warehouseId,
                PageSize: $scope.pageSize,
                PageIndex: $scope.currentPage,
                SortIndex: $scope.searchObj.SortIndex,
                OrderDirection: $scope.searchObj.OrderDirection,
                ProductTypeIdList: $scope.searchObj.TypeIdList
            };

            var promise = $http.post(apiurl.stock.getStockOfWareHouse, $scope.search)
            .success(tmpFunction)
            .error(function (data, status) {
                toasterService.errorFromServerToast(data);
                console.log("sajat " + data);
                console.log("sajat " + status);
            });

            return promise;
        };

        $scope.setSelectedProduct = function (selected) {
            var warehouseId = 0;
            if (angular.isObject($scope.actWarehouse)) {
                warehouseId = $scope.actWarehouse.Id;
            }
            window.location.href = siteurl.lifeOfProduct + '/?productId=' + selected + '/?warehouseId=' + warehouseId;
        };

        $scope.moveToWarehouseBtn = function (warehouseId) {
            if (warehouseId != 0) {
                location.href = siteurl.moveToWarehouse + "/" + warehouseId;
            }
        };

        $scope.takeOutBtn = function (warehouseId) {
            if (warehouseId != 0) {
                location.href = siteurl.takeOut + "/" + warehouseId;
            }
        }

        //Lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.QueriesInquiry();
        };

        //Termékek számának beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.QueriesInquiry();
            }
        };

        //Keresés
        $scope.changedQuery = function () {
            if ($scope.productName.length > 2) {
                $scope.QueriesInquiry();
            }
            else if ($scope.productName.length < 1 || $scope.productName === '') {
                $scope.QueriesInquiry();
            }
        }

        var getDependences = function (sortIndex) {
            var deffers = [
                searching(sortIndex)
            ];

            return deffers;
        };

        $scope.QueriesInquiry = function (sortIndex) {
            //Betöltődött az egész oldal
            $q.all(getDependences(sortIndex)).then(function () {
                stopSpin();
            });
        };

        $scope.QueriesInquiry();
    }

})();
