(function () {

    angular.module('IMasterApp')
        .controller('ProductCtrl', ProductCtrl)
        .controller('EditProductCtrl', EditProductCtrl);

    ProductCtrl.$inject = ['$scope', '$http', 'siteurl', '$location', '$window', 'apiurl', 'toasterService'];
    function ProductCtrl($scope, $http, siteurl, $location, $window, apiurl, toasterService) {
        $scope.siteurl = siteurl;

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1] : siteurl.editPartner;

        var partnerId = 0;
        $scope.actUrl = siteurl.products;

        if (location.href.indexOf(siteurl.editPartner) !== -1) {
            $scope.actUrl = siteurl.editPartner;
            partnerId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
            var isSupplierOfPartner;
            if ($scope.$parent.$parent.commonSolfOfProductTab === true) {
                isSupplierOfPartner = false;
                $scope.productIncludeCustomPrice = true;
            } else {
                isSupplierOfPartner = true;
                $scope.productIncludeCustomPrice = false;
            }
        }

        $scope.setSelectedProduct = function (selected) {
            if ($scope.actUrl === siteurl.editPartner) {
                $scope.setSelectedLifeOfProduct(selected);
            } else {
                $scope.editProduct(selected);
            }
        }

        $scope.pressEnter = function (ev) {
            $scope.pressed = ev.which;
            if (ev.which == 13)
                $scope.Searching();
        };

        //Termék életútjának elérése
        $scope.setSelectedLifeOfProduct = function (selected) {
            window.location.href = siteurl.lifeOfProduct + '/?fromIsSupplierOfPartner=' + isSupplierOfPartner + '/?productId=' + selected + '?url=' + backUrl;
        };

        var NORMALPRODUCTID = 26;
        var SERVICEPRODUCTID = 27;
        var STRICTPRODUCTID = 28;
        var UNIQUEPRODUCTID = 39;

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = '25';
        var search = angular.fromJson(localStorage.getItem('search'));

        $scope.search = {
            Name: search === null ? '' : search.Name || '',
            TypeIdList: search === null ? [NORMALPRODUCTID, SERVICEPRODUCTID, STRICTPRODUCTID, UNIQUEPRODUCTID] : search.TypeIdList || [NORMALPRODUCTID, SERVICEPRODUCTID, STRICTPRODUCTID, UNIQUEPRODUCTID],
            ItemNumber: search === null ? '' : search.ItemNumber || '',
            PurchasePriceFrom: search === null ? null : search.PurchasePriceFrom || null,
            PurchasePriceTo: search === null ? null : search.PurchasePriceTo || null,
            RetailPriceFrom: search === null ? null : search.RetailPriceFrom || null,
            RetailPriceTo: search === null ? null : search.RetailPriceTo || null,
            WholesalePriceFrom: search === null ? null : search.WholesalePriceFrom || null,
            WholesalePriceTo: search === null ? null : search.WholesalePriceTo || null,
            SupplierId: $scope.actUrl === siteurl.editPartner ? partnerId : null,
            ProductIncludeCustomPrice: $scope.productIncludeCustomPrice === true ? true : null,
            SortIndex: search === null ? '' : search.SortIndex || '',
            OrderDirection: search === null ? true : search.OrderDirection || true,
            normalProduct: search === null ? true : false,
            serviceProduct: search === null ? true : false,
            strictProduct: search === null ? true : false,
            uniqueProduct: search === null ? true : false,
            Inactive: search === null ? false : search.Inactive || false,
            Active: search === null ? true : search.Active || true
        };

        if (search !== null) {
            angular.forEach(search.TypeIdList, function (typeId) {
                switch (typeId) {
                    case NORMALPRODUCTID: $scope.search.normalProduct = true; break;
                    case SERVICEPRODUCTID: $scope.search.serviceProduct = true; break;
                    case STRICTPRODUCTID: $scope.search.strictProduct = true; break;
                    case UNIQUEPRODUCTID: $scope.search.uniqueProduct = true; break;
                }
            });
        }

        $scope.Searching = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.search.SortIndex) {
                    $scope.search.OrderDirection = !$scope.search.OrderDirection;
                }
                else {
                    $scope.search.OrderDirection = true;
                    $scope.search.SortIndex = sortIndex;
                }
                if (search !== null) {
                    search.OrderDirection = $scope.search.OrderDirection;
                    search.SortIndex = $scope.search.SortIndex;
                }
            }
            var params = {
                pageIndex: $scope.currentPage,
                pageSize: $scope.pageSize,
                search: $scope.search,
                categoryId: -1,
            };
            $http.post(apiurl.product.allProduct, $scope.search, { params: params }).success(function (data) {
                $scope.products = data.Items;
                setText();
                $scope.totalPages = [];
                $scope.totalItems = data.TotalItems;
                localStorage.removeItem('search');
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.Searching();
        $scope.newProduct = function () {
            location.href = siteurl.editProduct + "/0";
        }
        $scope.editProduct = function (productId) {
            localStorage.setItem('search', angular.toJson($scope.search));
            location.href = siteurl.editProduct + "/" + productId;
        }

        $scope.searchProduct = function () {
            $scope.setTypeList();
            $scope.Searching();
        };

        $scope.clear = function () {
            $scope.search = {
                Name: '',
                TypeIdList: [NORMALPRODUCTID, SERVICEPRODUCTID, STRICTPRODUCTID, UNIQUEPRODUCTID],
                ItemNumber: '',
                PurchasePriceFrom: null,
                PurchasePriceTo: null,
                RetailPriceFrom: null,
                RetailPriceTo: null,
                WholesalePriceFrom: null,
                WholesalePriceTo: null,
                SupplierId: $scope.actUrl === siteurl.editPartner ? partnerId : null,
                ProductIncludeCustomPrice: $scope.productIncludeCustomPrice === true ? true : null,
                SortIndex: "",
                OrderDirection: true,
                Inactive: false,
                Active: true
            };

            $scope.search.normalProduct = true;
            $scope.search.serviceProduct = true;
            $scope.search.strictProduct = true;
            $scope.search.uniqueProduct = true;

            $scope.Searching();
        };

        $scope.setTypeList = function () {
            $scope.search.TypeIdList = [];

            if ($scope.search.normalProduct) {
                $scope.search.TypeIdList.push(NORMALPRODUCTID);
            }
            if ($scope.search.serviceProduct) {
                $scope.search.TypeIdList.push(SERVICEPRODUCTID);
            }
            if ($scope.search.strictProduct) {
                $scope.search.TypeIdList.push(STRICTPRODUCTID);
            }
            if ($scope.search.uniqueProduct) {
                $scope.search.TypeIdList.push(UNIQUEPRODUCTID);
            }
        };

        $scope.deleteProduct = function (productId, index) {
            if (confirm("A törlés után az adott termékre nem lehet a továbbiakban bevételezést indítani. Kívánja folytatni?")) {
                $http.put(apiurl.product.deleteProduct, productId, { params: { productId: productId } }).success(function () {
                    $scope.products.splice(index, 1);
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.Searching();
        };

        //Termékek számának beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.Searching();
            }
        };

        function setText() {
            for (var i = 0; i < $scope.products.length; i++) {
                if ($scope.products[i].Categories && $scope.products[i].Categories.length > 1) {
                    setPopoverText('popoverCategories', i, $scope.products[i].Categories);
                }

                if ($scope.products[i].QuantityTypes) {
                    setPopoverText('popoverQuantityTypes', i, $scope.products[i].QuantityTypes);
                }

                if ($scope.products[i].Suppliers && $scope.products[i].Suppliers.length > 1) {
                    setPopoverText('popoverSuppliers', i, $scope.products[i].Suppliers);
                }
            }
        }

        function setPopoverText(popoverName, productIndex, list) {
            $scope.products[productIndex][popoverName] = list.join(', ');
        }
    }

    EditProductCtrl.$inject = ['$scope', '$rootScope', '$http', '$q', 'siteurl', 'apiurl', 'toasterService', 'taxRateService'];
    function EditProductCtrl($scope, $rootScope, $http, $q, siteurl, apiurl, toasterService, taxRateService) {
        var productId = 0;
        if (!$rootScope.isFromModal) {
            productId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        }

        $scope.canModifyProductType = true;
        $scope.arraycheck = false;
        $scope.tabClick = 'productdata';

        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null ? backUrl[1] : siteurl.editPartner;

        $scope.product = { TypeId: 26, IsActive: true };
        $scope.barcodeList = [];
        $scope.companyForProductList = [];
        $scope.tags = [];
        $scope.strict = { Id: 28, IsSelected: false };
        $scope.service = { Id: 27, IsSelected: false };
        $scope.identifier = { Id: 39, IsSelected: false };

        $scope.changedNetPrice = function () {
            if ($scope.product.PurchasePrice) {
                $scope.product.PurchasePrice = $scope.product.PurchasePrice.replace(",", ".");
                $scope.product.RetailPrice = ($scope.product.PurchasePrice * (1 + ($scope.product.RetailMargin / 100))).toFixed(2);
                $scope.product.WholesalePrice = ($scope.product.PurchasePrice * (1 + ($scope.product.WholesaleMargin / 100))).toFixed(2);
            }
        };

        $scope.changedRetailPrice = function () {
            if ($scope.product.PurchasePrice && ($scope.product.RetailPrice || $scope.product.RetailPrice === 0)) {
                $scope.product.RetailPrice = $scope.product.RetailPrice.replace(",", ".");
                $scope.product.RetailMargin =
                    Number(((($scope.product.RetailPrice - $scope.product.PurchasePrice) / $scope.product.PurchasePrice) * 100).toFixed(2));
            }
        };

        $scope.changedRetailMargin = function () {
            if ($scope.product.PurchasePrice && ($scope.product.RetailMargin || $scope.product.RetailMargin === 0)) {
                $scope.product.RetailMargin = Number($scope.product.RetailMargin.toString().replace(",", "."));
                $scope.product.RetailPrice = (($scope.product.PurchasePrice * 10 * (1 + ($scope.product.RetailMargin / 100))) / 10).toFixed(2);
            }
        };

        $scope.changedWholesalePrice = function () {
            if ($scope.product.PurchasePrice && ($scope.product.WholesalePrice || $scope.product.WholesalePrice === 0)) {
                $scope.product.WholesalePrice = $scope.product.WholesalePrice.replace(",", ".");
                $scope.product.WholesaleMargin =
                    Number(((($scope.product.WholesalePrice - $scope.product.PurchasePrice) / $scope.product.PurchasePrice) * 100).toFixed(2));
            }
        };

        $scope.changedWholesaleMargin = function () {
            if ($scope.product.PurchasePrice && ($scope.product.WholesaleMargin || $scope.product.WholesaleMargin === 0)) {
                $scope.product.WholesaleMargin = Number($scope.product.WholesaleMargin.toString().replace(",", "."));
                $scope.product.WholesalePrice = (($scope.product.PurchasePrice * 10 * (1 + ($scope.product.WholesaleMargin / 100))) / 10).toFixed(2);
            }
        };

        if (productId != "0") {
            //Edit 
            $http.get(apiurl.product.editProduct + '/' + productId).success(function (data) {
                $scope.TaxRates = [];
                $scope.product = data.Product;
                $scope.canModifyProductType = data.CanModifyProductType;
                $scope.product.CriticalLevels = data.CriticalLevels;
                criticalLevelService.mapCriticalLevels();

                if ($scope.product.TypeId == 27) {
                    $scope.hideSize = true;
                }
                getAllTaxRate();
                $scope.barcodeList = data.BarcodeList;
                $scope.companyForProductList = data.CompanyForProductList;
                $scope.tags = data.CategoryForProductList;
                //View title
                $scope.pageTitle = $scope.product.Name;

                $http.get(apiurl.product.getProductBaseData).success(function (data2) {
                    $scope.supplierList = data2.CompanyList;
                    $scope.quantityTypeList = data2.QuantityTypeList;
                    $scope.product.QuantityTypeSwitchings = data.QuantityTypeSwitchings;
                    quantityService.setActQuantity();
                    supplierService.mapList();

                    if ($scope.product.TypeId == 28) {
                        $scope.strict.IsSelected = true;
                    }
                    if ($scope.product.TypeId == 27) {
                        $scope.service.IsSelected = true;
                    }
                    if ($scope.product.TypeId == 39) {
                        $scope.identifier.IsSelected = true;
                    }

                    //$scope.check = true;
                    if ($scope.product.TypeId == 28) {
                        $scope.arraycheck = true;
                    }
                });
            });
        } else {
            $scope.canModifyProductType = true;
            getAllTaxRate();
            $http.get(apiurl.product.getProductBaseData).success(function (data2) {
                $scope.supplierList = data2.CompanyList;
                $scope.quantityTypeList = data2.QuantityTypeList;
                //View title
                $scope.pageTitle = "Új termék";
                $scope.hideSize = true;
            });
        }

        $scope.product.actQuantity = null;
        $scope.product.actQuantityUnit = null;
        $scope.quantityTypeId = $scope.product.QuantityTypeId;

        //Product Type checkboxes
        $scope.isDisabled = false;
        $scope.$watch("strict.IsSelected", function () {
            if ($scope.strict.IsSelected === true) {
                $scope.service.IsSelected = false;
                $scope.identifier.IsSelected = false;
                $scope.isService = false;
                $scope.isDisabled = true;
            } else {
                $scope.isDisabled = false;
            }
        });

        //Service
        $scope.isService = false;
        $scope.$watch("service.IsSelected", function () {
            if ($scope.service.IsSelected === true) {
                $scope.isService = true;
                $scope.identifier.IsSelected = false;
                $scope.strict.IsSelected = false;
                $scope.product.actQuantityUnit = "";
                $scope.product.ItemNumber = "";
            }
            else if ($scope.service.IsSelected === false) {
                $scope.isService = false;
            }
        });

        $scope.isUniqueId = false;
        $scope.$watch("identifier.IsSelected", function () {
            if ($scope.identifier.IsSelected === true) {
                $scope.isUniqueId = true;
                $scope.service.IsSelected = false;
                $scope.strict.IsSelected = false;
            }
            else if ($scope.service.IsSelected === false) {
                $scope.isUniqueId = false;
            }
        });

        //Barcode
        $scope.barcode = "";
        $scope.addBarcode = function (barcode) {
            if ($scope.barcode !== "") {
                $scope.barcodeList.push({ Barcode: barcode });
                $scope.barcode = "";
            }
        };

        $scope.deleteBarcode = function (index) {
            $scope.barcodeList.splice(index, 1);
        };


        var supplierService = (function () {
            //Supplier
            $scope.supplier = null;

            $scope.addSupplier = function () {
                if (!canAdd()) {
                    return;
                }

                $scope.companyForProductList.push({
                    CompanyId: $scope.supplier.company.Id,
                    CompanyName: $scope.supplier.company.Name,
                    PurchasePrice: $scope.supplier.purchasePrice,
                    Priority: $scope.supplier.priority,
                    CompanyProductName: $scope.supplier.productName,
                    QuantityTypeId: $scope.supplier.quantityType.Id,
                    QuantityType: $scope.supplier.quantityType.Name,
                });

                $scope.supplier = null;
            };

            $scope.deleteSupplier = function (index) {
                $scope.companyForProductList.splice(index, 1);
            };

            return {
                mapList: mapList,
            };

            function mapList() {
                angular.forEach($scope.companyForProductList, function (supplier) {
                    angular.forEach($scope.quantityTypeList, function (quantityType) {
                        if (supplier.QuantityTypeId === quantityType.Id) {
                            supplier.QuantityType = quantityType.Name
                        }
                    });
                });
            }

            function canAdd() {
                if (!$scope.supplier || !$scope.supplier.company) {
                    toasterService.warningToast('', 'A beszállító kötelező!');
                    return false;
                }
                for (var key in $scope.companyForProductList) {
                    if ($scope.companyForProductList[key].CompanyId === $scope.supplier.company.Id) {
                        toasterService.warningToast('', 'Egy termékhez nem lehet hozzáadni ugyan azt a beszállítót többször!');
                        return false;
                    }
                }
                if ($scope.supplier.productName && $scope.supplier.productName.length > 500) {
                    toasterService.warningToast('', "A beszállítói terméknév nem lehet 500 karakternél hosszabb!");
                    return false;
                }
                if (!$scope.supplier.purchasePrice) {
                    toasterService.warningToast('', 'A beszerzési ár kötelező!');
                    return false;
                }
                if (!$scope.supplier.quantityType) {
                    toasterService.warningToast('', 'A mennyiségi egység kötelező!');
                    return false;
                }

                return true;
            }
        })();

        $scope.setProductType = function (selected) {
            if ($scope.strict.IsSelected) {
                selected.TypeId = $scope.strict.Id;
            }
            if ($scope.service.IsSelected) {
                selected.TypeId = $scope.service.Id;
            }
            if ($scope.identifier.IsSelected) {
                selected.TypeId = 39;
            }
            if (!$scope.strict.IsSelected && !$scope.service.IsSelected && !$scope.identifier.IsSelected) {
                selected.TypeId = 26;
            }
        };

        // Az adótípusok lekérése és a legördülő lista beállítása a megnyitott terméknél már beállított értékre (id-ra)
        function getAllTaxRate() {
            taxRateService.getAllTaxRate().success(function (data3) {
                $scope.TaxRates = data3;
                if ($scope.product.TaxId !== undefined) {
                    angular.forEach($scope.TaxRates, function (tr, i) {
                        if (tr.Id == $scope.product.TaxId) {
                            $scope.TaxRate = $scope.TaxRates[i];
                        }
                    });
                } else {
                    $scope.TaxRate = $scope.TaxRates[9];
                }
            }).error(function (data3) {
                toasterService.errorFromServerToast(data3);
            });
        }

        // Handle Product Categories
        $scope.loadCategories = function (query) {
            return $http.get(apiurl.product.getProductCategories + '/' + query);
        };

        $scope.tagAdded = function () {
            console.log("Selected:");
            console.log($scope.tags);
        };

        $scope.tagRemoved = function () {
            console.log("Selected:");
            console.log($scope.tags);
        };

        $scope.productSizes = {
            Id: 0,
            Width: '',
            Height: '',
            Depth: '',
            Weight: '',
            Volume: '',
            ProductId: productId,
            EnviromentProtectionPrice: '',
            EnviromentProtectionGroup: ''
        };

        //Navigation and function buttons
        $scope.backProducts = function () {
            if (!confirm("Biztosan szeretne kilépni?")) {
                return;
            }
            if ($rootScope.isFromModal) {
                $rootScope.isFromModal = false;
                $scope.$parent.$uibModalInstance.close();
            } else {
                location.href = siteurl.products;
            }
        };

        //Új termék mentése
        $scope.saveProduct = function () {
            if (!quantityService.canContinue()) {
                return;
            }

            $scope.setProductType($scope.product);

            $scope.product.TaxId = $scope.TaxRate.Id;
            $scope.product.VAT = $scope.TaxRate.TaxRateValue;
            var pdata = {
                Product: $scope.product,
                BarcodeList: $scope.barcodeList,
                CompanyForProductList: $scope.companyForProductList,
                CategoryForProductList: $scope.tags,
                QuantityTypeSwitchings: quantityService.getQuantityTypeSwitchings()
            };

            if ($scope.product.Id > 0) {
                $http.post(apiurl.product.updateProduct, pdata).success(function () {
                    toasterService.successToast('', 'Sikeresen mentve.');
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                $http.post(apiurl.product.saveProduct, pdata).success(function (response) {
                    toasterService.successToast('', 'Sikeresen mentve.');
                    if ($rootScope.isFromModal) {
                        $rootScope.isFromModal = false;
                        $scope.$parent.$uibModalInstance.close();
                    }
                    else {
                        location.href = siteurl.editProduct + '/' + response;
                    }
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Handle ProductSizes
        $scope.loadProductSizes = function () {
            //Természetvédelmi csoportok lekérése
            $scope.EnviromentProtectionGroups = [];
            $http.get(apiurl.enviromentProtectionGroup.getEnviromentProtectionGroups).success(function (data) {
                $scope.EnviromentProtectionGroups = data;
                $http.get(apiurl.enviromentProtectionGroup.getPrdouctSize + '/' + productId).success(function (data) {
                    if (data.Id == 0) {
                        $scope.productSizes = {
                            Id: 0,
                            Width: '',
                            Height: '',
                            Depth: '',
                            Weight: '',
                            Volume: '',
                            ProductId: productId,
                            EnviromentProtectionPrice: ''
                        };
                    } else {
                        $scope.productSizes = data;
                        for (var index in $scope.EnviromentProtectionGroups) {
                            var obj = $scope.EnviromentProtectionGroups[index];
                            if (obj.Id === $scope.productSizes.EnviromentProtectionGroup.Id) {
                                $scope.productSizes.EnviromentProtectionGroup = obj;
                            }
                        }
                    }
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.saveProductSize = function () {
            if ($scope.product.Note && $scope.product.Note.length > 1000) {
                toasterService.warningToast('', 'A megjegyzés mező maximum 1000 karakter hosszú lehet!');
                return;
            }
            criticalLevelService.mapIntoProductSizes();

            if ($scope.productSizes.Id == 0) {
                $http.post(apiurl.enviromentProtectionGroup.createProductSizes, $scope.productSizes).success(function (data) {
                    toasterService.saveSuccess();
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            } else {
                $http.post(apiurl.enviromentProtectionGroup.updateProductSizes, $scope.productSizes).success(function (data) {
                    toasterService.saveSuccess();
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.calculatePrice = function () {
            if ($scope.productSizes.EnviromentProtectionGroup == null) {
                $scope.productSizes.EnviromentProtectionPrice = '';
            }
            else {
                $scope.productSizes.EnviromentProtectionPrice = Math.round(($scope.productSizes.Weight / 1000) * $scope.productSizes.EnviromentProtectionGroup.Price);
            }
        };

        $scope.calcualteVolume = function () {
            $scope.productSizes.Volume = $scope.productSizes.Depth * $scope.productSizes.Height * $scope.productSizes.Width;
        };

        var quantityService = (function () {
            var tmpQuantityTypeId = 0;
            var quantityTypesDictionary = {};

            $scope.quantityTypeSwitch = null;
            $scope.quantityTypeSwitches = [];

            $scope.quantityTypeChanged = function () {
                $scope.product.QuantityTypeId = $scope.product.actQuantity.Id;
                $scope.product.QuantityTypeName = $scope.product.actQuantity.Name;
            };

            $scope.unitQuantityTypeChanged = function () {
                $scope.product.UnitQuantityTypeId = $scope.product.actQuantityUnit.Id;
                $scope.product.UnitQuantityTypeName = $scope.product.actQuantityUnit.Name;
            };

            $scope.addQuantityTypeSwitch = function () {
                if (!canAddToSwitches()) {
                    return;
                }
                addToSwitches();
                $scope.quantityTypeSwitch = null;
            };

            $scope.removeQuantityTypeSwitch = function (index) {
                $scope.quantityTypeSwitches.splice(index, 1);
            };

            return {
                setActQuantity: setActQuantity,
                canContinue: canContinue,
                getQuantityTypeSwitchings: getQuantityTypeSwitchings,
            };


            //--------- csak függvény definíciók mennek a vonal alá ---------------
            function canAddToSwitches() {
                if (!$scope.product.QuantityTypeId) {
                    toasterService.warningToast('Előbb az alapértelmezett mennyiségi egységet kell kiválasztani!');
                    return false;
                }
                if (!$scope.quantityTypeSwitch) {
                    toasterService.warningToast('Válassza ki az átváltandó mennyiségi egységet!');
                    return false;
                }
                if (!$scope.quantityTypeSwitch.value1 || !$scope.quantityTypeSwitch.value2) {
                    toasterService.warningToast('Adja meg a váltószámot!');
                    return false;
                }
                if ($scope.quantityTypeSwitch.quantityType.Id === $scope.product.QuantityTypeId) {
                    toasterService.warningToast('Az alapértelmezett mennyiségi egységre nem lehet felvenni váltószámot!');
                    return false;
                }
                if (quantityTypeSwitchIsExist($scope.quantityTypeSwitch.quantityType.Id, $scope.product.QuantityTypeId)) {
                    toasterService.warningToast('Már van ilyen váltószám!');
                    return false;
                }

                return true;
            }

            function quantityTypeSwitchIsExist(fromQuantityTypeId, toQuantityTypeId) {
                for (var key in $scope.quantityTypeSwitches) {
                    if ($scope.quantityTypeSwitches[key].quantityTypeId1 === fromQuantityTypeId && $scope.quantityTypeSwitches[key].quantityTypeId2 === toQuantityTypeId
                        || $scope.quantityTypeSwitches[key].quantityTypeId2 === fromQuantityTypeId && $scope.quantityTypeSwitches[key].quantityTypeId1 === toQuantityTypeId) {
                        return true;
                    }
                }
                return false;
            }

            function addToSwitches() {
                pushQuantityTypeSwitches(
                    $scope.product.QuantityTypeId,
                    $scope.quantityTypeSwitch.value1,
                    $scope.quantityTypeSwitch.quantityType.Id,
                    $scope.quantityTypeSwitch.value2
                );
            }

            function setActQuantity() {
                for (var index in $scope.quantityTypeList) {
                    quantityTypesDictionary[$scope.quantityTypeList[index].Id] = $scope.quantityTypeList[index];
                }

                if (quantityTypesDictionary[$scope.product.QuantityTypeId]) {
                    $scope.product.actQuantity = quantityTypesDictionary[$scope.product.QuantityTypeId];
                    tmpQuantityTypeId = $scope.product.QuantityTypeId;
                }
                if (quantityTypesDictionary[$scope.product.UnitQuantityTypeId]) {
                    $scope.product.actQuantityUnit = quantityTypesDictionary[$scope.product.UnitQuantityTypeId];
                }

                for (var innerKey in $scope.product.QuantityTypeSwitchings) {
                    if (!quantityTypesDictionary[$scope.product.QuantityTypeSwitchings[innerKey].FromQuantityTypeId]
                        || !quantityTypesDictionary[$scope.product.QuantityTypeSwitchings[innerKey].ToQuantityTypeId]) {
                        continue;
                    }
                    pushQuantityTypeSwitches(
                        $scope.product.QuantityTypeSwitchings[innerKey].FromQuantityTypeId,
                        $scope.product.QuantityTypeSwitchings[innerKey].FromQuantity,
                        $scope.product.QuantityTypeSwitchings[innerKey].ToQuantityTypeId,
                        $scope.product.QuantityTypeSwitchings[innerKey].ToQuantity
                    );
                }
            }

            function pushQuantityTypeSwitches(id1, value1, id2, value2) {
                $scope.quantityTypeSwitches.push({
                    quantityTypeId1: id1,
                    value1: value1,
                    quantityTypeId2: id2,
                    value2: value2,
                    text: value1 + ' ' + quantityTypesDictionary[id1].Name + ' = ' + value2 + ' ' + quantityTypesDictionary[id2].Name,
                });
            }

            function canContinue() {
                if ($scope.product.TypeId != 27 && !$scope.product.actQuantityUnit) {
                    toasterService.warningToast('', 'Az egységre vetített mennyiségi egység megadása kötelező.');
                    return false;
                }

                if (!$scope.product.actQuantity) {
                    toasterService.warningToast('', 'Az alapértelmezett mennyiségi egység megadása kötelező.');
                    return false;
                }

                if ($scope.product.Id > 0 && !quantityTypeValid()) {
                    return false;
                }

                return true;
            }

            function quantityTypeValid() {
                if ($scope.product.QuantityTypeId === tmpQuantityTypeId) {
                    return true;
                }

                if (!confirm('Ha változtat az alapértelmezett mennyiségi egységen a készlet át lesz számolva. Folytatja?')) {
                    return false;
                }

                if (!quantityTypeSwitchIsExist($scope.product.QuantityTypeId, tmpQuantityTypeId)) {
                    toasterService.warningToast('Előbb rögzíteni kell egy váltószámot az új és a régi mennyiségi egység között!');
                    return false;
                }

                return true;
            }

            function getQuantityTypeSwitchings() {
                var list = [];
                for (var key in $scope.quantityTypeSwitches) {
                    list.push({
                        ProductId: $scope.product.Id,
                        FromQuantityTypeId: $scope.quantityTypeSwitches[key].quantityTypeId1,
                        FromQuantity: $scope.quantityTypeSwitches[key].value1,
                        ToQuantityTypeId: $scope.quantityTypeSwitches[key].quantityTypeId2,
                        ToQuantity: $scope.quantityTypeSwitches[key].value2,
                    });
                }

                return list;
            }
        })();

        var criticalLevelService = (function () {
            $scope.warehouses = [];
            $scope.newCriticalLevel = {};
            $scope.criticalLevels = [];
            $scope.addNewCriticalLevel = addNewCriticalLevel;
            $scope.removeCriticalLevel = removeCriticalLevel;

            init();

            return {
                mapCriticalLevels: mapCriticalLevels,
                mapIntoProductSizes: mapIntoProductSizes,
            };

            //--------- csak függvény definíciók mennek a vonal alá ---------------
            function addNewCriticalLevel() {
                if (!$scope.newCriticalLevel.value) {
                    toasterService.warningToast('A kritikus szint értéke kötelező!');
                    return;
                }
                if ($scope.newCriticalLevel.value < 0) {
                    toasterService.warningToast('A kritikus szint értéke csak pozitív szám lehet!');
                    return;
                }
                if (!$scope.newCriticalLevel.warehouseId) {
                    toasterService.warningToast('A raktár kiválasztása kötelező!');
                    return;
                }
                if ($scope.criticalLevels.some(function (elem) { return elem.warehouseId === $scope.newCriticalLevel.warehouseId; })) {
                    toasterService.warningToast('Már egy kritikus szint hozzá lett adva a kiválasztott raktárhoz!');
                    return;
                }
                pushCriticalLevel($scope.newCriticalLevel.warehouseId, $scope.newCriticalLevel.value);
                $scope.newCriticalLevel = {};
            }

            function removeCriticalLevel(index) {
                $scope.criticalLevels.splice(index, 1);
            }

            function init() {
                loadDependencies();
            }

            function loadDependencies() {
                return $q.all([
                    setWarehouses(),
                ]);
            }

            function setWarehouses() {
                return loadWarehouses().then(function (response) {
                    $scope.warehouses = response;
                });
            }

            function loadWarehouses() {
                return $http.get(apiurl.interWarehouse.getWarehouses).then(function (response) {
                    return response.data;
                });
            }

            function mapCriticalLevels() {
                angular.forEach($scope.product.CriticalLevels, function (criticalLevel) {
                    pushCriticalLevel(criticalLevel.WarehouseId, criticalLevel.CriticLevel);
                });
            }

            function pushCriticalLevel(warehouseId, criticalLevel) {
                $scope.criticalLevels.push({
                    warehouseId: warehouseId,
                    warehouseName: getWarehouseName(warehouseId),
                    value: criticalLevel,
                    quantityType: $scope.product.QuantityTypeName,
                });
            }

            function getWarehouseName(warehouseId) {
                var warehouseName = '';
                angular.forEach($scope.warehouses, function (warehouse) {
                    if (warehouseId === warehouse.Id) {
                        warehouseName = warehouse.Name;
                    }
                });
                return warehouseName;
            }

            function mapIntoProductSizes() {
                var tmp = [];
                angular.forEach($scope.criticalLevels, function (critical) {
                    tmp.push({
                        ProductId: $scope.product.Id,
                        WarehouseId: critical.warehouseId,
                        CriticLevel: critical.value,
                    });
                });

                $scope.productSizes.CriticalLevels = tmp;
                $scope.productSizes.Note = $scope.product.Note;
                $scope.productSizes.DefaultCriticalLevel = $scope.product.CriticLevel;
            }

        })();

        (function () {
            $scope.productStatuses = [];

            init();

            //--------- csak függvény definíciók mennek a vonal alá ---------------
            function init() {
                getStatuses();
            }

            function getStatuses() {
                loadStatuses().then(function (response) {
                    $scope.productStatuses = response;
                    for (var i = 0; i < $scope.productStatuses.length; i++) {
                        var elem = $scope.productStatuses[i];

                        if (elem.Define == 'Aktív' && elem.Department == 'ProductStatus') {
                            $scope.product.StatusId = elem.Id;
                        }
                    }
                });
            }

            function loadStatuses() {
                return $http.get(apiurl.product.getProductStatuses).then(function (response) {
                    return response.data;
                });
            }
        })();
    }

})();
