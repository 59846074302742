(function () {

    angular.module('IMasterApp')
        .controller("QuantityTypeCtrl", QuantityTypeCtrl);

    QuantityTypeCtrl.$inject = ['$scope', '$http', 'apiurl', 'toasterService'];
    function QuantityTypeCtrl($scope, $http, apiurl, toasterService) {
        //Mennyiségi egységek lekérése
        $scope.getAllQuantityTypes = function () {
            $http.get(apiurl.quantityType.getAllQuantityType).success(function (data) {
                $scope.quantityTypes = data;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }
        $scope.getAllQuantityTypes();

        //Mennyiségi egységek felvitele
        $scope.newQuantityType = "";
        $scope.quantityType = { Id: '', Name: '' };
        $scope.addCard = function () {
            if ($scope.newQuantityType !== "") {
                var exist = false;
                angular.forEach($scope.quantityTypes, function (value, key) {
                    if (angular.lowercase(value.Name) === angular.lowercase($scope.newQuantityType)) {
                        exist = true;
                    }
                });
                if (!exist) {
                    $http.post(apiurl.quantityType.createQuantityType, { Id: "", Name: $scope.newQuantityType }).success(function () {
                        $scope.newQuantityType = "";
                        $scope.getAllQuantityTypes();
                    }).error(function (data, status) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    toasterService.warningToast("Figyelem", "Már létezik ilyen mennyiségi egység!");
                }


            }
        }

        //Mennyiségi egység törlése
        $scope.deleteCard = function (quantity) {
            if (confirm("Biztosan törli a " + quantity.Name + " mennyiségi egységet?")) {
                $http.delete(apiurl.quantityType.deleteQuantityType + "/" + quantity.Id).success(function () {
                    $scope.getAllQuantityTypes();
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }

        //Mennyiségi egység módosítása
        $scope.quantityType.modVisibility = false;
        $scope.editCard = function (quantity) {
            quantity.modVisibility = true;
            quantity.modName = quantity.Name;
        }

        //Mennyiségi egység módosítás mentése
        $scope.saveCard = function (quantity) {
            quantity.modVisibility = false;
            if (quantity.modName !== "") {
                var exist = false;
                if (angular.lowercase(quantity.Name) !== angular.lowercase(quantity.modName)) {
                    angular.forEach($scope.quantityTypes, function (value, key) {
                        if (angular.lowercase(value.Name) === angular.lowercase(quantity.modName)) {
                            exist = true;
                        }
                    });
                }
            }

            if (!exist && angular.lowercase(quantity.Name) !== angular.lowercase(quantity.modName)) {
                $http.put(apiurl.quantityType.updateQuantityType, { Id: quantity.Id, Name: quantity.modName }).success(function () {
                    $scope.getAllQuantityTypes();
                }).error(function (data, status) {
                    console.log(data.Message);
                    console.log(status);
                });
            }
            if (exist) {
                toasterService.warningToast("Figyelem", "Már létezik ilyen mennyiségi egység!");
            }
        }
    }

})();
