(function () {
    // A  megadott költséghelyek ellenőrzése a termékekhez és magához a számlához

    angular.module('IMasterApp')
        .factory('CheckCostCenters', CheckCostCenters);

    CheckCostCenters.$inject = ['toasterService'];
    function CheckCostCenters(toasterService) {
        return {
            // Bemenő paraméterek: A terméklista, a számlához tartozó költséghelyek valamint egy dictionary, 
            // amit továbbítunk a szerver felé viszont itt állítunk össze
            Check: function (products, voucherCostCenters, costCenterDictionary) {

                // Init
                var costCenterError = false;
                var allCostCenterHasGotName = true;

                // A számlához tartozó költséghelyek ellenőrzése
                if (voucherCostCenters.length !== 0) {
                    var sumLoadingRate = 0;

                    // Megnézzük minden számlához adott költséghelyhez lett-e adva név
                    for (var i = 0; i < voucherCostCenters.length; i++) {
                        if (voucherCostCenters[i].Name === "") {
                            allCostCenterHasGotName = false;
                            break;
                        }
                    }
                    // Ha nem, akkor hibaüzenetet dobunk toasterrel és visszatérünk
                    if (!allCostCenterHasGotName) {
                        toasterService.errorToast("Költséghely hiba a számlánál", "Nem lehet lezárni úgy a számlát, hogy nincs minden számlához tartozó költséghely megfelelően kitöltve!");
                        return true;
                    }

                    // Ha ki van töltve minden, akkor megnézzük, hogy helyesen-e
                    angular.forEach(voucherCostCenters, function (costCenterItem) {
                        // Ha valamelyik költséghelyhez 0 vagy negatív érték lett megadva, akkor hiba és visszatérünk
                        if (costCenterItem.loadingRate <= 0) {
                            toasterService.errorToast('Költséghely hiba a számlánál', 'Nem adhat meg negatív terhelési költséget és nem lehet 0!');
                            costCenterError = true;
                            return;
                        }

                        // Egyébként összegezzük a terhelési költségeket
                        sumLoadingRate += costCenterItem.loadingRate;

                        // Ha a költséghelyként egy nem létező lett megadva, akkor hiba és visszatérünk
                        if (costCenterItem.Id === undefined) {
                            toasterService.errorToast('Költséghely hiba a számlánál', 'Adjon meg egy létező költséghelyet!');
                            costCenterError = true;
                            return;
                        } else {
                            // Egyébként bekerül a dictionarybe ez a költséghely
                            costCenterDictionary[costCenterItem.Id] = costCenterItem.loadingRate;
                        }
                    });
                    // A vezérléshez szükséges
                    if (costCenterError) {
                        return true;
                    }
                    // A terhelési költségek összegének az ellenőrzése
                    sumLoadingRate = parseFloat(sumLoadingRate);
                    switch (true) {
                        case sumLoadingRate === 0: toasterService.errorToast('Költséghely hiba a számlánál', 'A terhelési költség nem lehet 0, vagy nem adta meg!'); return true;
                        case sumLoadingRate < 100.00: toasterService.errorToast('Költséghely hiba a számlánál', 'A terhelési költség nem éri el a 100%-ot!'); return true;
                        case sumLoadingRate > 100.00: toasterService.errorToast('Költséghely hiba a számlánál', 'A terhelési költség meghaladja a 100%-ot!'); return true;
                    }
                }

                // A termékekhez tartozó költséghelyek ellenőrzése
                var allCostCenterForProductsHasGotName = true;
                angular.forEach(products, function (product) {
                    product.CostCenterIdentities = {};
                    var sumProductLoadingRate = 0;
                    if (product.CostCenters.length !== 0) {
                        // Megnézzük minden termékekhez tartozó költséghely neve ki lett-e töltve
                        for (var i = 0; i < product.CostCenters.length; i++) {
                            if (product.CostCenters[i].Name === "") {
                                allCostCenterForProductsHasGotName = false;
                                break;
                            }
                        }
                        // Ha nem, akkor hibát dobunk és visszatérünk
                        if (!allCostCenterForProductsHasGotName) {
                            toasterService.errorToast("Költséghely hiba a termékeknél", "Nem lehet lezárni úgy a számlát, hogy nincs minden termékekhez tartozó költséghely megfelelően kitöltve!");
                            costCenterError = true;
                            return;
                        }
                        // Ha jól vannak kitöltve, akkor ellenőrizzük az adatokat
                        angular.forEach(product.CostCenters, function (costCenterItem) {
                            // Először a terhelési költség ellenőrzése történik, amely nem lehet 0 vagy negatív, ha hiba van akkor visszatérünk
                            if (costCenterItem.loadingRate <= 0) {
                                toasterService.errorToast('Költséghely hiba a következő terméknél : ' + product.Name, 'Nem adhat meg negatív terhelési költséget és nem lehet 0!');
                                costCenterError = true;
                                return;
                            }
                            // A termékekhez tartozó összes terhelési költség
                            sumProductLoadingRate += costCenterItem.loadingRate;
                            // Ha egy nem létező költséghely lett megadva, akkor hibát dobunk és visszatérünk
                            if (costCenterItem.Id === undefined) {
                                toasterService.errorToast('Költséghely hiba a következő terméknél : ' + product.Name, 'Adjon meg egy létező költséghelyet!');
                                costCenterError = true;
                                return;
                            } else {
                                // Egyébként pedig hozzáadjuk a dictionaryhez
                                product.CostCenterIdentities[costCenterItem.Id] = costCenterItem.loadingRate;
                            }
                        });
                        // Ha nincs hiba, akkor ellenőrizzük a termékekhez tartozó terhelési költségeket
                        if (!costCenterError) {
                            sumProductLoadingRate = parseFloat(sumProductLoadingRate);
                            switch (true) {
                                case sumProductLoadingRate === 0: toasterService.errorToast('Költséghely hiba a következő terméknél : ' + product.Name, 'A terhelési költség nem lehet 0, vagy nem adta meg!'); costCenterError = true; return true;
                                case sumProductLoadingRate < 100.00: toasterService.errorToast('Költséghely hiba a következő terméknél : ' + product.Name, 'A terhelési költség nem éri el a 100%-ot!'); costCenterError = true; return true;
                                case sumProductLoadingRate > 100.00: toasterService.errorToast('Költséghely hiba a következő terméknél : ' + product.Name, 'A terhelési költség meghaladja a 100%-ot!'); costCenterError = true; return true;
                            }
                        }
                    }
                });
                return costCenterError;
            }
        }
    }

})();
