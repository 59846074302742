(function () {

    angular.module('IMasterApp')
        .factory('GetExistingVoucher', GetExistingVoucher)
        .factory('MakeInvoiceFromMoreDelivery', MakeInvoiceFromMoreDelivery)
        .factory('MapObject', MapObject)
        .controller('stock_tweak_controller', stock_tweak_controller);

    GetExistingVoucher.$inject = ['$http', 'apiurl'];
    function GetExistingVoucher($http, apiurl) {
        return {
            service: function (tId) {
                return $http.get(apiurl.voucher.getVoucherById + tId);
            }
        }
    }

    MakeInvoiceFromMoreDelivery.$inject = ['$http', 'apiurl'];
    function MakeInvoiceFromMoreDelivery($http, apiurl) {
        return {
            service: function (sendNoteList) {
                return $http.post(apiurl.stock.makeStockUnitDtoFromMoreDelivery, sendNoteList);
            }
        }
    }

    function MapObject() {
        return {
            OwnerCompanyToVoucherDto: function (ownerCompanyToReceiptAndTweakDto, stockVoucherDto) {

                stockVoucherDto.VendorName = ownerCompanyToReceiptAndTweakDto.LongName;
                stockVoucherDto.VendorAddress = ownerCompanyToReceiptAndTweakDto.HomeAddress;
                stockVoucherDto.VendorTaxNumber = ownerCompanyToReceiptAndTweakDto.TaxNumber;
                stockVoucherDto.VendorEUTaxNumber = ownerCompanyToReceiptAndTweakDto.EuTaxNumber;
                stockVoucherDto.VendorBankAccount = ownerCompanyToReceiptAndTweakDto.OwnerCompanyBankAccounts
                                                    && ownerCompanyToReceiptAndTweakDto.OwnerCompanyBankAccounts.length > 0
                                                        ? ownerCompanyToReceiptAndTweakDto.OwnerCompanyBankAccounts[0].BankAccount
                                                        : '';

                stockVoucherDto.VendorZip = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.ZipCode;
                stockVoucherDto.VendorCity = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.City;
                stockVoucherDto.VendorDistrict = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.District;
                stockVoucherDto.VendorPubliclyOwnedLandName = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.PubliclyOwnedLandName;
                stockVoucherDto.VendorPubliclyOwnedLandType = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.PubliclyOwnedLandType;
                stockVoucherDto.VendorHouseNumber = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.HouseNumber;
                stockVoucherDto.VendorBuilding = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.Building;
                stockVoucherDto.VendorStairway = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.Stairway;
                stockVoucherDto.VendorFloor = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.Floor;
                stockVoucherDto.VendorDoor = ownerCompanyToReceiptAndTweakDto.HomeAddressObject.Door;
            }
        };
    }

    stock_tweak_controller.$inject = ['$scope', '$http', '$filter', 'PriceCounter', 'CurrentCreditLineCounter', 'CheckVoucher', 'CheckVoucherItems', 'siteurl', 'apiurl', '$window', 'GetExistingVoucher', 'MakeInvoiceFromMoreDelivery', 'toasterService', 'CheckCostCenters', 'userService', 'taxRateService', 'MapObject', 'browserModalService', 'manufacturingScheduleTweakService'];
    function stock_tweak_controller($scope, $http, $filter, PriceCounter, CurrentCreditLineCounter, CheckVoucher, CheckVoucherItems, siteurl, apiurl, $window, GetExistingVoucher, MakeInvoiceFromMoreDelivery, toasterService, CheckCostCenters, userService, taxRateService, MapObject, browserModalService, manufacturingScheduleTweakService) {
        var backUrl = (/url=([^&=]+)/g).exec(location.search);
        backUrl = backUrl !== null
                ? backUrl[1]
                : siteurl.salesCertification;
        //ID  lekérése a URL-ből
        var tId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
        var userId = $window.sessionStorage.getItem('userid');

        var OUTGOING_STATUS = 41;
        var TRANSFER_PAYMENTTYPE = 21;

        var isTakeIn = false;

        //init
        $scope.newTweak = false;
        $scope.disabledElement = false;
        $scope.actCompany = null;
        $scope.actWarehouse = null;
        $scope.stornoBtnDis = true;
        $scope.isFromMoreDelivery = false;
        $scope.isFromMoreDeliveryToHideCorrection = false;
        $scope.isCorrecterBill = false;
        var runningTakeOutStockMethod = false;
        $scope.IsPaperFrom = false;
        $scope.pricesVisible = false;
        $scope.actualUser = "";
        $scope.costCenters = [];
        $scope.disableTaxRate = false;
        $scope.isFromMoreIncomingDelivery = false;
        $scope.isFromOrder = false;
        $scope.isClone = false;

        $scope.allManufacturingProcessesByProducts = [];
        $scope.probableEndingDateOfManufaturing = null;

        //Regisztrált felhasználók letöltése
        $scope.Users = [];
        $http.get(apiurl.user.getAllUser).success(function (data) {
            for (var i = 0; i < data.length; i++) {
                $scope.Users.push({
                    Id: data[i].UserId,
                    Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                    UserName: data[i].Username
                });
                //új értékesítés
                if (isNewTweak()) {
                    if (data[i].UserId == userId) {
                        $scope.actualUser = {
                            Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                            UserName: data[i].Username,
                            Id: data[i].UserId
                        }
                    }
                }
            }
            //ilyen esetben adminnal vagyok belépve
            if (angular.isUndefined($scope.actualUser) || $scope.actualUser == "") {
                userService.getUserById(userId).success(function (data2) {
                    var adminUser = {
                        Name: data2.UserFirstname + ' ' + data2.UserLastname,
                        UserName: data2.Username,
                        Id: data2.UserId
                    }
                    //új értékesítés
                    if (isNewTweak()) {
                        $scope.actualUser = adminUser;
                    }
                    $scope.Users.push(adminUser); //ez a felhasználó az admin, szóval nem kell rá ellenőrzés, hogy a lista tartalmazza-e
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });

        //felhasználók közötti keresés
        $scope.searchUsers = function (nameValue) {
            if ($scope.Users.length == 0) return;

            return Enumerable.From($scope.Users).Where(function (x) { return x.UserName.toLowerCase().indexOf(nameValue) != -1 || x.Name.toLowerCase().indexOf(nameValue) != -1 }).Select(function (x) { return x }).ToArray();
        };

        //Áfa-k letöltése
        taxRateService.getAllTaxRate().success(function (data) {
            $scope.TaxRates = data;
        });

        function isNewTweak() {
            return (tId === "Tweak" && (localStorage.getItem('sendNoteList') == null || localStorage.getItem('sendNoteList').length <= 2)) || tId === "";
        }

        //Alapadatok feltöltése
        function loadTweak() {
            if (isNewTweak()) {
                //----------------------------------Új értékesítési bizonylat készítése
                //Láthatóság
                $scope.disabledElement = false;
                $scope.stornoBtnDis = true;
                //Státusz
                $scope.SalesRadio = "Bill";
                //Init
                //Új
                $scope.Users = [];
                $scope.newTweak = true;
                $scope.voucherData = {
                    VoucherNumber: '',
                    VendorName: '',
                    VendorAddress: '',
                    VendorBankAccount: '',
                    VendorTaxNumber: '',
                    VendorEUTaxNumber: '',
                    CustomerName: '',
                    CustomerAddress: '',
                    CustomerDeliveryAddress: '',
                    CustomerBankAccount: '',
                    CustomerTaxNumber: '',
                    CustomerEUTaxNumber: '',
                    BuyerPersonName: '',
                    BuyerPersonTaxNumber: '',
                    IssueDate: '',
                    FulfillmentDate: '',
                    DeadlineDate: '',
                    LanguageTypeId: '',
                    CurrencyTypeId: '',
                    PaymentTypeId: '',
                    ContinuousTransaction: false,
                    IsPaid: true,
                    CashDesk: null,
                    RoundingDiff: 0,
                    CreationOwner: '',
                    DiscountType: 0,
                    DiscountAmount: 0,
                    DiscountTypeName: '%',
                    ValidityDeadline: null,
                    CreationOwnerUserId: '',
                    ItHasTasks: false,
                    ProjectName: '',
                    UndertakeManufacturingDate: null
                };

                $scope.disableFinishBtn = false;

                //Saját cég lekérdezése
                $http.get(apiurl.stock.getOwnerCompanyDatas).success(function (data) {
                    if (data !== null) {
                        $scope.OwnerCompanyBankAccounts = data.OwnerCompanyBankAccounts;
                        MapObject.OwnerCompanyToVoucherDto(data, $scope.voucherData);
                    }
                });

                //További adatok lekérése (Raktárak, nyelv, Pénznem, fizetési mód), default beállítások
                $http.get(apiurl.stock.getBaseDataForTakeOut + userId).success(function (data) {
                    $scope.Languages = data.Languages;
                    $scope.Language = $scope.Languages[0];

                    $scope.Currencies = data.Currencies;
                    $scope.Currency = $scope.Currencies[0];

                    //Az alap fizetési módok
                    $scope.PaymentTypes = data.PaymentTypes;
                    $scope.PaymentType = $scope.PaymentTypes[0];

                    //Az összes fizetési mód eltárolása, szükséges a nyugtás választáshoz
                    $scope.TempPaymentTypes = data.PaymentTypes;

                    //A nyugtához szükséges fizetési módok
                    $scope.VoucherPaymentTypes = [];
                    $scope.VoucherPaymentTypes[0] = angular.copy(data.PaymentTypes[0]);
                    $scope.VoucherPaymentTypes[1] = angular.copy(data.PaymentTypes[2]);

                    $scope.CustomerOrderStatuses = [];
                    angular.forEach(data.CustomerOrderStatuses, function (st) {
                        if (st.Level < 5) {
                            $scope.CustomerOrderStatuses.push(st);
                        }
                    });
                    $scope.customerOrderStatus = $scope.CustomerOrderStatuses[0];

                    $scope.BidStatuses = [];
                    angular.forEach(data.BidStatuses, function (st) {
                        if (st.Level != null && st.Level < 6) {
                            $scope.BidStatuses.push(st);
                        }
                    });
                    $scope.bidStatus = $scope.BidStatuses[0];

                    $scope.Warehouses = data.UserData.Warehouses;
                    $scope.defaultWarehouse = data.UserData.DefaultWarehouse;
                    $scope.actWarehouse = getDefaultOrFirstIfAny(data.UserData.DefaultWarehouse, data.UserData.Warehouses);
                    if ($scope.actWarehouse.CostCenters.length > 0) {
                        var percentage = Number((100 / $scope.actWarehouse.CostCenters.length).toFixed(0));
                        var error = Number((100 % $scope.actWarehouse.CostCenters.length).toFixed(0));
                        angular.forEach($scope.actWarehouse.CostCenters, function (costCenter) {
                            var tempCostCenter = {
                                Id: costCenter.Id,
                                Name: costCenter.Name,
                                loadingRate: percentage
                            };
                            $scope.costCenters.push(tempCostCenter);
                        });

                        $scope.costCenters[0].loadingRate += error;
                    }

                    $scope.cashRegisters = data.UserData.CashRegisters;
                    $scope.cashRegister = getDefaultOrFirstIfAny(data.UserData.DefaultCashRegister, data.UserData.CashRegisters);
                    ////Miután betöltődtek az alapadatok, beállítjuk a formnak a pristine állapotot
                    //$scope.tweakForm.$setPristine();
                });
            } else {
                if (localStorage.getItem('sendNoteList') != null && localStorage.getItem('sendNoteList').length > 2) {
                    $scope.deliveryIdList = JSON.parse(localStorage.getItem('sendNoteList'));
                    resultVoucher = MakeInvoiceFromMoreDelivery.service($scope.deliveryIdList);
                    localStorage.removeItem('sendNoteList');
                    $scope.isFromMoreDelivery = true;
                }
                else {
                    $scope.disabledElement = true;
                    $scope.customerOrderDisabled = true;
                    $scope.bidStatusDisabled = true;
                    $scope.isTakeInBill = false;
                    $scope.isTakeInTrans = false;
                    var resultVoucher = null;
                    resultVoucher = GetExistingVoucher.service(tId);
                }
                //-------------------------------------- Értékesítési bizonylat visszanyitása
                if (resultVoucher !== null) {
                    resultVoucher.success(function (data) {
                        //id-k lekérése
                        $scope.paymentId = data.PaymentTypeId;
                        $scope.currId = data.CurrencyId;
                        $scope.langId = data.LanguageId;
                        $scope.cashRegisterId = data.CashRegister;

                        //Státusz beállítás
                        switch (data.VoucherType) {
                            case 46: //Számla
                                $scope.SalesRadio = "Bill";
                                break;
                            case 47: //Szállítólevél
                                $scope.SalesRadio = "Trans";
                                break;
                            case 45: //Nyugta
                                $scope.SalesRadio = "Voucher";
                                break;
                            case 48: //Vevői megrendelés
                                $scope.SalesRadio = "Order";
                                break;
                            case 49: //Árajánlat
                                $scope.SalesRadio = "Bid";
                                break;
                            case 57: //Papír alapú számla
                                $scope.SalesRadio = "Bill";
                                $scope.IsPaperFrom = true;
                                break;
                            case 58: //Papír alapú szállítólevél
                                $scope.SalesRadio = "Trans";
                                $scope.IsPaperFrom = true;
                                break;
                            case 59: //Papír alapú vevői megrendelés
                                $scope.SalesRadio = "Order";
                                $scope.IsPaperFrom = true;
                                break;
                            case 60: //Papír alapú árajánlat
                                $scope.SalesRadio = "Bid";
                                $scope.IsPaperFrom = true;
                                break;
                            default: //Egyéb státusz
                                $scope.SalesRadio = "Bill";
                                break;
                        }

                        if (data.VoucherType === 46 || data.VoucherType === 53 || data.VoucherType === 57) {
                            $scope.stornoBtnDis = false;
                        }

                        if (data.Status === 37 && data.VoucherType === 46) {
                            $scope.isTakeInBill = true;

                            // Mivel ez egy bevételi számla, ezért ellenőrizzük, hogy bevételi szállítólevélből lett-e átforgatva
                            $http.get(apiurl.voucher.isIncomingVoucherHasParent + tId).success(function (data2) {
                                data2 === "true" ? $scope.isFromMoreIncomingDelivery = true : $scope.isFromMoreIncomingDelivery = false;
                            });
                        }

                        if (data.Status === 37 && (data.VoucherType === 47 || data.VoucherType === 58)) {
                            $scope.isTakeInTrans = true;
                        }

                        if (data.Note !== null && data.Note.indexOf('Szállítólevelek száma:') === 0 && data.Note.indexOf('SZLEV_') > 0) {
                            $scope.isFromMoreDeliveryToHideCorrection = true;
                        }

                        if (data.VoucherType === 53) {
                            $scope.isCorrecterBill = true;
                        }

                        //Raktár beállítás
                        $scope.actWarehouse = data.WareHouse;

                        //Cégadatok beállítása
                        $scope.actCompany = data.ActCompany;
                        $scope.selectedCompany = data.CustomerName;

                        //Pénztár beállítása
                        $scope.cashRegister = data.CashRegister;

                        //Alapadatok feltöltése
                        $scope.voucherData = data;
                        $scope.voucherData.VendorBankAccount = data.VendorAccountNumber;
                        $scope.voucherData.VendorEUTaxNumber = data.VendorEuTaxNumber;
                        $scope.voucherData.CustomerName = '';
                        $scope.voucherData.CustomerDeliveryAddress = data.CustomerShipAddress;
                        $scope.voucherData.CustomerBankAccount = data.CustomerAccountNumber;
                        $scope.voucherData.CustomerEUTaxNumber = data.CustomerEuTaxNumber;
                        $scope.voucherData.BuyerPersonName = data.CustomerPersonName;
                        $scope.voucherData.BuyerPersonTaxNumber = data.CustomerPersonTaxNumber;
                        $scope.voucherData.DiscountTypeName = data.DiscountType === 0 ? '%' : 'Ft';
                        $scope.voucherData.VoucherStatus = data.Status;

                        angular.forEach(data.CostCenters, function (costCenter) {
                            $scope.costCenters.push({
                                Id: costCenter.Id,
                                Name: costCenter.Name,
                                loadingRate: costCenter.Percentage
                            });
                        });

                        $scope.prdate2 = $scope.voucherData.IssueDate;

                        $scope.datePickers = {
                            deadline: { isOpen: false, date: $scope.voucherData.DeadlineDate },
                            fulfillment: { isOpen: false, date: $scope.voucherData.FulfillmentDate },
                            validityDeadline: { isOpen: false, date: $scope.voucherData.ValidityDeadline }
                        };

                        $scope.originalStatus = data.Status;
                        if ($scope.originalStatus == 69) {
                            $scope.bidToCustomerOrderBtnVisibility = 1;
                        }

                        if ($scope.voucherData.VoucherStatus == 34) {
                            $scope.disableFinishBtn = true;
                        }
                        else {
                            $scope.disableFinishBtn = false;
                        }
                        if ($scope.voucherData.VoucherStatus == 53 || $scope.voucherData.VoucherType == 50) {
                            $scope.stornoBtnDis = true;
                        }

                        if ($scope.voucherData.ValidityDeadline != null) {
                            $scope.datePickers.validityDeadline.date = $scope.voucherData.ValidityDeadline;
                        }

                        //További adatok lekérése (Raktárak, nyelv, Pénznem, fizetési mód)
                        $http.get(apiurl.stock.getBaseDataForTakeOut + userId).success(function (bdata) {
                            $scope.Warehouses = bdata.UserData.Warehouses;

                            $scope.Languages = bdata.Languages;
                            angular.forEach($scope.Languages, function (value) {
                                if (value.Id === $scope.langId) {
                                    $scope.Language = value;
                                }
                            });

                            $scope.Currencies = bdata.Currencies;
                            angular.forEach($scope.Currencies, function (value) {
                                if (value.Id === $scope.currId) {
                                    $scope.Currency = value;
                                }
                            });

                            $scope.PaymentTypes = bdata.PaymentTypes;
                            angular.forEach($scope.PaymentTypes, function (value) {
                                if (value.Id === $scope.paymentId) {
                                    $scope.PaymentType = value;
                                    // Megnézzük, hogy a partner fizetési módja alapértelmezetten átutalás-e és ha igen, akkor megváltoztatjuk a fizetési határidőt
                                    if ($scope.paymentId === TRANSFER_PAYMENTTYPE && $scope.isFromMoreDelivery) {
                                        $scope.paymentTypeChange();
                                    }
                                }
                            });

                            // Az aktuális felhasználó beállítása
                            angular.forEach($scope.Users, function (value, key) {
                                if (value.Name == $scope.voucherData.CreationOwner) {
                                    $scope.actualUser = {
                                        Name: value.Name,
                                        UserName: value.UserName,
                                        Id: value.Id
                                    }
                                }
                            });
                            //ez olyan esetben lehet, ha a számlát egy olyan személy állította ki, aki már nem regisztrált felhasználója a rendszernek
                            if ($scope.actualUser == '') {
                                $scope.actualUser = {
                                    Name: $scope.voucherData.CreationOwner
                                }
                            }

                            $scope.CustomerOrderStatuses = bdata.CustomerOrderStatuses;

                            if ($scope.voucherData.VoucherStatus != 64) {
                                $scope.CustomerOrderStatuses.splice(3, 1);
                            }

                            angular.forEach($scope.CustomerOrderStatuses, function (st) {
                                if (st.Id === $scope.voucherData.VoucherStatus) {
                                    $scope.customerOrderStatus = st;
                                    $scope.customerOrderDisabled = !st.Editable;
                                }
                            });

                            $scope.BidStatuses = bdata.BidStatuses;
                            angular.forEach($scope.BidStatuses, function (st) {
                                if (st.Id === $scope.voucherData.VoucherStatus) {
                                    $scope.bidStatus = st;
                                    $scope.bidStatusDisabled = !st.Editable;
                                }
                            });

                            $scope.cashRegister = null;
                            if ($scope.cashRegisterId == null || $scope.cashRegisterId == 0) {
                                $scope.cashRegisterId = bdata.UserData.DefaultCashRegister.Id;
                            }
                            $scope.cashRegisters = bdata.UserData.CashRegisters;
                            angular.forEach($scope.cashRegisters, function (value, key) {
                                if (value.Id === $scope.cashRegisterId) {
                                    $scope.cashRegister = value;
                                }
                            });
                            if ($scope.cashRegister === null) {
                                $scope.cashRegister = $scope.cashRegisters[0];
                            }

                            var eaList = [];
                            var notInTheList = [];
                            var numberOfPushedItems = 0;
                            //Terméklista feltöltése
                            for (var i = 0; i < data.Items.length; i++) {
                                var tmpProduct = {
                                    Name: data.Items[i].ItemName,
                                    Quantity: data.Items[i].ItemQuantity,
                                    QuantityType: data.Items[i].ItemQuantityType,
                                    QuantityTypeId: data.Items[i].QuantityTypeId,
                                    NetPrice: data.Items[i].ItemUnitNetPrice,
                                    Vat: 27, //nincs benne a DTO-ban
                                    NetValue: data.Items[i].ItemNetPrice,
                                    VatValue: data.Items[i].ItemTaxAmount,
                                    GrossPrice: Number((data.Items[i].ItemGrossPrice / data.Items[i].ItemQuantity).toFixed(2)),
                                    GrossValue: data.Items[i].ItemGrossPrice,
                                    ProductId: data.Items[i].ProductId,
                                    Type: {
                                    },
                                    DiscountAmount: data.Items[i].DiscountAmount,
                                    DiscountType: data.Items[i].DiscountType,
                                    DiscountTypeName: data.Items[i].DiscountType == 0 ? '%' : 'Ft',
                                    TaxRateId: data.Items[i].TaxTypeId,
                                    Identifier: data.Items[i].Identifier,
                                    Prefix: data.Items[i].Prefix,
                                    StartNumberOfRange: data.Items[i].StartNumberOfRange,
                                    EndNumberOfRange: data.Items[i].EndNumberOfRange,
                                    CostCenters: [],
                                    ManufacturingProcessId: data.Items[i].ManufacturingProcessId
                                };

                                //$scope.getManufacturingProcessesByProductId(tmpProduct.ProductId);

                                if (data.Items[i].ItemName.indexOf('\nAzonosító:') > 0) {
                                    tmpProduct.Name = data.Items[i].ItemName.slice(0, data.Items[i].ItemName.indexOf('\nAzonosító:'));
                                    tmpProduct.Identifier = data.Items[i].ItemName.slice(data.Items[i].ItemName.lastIndexOf('\nAzonosító:') + 12, data.Items[i].ItemName.length);
                                }

                                if (tmpProduct.DiscountAmount > 0) {
                                    $scope.DiscountMethods(tmpProduct);
                                }

                                angular.forEach(data.Items[i].CostCenters, function (costCenter) {
                                    tmpProduct.CostCenters.push({
                                        Id: costCenter.Id,
                                        Name: costCenter.Name,
                                        loadingRate: costCenter.Percentage
                                    });
                                });

                                if ($scope.isFromMoreDelivery) {
                                    angular.forEach($scope.products, function (product, key) {
                                        if (product.ProductId == tmpProduct.ProductId) {
                                            if (product.TaxRateId == tmpProduct.TaxRateId && product.QuantityType == tmpProduct.QuantityType && product.NetPrice == tmpProduct.NetPrice && product.DiscountAmount == tmpProduct.DiscountAmount) {
                                                product.Quantity += tmpProduct.Quantity;
                                                product.NetValue = Number((product.NetPrice * product.Quantity).toFixed(2));
                                                product.VatValue = Number((product.NetValue * (tmpProduct.Vat / 100)).toFixed(2));
                                                product.GrossValue = Number((product.NetValue + product.VatValue).toFixed(2));
                                                notInTheList.push({ productId: product.ProductId });
                                            }
                                        }
                                    });
                                }

                                tmpProduct.Type.Id = data.Items[i].ProductTypeId;
                                switch (tmpProduct.Type.Id) {
                                    case 26:
                                        tmpProduct.Type = $scope.productTypes[0];
                                        break;
                                    case 27:
                                        tmpProduct.Type = $scope.productTypes[1];
                                        break;
                                    case 28:
                                        tmpProduct.Type = $scope.productTypes[2];
                                        break;
                                    case 39:
                                        tmpProduct.Type = $scope.productTypes[3];
                                        var canPusheaList = true;
                                        angular.forEach(eaList, function (ea) {
                                            if (ea.ProductId == data.Items[i].ProductId &&
                                                (($scope.products[ea.placeInProducts].Quantity > 0 && data.Items[i].ItemQuantity > 0)
                                                    || ($scope.products[ea.placeInProducts].Quantity < 0 && data.Items[i].ItemQuantity < 0))) {
                                                if (!$scope.isCorrecterBill) {
                                                    notInTheList.push({ productId: data.Items[i].ProductId });
                                                    canPusheaList = false;
                                                }
                                                if (($scope.products[ea.placeInProducts].Quantity * data.Items[i].ItemQuantity) > 0) {
                                                    if (data.Items[i].ItemQuantity != -1 || data.VoucherType === 50) {
                                                        if (data.Items[i].Identifier === null) {
                                                            $scope.products[ea.placeInProducts].Identifier += "," + tmpProduct.Identifier;
                                                        } else {
                                                            $scope.products[ea.placeInProducts].Identifier += "," + data.Items[i].Identifier;
                                                        }
                                                    }
                                                }
                                                if (!$scope.isFromMoreDelivery && !$scope.isCorrecterBill) {
                                                    var pr = $scope.products[ea.placeInProducts];
                                                    pr.Quantity += data.Items[i].ItemQuantity;
                                                    pr.NetValue = PriceCounter.NetValue(pr.NetPrice, pr.Quantity);;
                                                    pr.VatValue = PriceCounter.VatValue(pr.NetPrice, pr.Quantity, pr.Vat);
                                                    pr.GrossValue = PriceCounter.GrossValue(pr.NetPrice, pr.Quantity, pr.Vat);
                                                }
                                            }
                                        });

                                        if (canPusheaList) {
                                            eaList.push({
                                                ProductId: data.Items[i].ProductId,
                                                placeInProducts: numberOfPushedItems
                                            });
                                        }
                                        break;
                                    default:
                                        tmpProduct.Type = $scope.productTypes[0];
                                        break;
                                }

                                var canPush = true;
                                angular.forEach(notInTheList, function (pr) {
                                    if (pr.productId == tmpProduct.ProductId) {
                                        canPush = false;
                                    }
                                });

                                if (canPush) {
                                    $scope.products.push(tmpProduct);
                                    numberOfPushedItems++;
                                }
                            }

                            $scope.loadManufacturingProcesses($scope.products);
                            $scope.getProbableEndingDateOfManufacturing();

                            angular.forEach($scope.products, function (product) {
                                getQuantityTypesByProductId(product.ProductId).then(function (response) {
                                    product.quantityTypes = response;
                                });
                            });

                            //Áfa beállítása
                            for (var i = 0; i < $scope.products.length; i++) {
                                angular.forEach($scope.TaxRates, function (value) {
                                    if ($scope.products[i].TaxRateId == value.Id) {
                                        $scope.products[i].TaxRate = value;
                                        $scope.products[i].Vat = value.TaxRateValue;
                                    }
                                });
                            }

                            //Kerekítés és Összesen
                            $scope.InvoiceRounding();

                            //Áfaösszesítő
                            $scope.InvoiceVatSum();
                        });

                        //Számlához tartozó befizetések
                        if (data.Payments != null) {
                            $scope.inPaymentValue = 0;
                            for (var i = 0; i < data.Payments.length; i++) {
                                $scope.inPaymentValue += data.Payments[i].Amount;
                            }
                        }

                        //Számlához tartozó összegzősorok
                        if (data.SumRows != null) {
                            for (var i = 0; i < data.SumRows.length; i++) {
                                $scope.sumRows = {
                                    GrossValue: data.SumRows[i].GrossAmount
                                }
                            }
                        }

                        //ha a számla végösszege és a befizetések összege egyezik, akkor kiegyenlített a számla
                        if (Number($scope.inPaymentValue.toFixed(0)) === Number($scope.sumRows.GrossValue.toFixed(0))) {
                            $scope.invoiceIsEqualized = true;
                        }

                        //Ha számla és a fizetési mód átutalás, akkor ki kell számolni az aktuális hitelkeretet
                        if ($scope.SalesRadio === "Bill" && $scope.paymentId == 21) {
                            //DebitAmount - a céghez tartozó tartozások összege
                            CurrentCreditAmountCount(data.ActCompany, data.ActCompany.DebitAmount);
                        }

                        //Ha kiállított szállítólevél, akkor ki kell számolni az aktuális hitelkeretet
                        if ($scope.SalesRadio === "Trans" && $scope.voucherData.VoucherStatus == 41) {
                            //DebitAmount - a céghez tartozó tartozások összege
                            CurrentCreditAmountCount(data.ActCompany, data.ActCompany.DebitAmount);
                        }

                        //Ha vevői megrendelés, amely nem törölt és teljesített, akkor ki kell számolni az aktuális hitelkeretet
                        if ($scope.SalesRadio === "Order" && $scope.voucherData.VoucherStatus != 64 && $scope.voucherData.VoucherStatus != 65) {
                            //DebitAmount - a céghez tartozó tartozások összege
                            CurrentCreditAmountCount(data.ActCompany, data.ActCompany.DebitAmount);
                        }
                    });
                }
            }
        }
        loadTweak();

        //Költséghelyek costcenters
        $scope.saveCostCentersVisibility = !$scope.newTweak;

        $scope.haveCostCenter = false;
        $scope.haveItemCostCenter = false;

        //Egy új sor hozzáadása
        $scope.AddCostCenterRow = function () {
            for (var i = 0; i < $scope.products.length; i++) {
                if ($scope.products[i].CostCenters.length != 0) {
                    $scope.haveItemCostCenter = true;
                    break;
                } else {
                    $scope.haveItemCostCenter = false;
                }
            };

            if ($scope.haveItemCostCenter) {
                toasterService.warningToast("Figyelem", "Már adott meg a tételekhez költséghelyet!");
            } else {
                $scope.haveCostCenter = true;

                $scope.costCenter = {
                    Name: '',
                    loadingRate: 0
                };

                $scope.costCenters.push($scope.costCenter);
            }
        };

        //Egy sor törlése
        $scope.DeleteCostCenterRow = function (index) {
            $scope.costCenters.splice(index, 1);
        };

        //Költséghely keresése
        $scope.GetCostCenters = function (costCenterName, index) {
            if (costCenterName.length < 3) {
                return [];
            }
            return $http.get(apiurl.costCenter.getAllCostCenter + costCenterName)
            .then(function (data) {
                return data.data;
            });
        };

        //Költséghelyeknél enter leütésre hozzáad egy sort
        $scope.IsEnterAtCostCenter = function (ev, index) {
            if (ev.which == 13) {
                $scope.AddCostCenterRow();
            }
        };

        //A kiválasztott költséghely hozzáadása a listához
        $scope.CostCenterSelected = function ($model, $index) {
            var isNew = true;
            angular.forEach($scope.costCenters, function (costCenter) {
                if ($model.Id == costCenter.Id) {
                    toasterService.errorToast("Már hozzáadta a listához ezt a költséghelyet!");
                    isNew = false;
                    $scope.costCenters[$index] = {
                        Name: '',
                        loadingRate: 0
                    }
                }
                return;
            });
            if (isNew) {
                $scope.costCenters[$index].Id = $model.Id;
            }
        };

        ////Termékhez tartozó költséghelyek

        //Termékhez egy új költséghely hozzáadása
        $scope.AddCostCenterRowForProduct = function (index) {
            if ($scope.costCenters.length > 0) {
                toasterService.warningToast("Figyelem", "Már adott meg a bizonylathoz költséghelyet!");
            } else {
                $scope.costCenterForProduct = {
                    Name: '',
                    loadingRate: 0
                };
                $scope.products[index].CostCenters.push($scope.costCenterForProduct);
            }
        };

        //Termékhez tartozó költséghely hozzáadása a termékhez
        $scope.CostCenterSelectedForProduct = function (product, $model, $index) {
            var isNew = true;
            angular.forEach(product.CostCenters, function (costCenter) {
                if ($model.Id == costCenter.Id) {
                    toasterService.errorToast("Már hozzáadta a listához ezt a költséghelyet!");
                    isNew = false;
                    product.CostCenters[$index] = {
                        Name: '',
                        loadingRate: 0
                    }
                }
                return;
            });
            if (isNew) {
                product.CostCenters[$index].Id = $model.Id;
            }
        };

        //Termékhez tartozó költséghely törlése
        $scope.DeleteCostCenterRowForProduct = function (index, model) {
            model.CostCenters.splice(index, 1);
        };

        $scope.BidVis = false;
        $scope.bidToCustomerOrderBtnVisibility = 0;
        $scope.rolloverOrder = function () {
            $scope.bidToCustomerOrderBtnVisibility = 2;
            $scope.generateOrderVisibility = 1;
            $scope.actDate = new Date();
            $scope.IsPaperFrom = false;

            if ($filter('date')($scope.actDate, 'yyyy.MM.dd') < $filter('date')($scope.datePickers.validityDeadline.date, 'yyyy.MM.dd')) {
                $scope.BidVis = true;
            } else {
                browserModalService.confirm("Az árajánlat érvényességi ideje lejárt! Biztosan szeretné átforgatni megrendeléssé?").then(function (r) {
                    if (r) {
                        $scope.BidVis = true;
                    }
                });
            }
        }
        $scope.generateOrderVisibility = 0;

        //Helyesbítő számla
        $scope.canRecifyInvoiceAndCorrection = function () {
            if ($scope.voucherData.VoucherType == 57) {
                browserModalService.alert("Helyesbítő számla generálása papír alapú számlánál egyelőre nem üzemel!");
                return;
            }
            $http.get(apiurl.stock.canRectifyInvoice + '/' + tId).success(function () {
                invoiceCorrection();
            }).error(function (edata, status) {
                if (edata.Message.indexOf("Argument validation failed") > -1) {
                    browserModalService.alert(edata.Notifications[0].Target);
                } else {
                    browserModalService.alert(edata.Message.slice(0, edata.Message.lastIndexOf('/')));
                }
                console.log("sajat " + edata);
                console.log("sajat " + status);
            });
        }

        $scope.correctionVis = false;
        $scope.payTypeDis = "";
        var original_FulfillmentDate = "";

        function invoiceCorrection() {
            var countDeadlineForInvoiceCorrection = function () {
                var one = $filter('date')($scope.voucherData.IssueDate, 'yyyy-MM-dd');
                var two = $filter('date')(new Date(), 'yyyy-MM-dd');
                var oneDate = new Date(one);
                var twoDate = new Date(two);

                var millisecondsPerDay = 1000 * 60 * 60 * 24;
                var millisBetween = twoDate.getTime() - oneDate.getTime();
                var days = millisBetween / millisecondsPerDay;

                return days;
            };
            if (countDeadlineForInvoiceCorrection() > 15) {
                browserModalService.alert('Helyesbítő számla az eredeti számla kiállításától számítva 15 napon belül állítható ki!');
                return;
            }
            $scope.disableTaxRate = true;
            original_FulfillmentDate = angular.copy($filter('date')($scope.voucherData.FulfillmentDate, 'yyyy.MM.dd'));

            //A cégnek van-e tartozása
            isDebitOfActCompany();

            $scope.correctionVis = true;
            $("ul.selectpicker li.disabled").removeClass('disabled');
            $("#paymentType select").removeAttr('disabled');
            $("#paymentType button").removeClass('disabled');
            $('#customerBankAccount').removeAttr('disabled');
            $('#customerTaxNumber').removeAttr('disabled');
            $('#customerEUTaxNumber').removeAttr('disabled');
            $('#buyerPersonName').removeAttr('disabled');
            $('#buyerPersonTaxNumber').removeAttr('disabled');
        }

        $scope.generateCorrectionInvoice = function () {
            browserModalService.confirm('Valóban szeretne helyesbítő számlát generálni?').then(function (r) {
                if (!r) {
                    return;
                } else {
                    var costCenterDictionary = {};
                    if (CheckCostCenters.Check($scope.products, $scope.costCenters, costCenterDictionary)) {
                        return;
                    }

                    var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
                    var status = $scope.SalesRadio !== 'Order' && $scope.SalesRadio !== 'Bid' ? 41 : $scope.SalesRadio === 'Bid' ? $scope.bidStatus.Id : $scope.customerOrderStatus.Id;
                    var voucherType = getVoucherType($scope.SalesRadio);
                    $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                    $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                    $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                    $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                    $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                    $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                    $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                    $scope.voucherData.Status = status;
                    $scope.voucherData.VoucherType = voucherType;

                    if ($scope.SalesRadio == 'Voucher') {
                        $scope.voucherData.CustomerName = '';
                        $scope.voucherData.CustomerAddress = '';
                        $scope.voucherData.CustomerBankAccount = '';
                        $scope.voucherData.CustomerDeliveryAddress = '';
                        $scope.voucherData.CustomerEUTaxNumber = '';
                        $scope.voucherData.CustomerTaxNumber = '';
                    }

                    $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                    if ($scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60) {
                        $scope.voucherData.ValidityDeadline = $filter('date')($scope.datePickers.validityDeadline.date, 'yyyy.MM.dd');
                    }

                    //A cégnek van-e tartozása
                    if (isDebitOfActCompany() == false) {
                        return;
                    }

                    //Számla és a fizetési mód átutalás
                    if (isInvoice && $scope.voucherData.PaymentTypeId == 21) {
                        var result = CheckSellerActCreditLine($scope.sumGrossValue);
                        if (!result) {
                            return;
                        }
                    }

                    //Ha készpénzes, akkor a teljesítés dátumának meg kell egyeznie az eredeti számla teljesítés dátumával
                    if ($scope.voucherData.PaymentTypeId == 20 && $scope.voucherData.VoucherType !== 53 && $scope.voucherData.VoucherType != 47 && $scope.voucherData.VoucherType != 58) {
                        if (original_FulfillmentDate !== $scope.voucherData.FulfillmentDate) {
                            toasterService.warningToast("Figyelem", "A helyesbítő számla teljesítés dátumának meg kell egyeznie az eredeti számla teljesítés dátumával!");
                            return;
                        }
                    }

                    //Vat beállítása a TaxtRate alapján
                    angular.forEach($scope.products, function (pr) {
                        pr.Vat = pr.TaxRate.TaxRateValue;
                    });

                    var data = {
                        StockItems: $scope.products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null,
                        IsInvoice: isInvoice, Voucher: $scope.voucherData, VoucherId: $scope.voucherData.VoucherId, CostCenterIdentites: costCenterDictionary
                    };

                    CheckVoucherItems.Check($scope.products, $scope.voucherData);

                    $http.post(apiurl.stock.createRecifyInvoice, data).success(function (recData) {
                        toasterService.successToast("", "Számla létrehozása sikeresen megtörtént!");
                        window.location.href = apiurl.stock.generateDocument + recData;
                    }).error(function (errorMsg) {
                        if (errorMsg.Message.indexOf("/n") > 0) {
                            errorMsg.Message = errorMsg.Message.slice(errorMsg.Message.lastIndexOf("/n") + 2, errorMsg.Message.length);
                        }
                        toasterService.errorFromServerToast(errorMsg);
                    });
                }
            });
        };

        $scope.generateInvoiceFromDeliveryButtonName = 'Számla generálása';
        $scope.generateInvoiceFromDelivery = false;
        $scope.correctionInvoiceButtonDisabled = false;

        //Számla generálása szállítólevélből
        $scope.generateInvoice = function () {
            if (!$scope.generateInvoiceFromDelivery) {
                browserModalService.confirm('Valóban számlát akar generálni a szállítólevélből?').then(function (r) {
                    if (!r) {
                        return;
                    } else {
                        $scope.generateInvoiceFromDelivery = true;
                        $scope.SalesRadio = 'Bill';
                        $scope.generateInvoiceFromDeliveryButtonName = 'Számla nyomtatása és mentése';
                        $("#generateInvoiceFromDelivery").addClass('btn-save');
                        removeDisableFromSelectPickers();
                        $scope.IsPaperFrom = false;

                        // A partnerhez tartozó alapértelmezett fizetési mód lekérése
                        $http.get(apiurl.stock.getCompanyById + '/' + $scope.actCompany.Id).success(function (data) {
                            $scope.paymentId = data.TypeOfPaymentId;
                            // Alapértelmezett fizetési mód beállítása
                            angular.forEach($scope.PaymentTypes, function (value) {
                                if (value.Id === $scope.paymentId) {
                                    $scope.PaymentType = value;
                                    // Megnézzük, hogy a partner fizetési módja alapértelmezetten átutalás-e és ha igen, akkor megváltoztatjuk a fizetési határidőt
                                    if ($scope.paymentId === TRANSFER_PAYMENTTYPE) {
                                        $scope.paymentTypeChange();
                                    }
                                }
                            });
                        });
                    }
                });
            } else {
                var voucherType = getVoucherType($scope.SalesRadio);
                $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                $scope.voucherData.Status = OUTGOING_STATUS;
                $scope.voucherData.VoucherType = voucherType;

                if ($scope.SalesRadio == 'Voucher') {
                    $scope.voucherData.CustomerName = '';
                    $scope.voucherData.CustomerAddress = '';
                    $scope.voucherData.CustomerBankAccount = '';
                    $scope.voucherData.CustomerDeliveryAddress = '';
                    $scope.voucherData.CustomerEUTaxNumber = '';
                    $scope.voucherData.CustomerTaxNumber = '';
                }

                $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                if ($scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60) {
                    $scope.voucherData.ValidityDeadline = $filter('date')($scope.datePickers.validityDeadline.date, 'yyyy.MM.dd');
                }
                //Ha készpénzes, akkor a fizetési határidő nem lehet későbbi, mint a kiállítás dátuma
                if ($scope.SalesRadio !== 'Trans') {
                    var actDate = $filter('date')(new Date(), 'yyyy.MM.dd');
                    if ($scope.voucherData.PaymentTypeId == 20 && ($scope.voucherData.DeadlineDate !== actDate
                        || $scope.voucherData.IssueDate !== actDate
                        || $scope.voucherData.FulfillmentDate !== actDate)) {
                        browserModalService.alert('Készpénzes fizetésnél a fizetési határidőnek, a kiállítás dátumának és a teljesítés dátumának ' +
                            'a mai dátummal azonosnak kell lennie.');
                        return;
                    }
                }
                //Vat beállítása a TaxtRate alapján
                angular.forEach($scope.products, function (pr) {
                    pr.Vat = pr.TaxRate.TaxRateValue;
                });

                var data = {
                    StockItems: $scope.products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null, IsInvoice: false, Voucher: $scope.voucherData, VoucherId: $scope.voucherData.VoucherId
                };

                $http.post(apiurl.stock.createInvoiceFromDelivery, data).success(function (invdata) {
                    tId = invdata;
                    $scope.correctionInvoiceButtonDisabled = true;
                    toasterService.successToast("", "Számla létrehozása sikeresen megtörtént!");
                    $scope.printDocument();
                }).error(function (edata, status) {
                    toasterService.errorFromServerToast(edata);
                });
            }
        };

        //Aktuáis hitelkeret kiszámolása
        function CurrentCreditAmountCount(actCompany, debitAmount) {
            if (actCompany.HufCreditLine != null) {
                $scope.currentCreditValue = CurrentCreditLineCounter.CurrentCredit(actCompany.HufCreditLine, debitAmount);
            }
        }

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            //  $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();
        $scope.clear = function () {
            $scope.prdate2 = null;
        };
        $scope.prdate2format = "yyyy.MM.dd";
        $scope.datePickers = {
            deadline: {
                isOpen: false, date: new Date()
            },
            fulfillment: {
                isOpen: false, date: new Date()
            },
            validityDeadline: {
                isOpen: false, date: new Date()
            }
        };
        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;
            } else {
                datePicker.isOpen = true;
            }
        };
        $scope.dtoptions = {
            startingDay: 1
        };

        //Csak nyomtatás
        $scope.printDocument = function () {
            if ($scope.pricesVisible == true) {
                window.location.href = apiurl.stock.generateDocument + tId + '/' + $scope.pricesVisible;
            } else {
                window.location.href = apiurl.stock.generateDocument + tId;
            }
        }

        //Átutalásos számlánál lellenőrzi, hogy a számla értéke nem nagyobb-e, mint az aktuális hitelkeret -> ha nagyobb, akkor csak készpénzesként tudja a számlát menteni/nyomtatni
        function CheckSellerActCreditLine(grossValue) {
            if ($scope.currentCreditValue < grossValue) {
                browserModalService.alert('A számla végösszege meghaladja a biztosított hitelkeretet!');
                //if (!message) {
                return false;
                //}
                //fizetési mód átállítása készpénzesre
                //$scope.voucherData.PaymentTypeId = 20;
                //return true;
            } else {
                return true;
            }
        };

        //Az ügyfélnek van-e tartozása
        function isDebitOfActCompany() {
            //Ha kiállított szállítólevél, akkor ki kell számolni az aktuális hitelkeretet és figyelmeztetnie kell
            if ($scope.SalesRadio === "Trans" && $scope.voucherData.Status == 41) {
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
                if ($scope.actCompany.IsExpiredDebit == true) {
                    browserModalService.alert("Az ügyfélnek lejárt tartozása van!\nNem zárhatja le a szállítólevelet!");
                    if ($scope.currentCreditValue > $scope.sumGrossValue) {
                        if ($window.sessionStorage.getItem('roleids').split(',')[21]) {
                            browserModalService.confirm("Mégis engedélyezi neki a szállítólevél kiállítását?").then(function (r) {
                                if (!r) {
                                    return false;
                                }
                            });
                        } else {
                            return true;
                        }
                    }
                }
            }

            //Ha visszaigazolt vevői megrendelés, akkor ki kell számolni az aktuális hitelkeretet és figyelmeztetnie kell
            if ($scope.SalesRadio === "Order" && $scope.voucherData.Status == 63) {
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
                if ($scope.actCompany.IsExpiredDebit == true) {
                    browserModalService.alert("Az ügyfélnek lejárt tartozása van!\nNem igazolhatja vissza a megrendelést!");
                    if ($scope.currentCreditValue > $scope.sumGrossValue) {
                        if ($window.sessionStorage.getItem('roleids').split(',')[21]) {
                            browserModalService.confirm("Mégis engedélyezi neki a visszaigazolást?").then(function (r) {
                                if (!r) {
                                    return false;
                                }
                            });
                        } else {
                            return true;
                        }
                    }
                }
            }

            //Ha átutalásos számla, akkor ki kell számolni az aktuális hitelkeretet és figyelmeztetnie kell
            if ($scope.SalesRadio === "Bill" && $scope.PaymentType.Id == 21) {
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
                if ($scope.actCompany.IsExpiredDebit == true) {
                    browserModalService.alert("Az ügyfélnek lejárt tartozása van!\nNem választhat átutalásos fizetést!");
                    angular.forEach($scope.PaymentTypes, function (pt) {
                        if (pt.Id == 20) {
                            $scope.pz = pt;
                        }
                    });
                    if ($scope.currentCreditValue > $scope.sumGrossValue) {
                        if ($window.sessionStorage.getItem('roleids').split(',')[21]) {
                            browserModalService.confirm("Mégis engedélyezi neki az átutalásos számlát?").then(function (r) {
                                if (!r) {
                                    $scope.PaymentType = $scope.pz;
                                    return false;
                                }
                            });
                        } else {
                            $scope.PaymentType = $scope.pz;
                        }
                    }
                }
            }
        };

        //Költséghelyek mentése
        $scope.saveCostCenters = function (products) {
            var costCenterDictionary = {};
            if (CheckCostCenters.Check(products, $scope.costCenters, costCenterDictionary)) {
                return;
            }
            data = {
                StockItems: products, VoucherId: tId, CostCenterIdentites: costCenterDictionary
            }
            $http.put(apiurl.stock.updateCostCenterOfInvoice, data).success(function () {
                toasterService.saveSuccess();
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.takeOutStock = function (products, isPreview) {
            var costCenterDictionary = {};

            if ($scope.SalesRadio !== 'Trans'
                && $scope.SalesRadio !== 'Order'
                && $scope.SalesRadio !== 'Bid'
                && CheckCostCenters.Check(products, $scope.costCenters, costCenterDictionary)) {
                return;
            }

            var confirmQuestion = (isPreview) ? 'Valóban meg kívánja tekinteni az előnézeti képet?' : 'Valóban ki akarja nyomtatni?';

            browserModalService.confirm(confirmQuestion).then(function (r) {
                if (!r) {
                    return;
                } else {
                    if ($scope.IsPaperFrom) {
                        if ($scope.voucherData.VoucherNumber == null || $scope.voucherData.VoucherNumber == '') {
                            toasterService.warningToast("Figyelem", "A sorszám mező kitöltése kötelező!\n");
                            return;
                        }
                    }

                    runningTakeOutStockMethod = true;

                    var checkActCerditLine = true;

                    //Ha több megrendelést forgatunk át számlára
                    if ($scope.isFromMoreDelivery == true) {
                        checkActCerditLine = false;
                    }

                    var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
                    var isOrder = $scope.SalesRadio === 'Order' ? true : false;
                    var isTrans = $scope.SalesRadio === 'Trans' ? true : false;
                    var status = $scope.SalesRadio !== 'Order' && $scope.SalesRadio !== 'Bid' ? 41 : $scope.SalesRadio === 'Bid' ? $scope.bidStatus.Id : $scope.customerOrderStatus.Id;
                    var voucherType = getVoucherType($scope.SalesRadio);
                    $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                    $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                    $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                    $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                    $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                    $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                    $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                    $scope.voucherData.Status = status;
                    $scope.voucherData.VoucherType = voucherType;

                    if (!angular.isObject($scope.actualUser)) {
                        toasterService.warningToast("Figyelem", 'A kiállító nevének megadása kötelező és egy regisztrált felhasználónak kell lennie.');
                        return;
                    }

                    $scope.voucherData.CreationOwnerUserId = $scope.actualUser.Id;
                    $scope.voucherData.CreationOwner = $scope.actualUser.Name;

                    if ($scope.SalesRadio == 'Voucher') {
                        $scope.voucherData.CustomerName = '';
                        $scope.voucherData.CustomerAddress = '';
                        $scope.voucherData.CustomerBankAccount = '';
                        $scope.voucherData.CustomerDeliveryAddress = '';
                        $scope.voucherData.CustomerEUTaxNumber = '';
                        $scope.voucherData.CustomerTaxNumber = '';
                    }

                    $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                    if ($scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60) {
                        $scope.voucherData.ValidityDeadline = $filter('date')($scope.datePickers.validityDeadline.date, 'yyyy.MM.dd');
                    }

                    //A cégnek van-e tartozása
                    if (isDebitOfActCompany() == false) {
                        return null;
                    }

                    //Ha ellenőrizni kell az aktuális hitelkeretet
                    if (checkActCerditLine) {
                        //Számla és a fizetési mód átutalás
                        if (isInvoice && $scope.voucherData.PaymentTypeId == 21) {
                            var result = CheckSellerActCreditLine($scope.sumGrossValue);
                            if (!result) {
                                return;
                            }
                        }
                    }

                    //Visszaigazolt vevői megrendelés
                    if (isOrder && $scope.voucherData.Status == 63) {
                        var result = CheckSellerActCreditLine($scope.sumGrossValue);
                        if (!result) {
                            return;
                        }
                    }

                    //Kiállított szállítólevél
                    if (isTrans && $scope.voucherData.Status == 41) {
                        var result = CheckSellerActCreditLine($scope.sumGrossValue);
                        if (!result) {
                            return;
                        }
                    }

                    //Ha készpénzes, akkor a fizetési határidő nem lehet későbbi, mint a kiállítás dátuma
                    if ($scope.SalesRadio !== 'Bid' && $scope.SalesRadio !== 'Trans') {
                        var actDate = $filter('date')(new Date(), 'yyyy.MM.dd');
                        //Ha nem papír alapú, akkor a 3dátum lehet régebbi mint az aktuális dátum, de nem térhet el egymástól
                        if ($scope.IsPaperFrom) {
                            if ($scope.voucherData.PaymentTypeId == 20 && ($scope.voucherData.DeadlineDate !== $scope.voucherData.IssueDate
                                                || $scope.voucherData.FulfillmentDate !== $scope.voucherData.IssueDate)) {
                                browserModalService.alert('Készpénzes fizetésnél a fizetési határidő, a kiállítás dátum és a teljesítés dátum ' +
                                    'nem térhet el egymástól!');
                                return;
                            }
                        } else {
                            if ($scope.voucherData.PaymentTypeId == 20 && ($scope.voucherData.DeadlineDate !== actDate
                                || $scope.voucherData.IssueDate !== actDate
                                || $scope.voucherData.FulfillmentDate !== actDate)) {
                                browserModalService.alert('Készpénzes fizetésnél a fizetési határidőnek, a kiállítás dátumának és a teljesítés dátumának ' +
                                    'a mai dátummal azonosnak kell lennie.');
                                return;
                            }
                        }
                    }

                    //Vat beállítása a TaxtRate alapján
                    angular.forEach(products, function (pr) {
                        pr.Vat = pr.TaxRate.TaxRateValue;
                    });

                    var data = {
                        StockItems: products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null, IsInvoice: isInvoice, Voucher: $scope.voucherData, CostCenterIdentites: costCenterDictionary
                    };

                    if (!CheckVoucherItems.Check(products, $scope.voucherData, $scope.newTweak)) {
                        return;
                    }

                    if ($scope.voucherData.CustomerBankAccount != null && $scope.voucherData.CustomerBankAccount != '') {
                        var regex1 = "^[1-9][0-9]{7}$";
                        var regex2 = "^[0-9]{8}$";
                        $scope.voucherData.CustomerBankAccount = $scope.voucherData.CustomerBankAccount.replace(" ", "");
                        var customerBankAccount = $scope.voucherData.CustomerBankAccount.split("-");
                        if (customerBankAccount.length < 2 || customerBankAccount[0].match(regex1) != customerBankAccount[0] || customerBankAccount[1].match(regex2) != customerBankAccount[1]) {
                            browserModalService.alert('A következő bankszámla formátum nem megfelelő.\nA megfelelő formátum : 12345678-12345678 vagy 12345678-12345678-12345678');
                            return;
                        }
                        if (customerBankAccount.length == 3 && (customerBankAccount[0].match(regex1) != customerBankAccount[0] || customerBankAccount[1].match(regex2) != customerBankAccount[1] || customerBankAccount[2].match(regex2) != customerBankAccount[2])) {
                            browserModalService.alert('A következő bankszámla formátum nem megfelelő.\nA megfelelő formátum : 12345678-12345678 vagy 12345678-12345678-12345678');
                            return;
                        }
                    }

                    if (!CheckVoucher.Check(products, $scope.voucherData))
                        return;

                    var isCompanyNew = voucherType !== 45 && $scope.AddNewCompany(data, status);
                    if (isCompanyNew === null) {
                        return;
                    }

                    if (!isCompanyNew) {
                        if (!isPreview) {
                            // Töltési animáció láthatósága
                            $scope.loaderIsVisibleForTakeOut = true;
                            TakeOutPost(data, ($scope.voucherData.VoucherType === 48 || $scope.voucherData.VoucherType === 59 || $scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60));
                        } else {
                            // Töltési animáció láthatósága
                            $scope.loaderIsVisibleForPreview = true;

                            $http({
                                method: 'POST',
                                url: apiurl.stock.generateTestDocument + $scope.pricesVisible,
                                data: data,
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                responseType: 'arraybuffer'
                            }).success(function (dataPdf) {
                                var blob = new Blob([dataPdf], {
                                    type: "application/pdf"
                                });
                                var actDate2 = $filter('date')(new Date(), 'yyyyMMdd.');
                                navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                                window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                                if (window.saveAs) {
                                    window.saveAs(blob, actDate2 + "elonezeti_kep.pdf");
                                } else if (navigator.saveBlob) {
                                    navigator.saveBlob(blob, actDate2 + "elonezeti_kep.pdf");
                                } else {
                                    var url = URL.createObjectURL(blob);
                                    var link = document.createElement("a");
                                    link.setAttribute("href", url);
                                    link.setAttribute("download", actDate2 + "elonezeti_kep.pdf");
                                    var event = document.createEvent('MouseEvents');
                                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                    link.dispatchEvent(event);
                                }
                            }).error(function () {
                                $scope.loaderIsVisibleForPreview = false;
                                toasterService.errorToast("Hiba Történt", "Az ön által megadott adatokból nem lehet előnézeti képet generálni!");
                            }).then(function () {
                                // Töltési animáció láthatósága
                                $scope.loaderIsVisibleForPreview = false;
                            });
                        }
                    }
                }
            });
        };

        $scope.countDeadlineForNewCompany = function () {
            var one = $filter('date')($scope.prdate2, 'yyyy-MM-dd');
            var two = $filter('date')($scope.datePickers.deadline.date, 'yyyy-MM-dd');
            var oneDate = new Date(one);
            var twoDate = new Date(two);

            var millisecondsPerDay = 1000 * 60 * 60 * 24;
            var millisBetween = twoDate.getTime() - oneDate.getTime();
            var days = millisBetween / millisecondsPerDay;

            return days;
        }

        $scope.AddNewCompany = function (pdata, status) {
            if (angular.isObject($scope.actCompany)) {
                return false;
            }

            browserModalService.confirm('A vevő LEHETSÉGES, hogy nem szerepel a Partnerek között.\n Szeretné a Partnertörzsbe rögzíteni?').then(function (r) {
                if (!r) {
                    return null;
                } else {
                    var newCompany = {
                        Name: $scope.selectedCompany,
                        Address: $scope.voucherData.CustomerAddress,
                        DeliveryAddress: $scope.voucherData.CustomerDeliveryAddress,
                        BankAccount: $scope.voucherData.CustomerBankAccount,
                        TaxNumber: $scope.voucherData.CustomerTaxNumber,
                        EUTaxNumber: $scope.voucherData.CustomerEUTaxNumber,
                        PaymentTypeId: $scope.voucherData.PaymentTypeId,
                        DeadlineOfPayment: $scope.countDeadlineForNewCompany()
                    };
                    mapToNewCompanyDto($scope.voucherData, newCompany);

                    $http.post(apiurl.stock.addCustomer, newCompany).success(function (data) {
                        pdata.Voucher.CustomerName = data.Name;
                        pdata.CompanyId = data.CompanyId;

                        if (status == 34) {
                            $scope.SaveTemp(pdata);
                        } else {
                            TakeOutPost(pdata, ($scope.voucherData.VoucherType === 48 || $scope.voucherData.VoucherType === 59 || $scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60));
                        }
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data.Message);
                    });

                    return true;
                }
            });
        };

        function mapToNewCompanyDto(voucherData, company) {
            company.Zip = voucherData.CustomerZip;
            company.City = voucherData.CustomerCity;
            company.District = voucherData.CustomerDistrict;
            company.PubliclyOwnedLandName = voucherData.CustomerPubliclyOwnedLandName;
            company.PubliclyOwnedLandType = voucherData.CustomerPubliclyOwnedLandType;
            company.HouseNumber = voucherData.CustomerHouseNumber;
            company.Building = voucherData.CustomerBuilding;
            company.Stairway = voucherData.CustomerStairway;
            company.Floor = voucherData.CustomerFloor;
            company.Door = voucherData.CustomerDoor;
        }


        $scope.bidToOrder = function () {
            SetBaseDataBeforePost(function (data) {
                if (data == null) {
                    return;
                }

                var canPost = false;
                canPost = CheckParametersForCustomerOrderAndBid(data);
                if (canPost) {
                    $http.post(apiurl.stock.createCustomerOrderFromBid, data).success(function (codata) {
                        toasterService.saveSuccess();
                        $scope.generateOrderVisibility = 2;
                        ////Nyomtatás előtt a formot visszaállítjuk pristine állapotra;
                        window.location.href = apiurl.stock.generateDocument + codata;
                    }).error(function (edata, status) {
                        toasterService.errorFromServerToast(edata);
                    });
                }
            });
        };

        $scope.saveBid = function () {
            SetBaseDataBeforePost(function (data) {
                if (data == null) {
                    return;
                }

                var canPost = false;
                canPost = CheckParametersForCustomerOrderAndBid(data);
                if (canPost) {
                    if (!isNaN($scope.voucherData.VoucherId) && $scope.voucherData.VoucherId > 0) {
                        $http.put(apiurl.stock.updateBid, data).success(function () {
                            toasterService.saveSuccess();
                            window.location.href = backUrl;
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    } else {
                        $http.post(apiurl.stock.createBid, data).success(function (cdata) {
                            $scope.ClearFrontEnd(cdata);
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    }
                }
            });
        };

        $scope.saveCustomerOrder = function () {
            SetBaseDataBeforePost(function (data) {
                if (data == null) {
                    return;
                }

                var canPost = false;
                canPost = CheckParametersForCustomerOrderAndBid(data);
                if (canPost) {
                    if (!isNaN($scope.voucherData.VoucherId) && $scope.voucherData.VoucherId > 0) {
                        $http.put(apiurl.stock.updateCustomerOrder, data).success(function () {
                            toasterService.saveSuccess();
                            window.location.href = backUrl;
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    } else {
                        $http.post(apiurl.stock.createCustomerOrder, data).success(function (cdata) {
                            $scope.ClearFrontEnd(cdata);
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    }
                }
            });
        };

        //Vevői megrendelésnél a visszanyitás utáni szerkesztéshez a mentés és nyomtatás
        $scope.saveAndPrint = function () {
            SetBaseDataBeforePost(function (data) {
                if (data == null) {
                    return;
                }
                var canPost = false;
                canPost = CheckParametersForCustomerOrderAndBid(data);
                if (canPost) {
                    if (data.Voucher.VoucherType === 48 || data.Voucher.VoucherType === 59) {
                        $http.put(apiurl.stock.updateCustomerOrder, data).success(function () {
                            toasterService.saveSuccess();
                            window.location.href = apiurl.stock.generateDocument + tId;
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    }
                    if (data.Voucher.VoucherType === 49 || data.Voucher.VoucherType === 60) {
                        $http.put(apiurl.stock.updateBid, data).success(function () {
                            toasterService.saveSuccess();
                            window.location.href = apiurl.stock.generateDocument + tId;
                        }).error(function (edata, status) {
                            toasterService.errorFromServerToast(edata);
                        });
                    }
                }
            });
        };

        function SetBaseDataBeforePost(callback) {
            browserModalService.confirm('Valóban el akarja menteni?').then(function (r) {
                if (!r) {
                    return null;
                } else {
                    var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
                    var isOrder = $scope.SalesRadio === 'Order' ? true : false;
                    var isTrans = $scope.SalesRadio === 'Trans' ? true : false;
                    var status = $scope.voucherData.VoucherType == 48 || $scope.voucherData.VoucherType == 59 ? $scope.customerOrderStatus.Id : $scope.bidStatus.Id;
                    var voucherType = getVoucherType($scope.SalesRadio);
                    $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                    $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                    $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                    $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                    $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                    $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                    $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                    $scope.voucherData.Status = status;
                    $scope.voucherData.VoucherType = voucherType;

                    $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                    //A cégnek van-e tartozása
                    if (isDebitOfActCompany() == false) {
                        return null;
                    }

                    //Számla és a fizetési mód átutalás
                    if (isInvoice && $scope.voucherData.PaymentTypeId == 21) {
                        if (!CheckSellerActCreditLine($scope.sumGrossValue)) {
                            return null;
                        }
                    }

                    //Visszaigazolt vevői megrendelés
                    if (isOrder && $scope.voucherData.Status == 63) {
                        if (!CheckSellerActCreditLine($scope.sumGrossValue)) {
                            return;
                        }
                    }

                    //Kiállított szállítólevél
                    if (isTrans && $scope.voucherData.Status == 41) {
                        if (!CheckSellerActCreditLine($scope.sumGrossValue)) {
                            return;
                        }
                    }

                    if ($scope.voucherData.VoucherType === 49 || $scope.voucherData.VoucherType === 60) {
                        $scope.voucherData.ValidityDeadline = $filter('date')($scope.datePickers.validityDeadline.date, 'yyyy.MM.dd');
                    }
                    var products = $scope.products;

                    //Vat beállítása a TaxtRate alapján
                    angular.forEach(products, function (pr) {
                        if (pr.DiscountTypeName == '%') {
                            pr.DiscountType = 0;
                        } else {
                            pr.DiscountType = 1;
                        }

                        pr.Vat = pr.TaxRate.TaxRateValue;
                    });

                    var data = {
                        StockItems: products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null, IsInvoice: isInvoice, Voucher: $scope.voucherData, VoucherId: $scope.voucherData.VoucherId
                    };

                    if (callback && typeof (callback) === "function") {
                        callback(data);
                    }
                }
            });
        }

        function CheckParametersForCustomerOrderAndBid(data) {
            if ($scope.voucherData.PaymentTypeId == 21 && $scope.voucherData.VoucherType != 49 && $scope.voucherData.VoucherType != 60 && $scope.voucherData.VoucherType != 48 && $scope.voucherData.VoucherType != 59 && ($scope.voucherData.VendorBankAccount === null || $scope.voucherData.VendorBankAccount === '')) {
                browserModalService.alert('Átutalásos számlánál a vevő számlaszámának kitöltése kötelező.');
                return false;
            }

            var products = $scope.products;
            var status = $scope.voucherData.VoucherType == 48 || $scope.voucherData.VoucherType == 59 ? $scope.customerOrderStatus.Id : $scope.bidStatus.Id;
            var hasProductIdError = false;

            for (var i = 0; i < products.length; i++) {
                products[i].LastModified = $('#TweakDatePicker').val();

                if (products[i].ProductId == '') {
                    hasProductIdError = true;
                }
            }

            var isCompanyNew = status !== 43 && $scope.AddNewCompany(data, status);
            if (isCompanyNew === null) {
                return false;
            }

            if (products.length > 0 && !hasProductIdError && !isCompanyNew) {
                return true;
            }
            else if (products.length == 0) {
                toasterService.warningToast("Figyelem", "Nem adott meg terméket.");
                return false;
            } else if (hasProductIdError) {
                toasterService.warningToast("Figyelem", "Termék kiválasztása kötelező!");
                return false;
            } else {
                return false;
            }
        }


        $scope.GenerateInvoiceButtonName = 'Számla generálása';
        $scope.canSaveInvoice = false;

        $scope.generateInvoiceFromCustomerOrder = function () {
            $scope.SalesRadio = "Bill";
            $scope.prdate2 = new Date();
            $scope.voucherData.VoucherNumber = '';
            $("ul.selectpicker li.disabled").removeClass('disabled');
            $("#cashregister select").removeAttr('disabled');
            $("#cashregister button").removeClass('disabled');
            $("#language select").removeAttr('disabled');
            $("#language button").removeClass('disabled');
            $("#currency select").removeAttr('disabled');
            $("#currency button").removeClass('disabled');

            $scope.GenerateInvoiceButtonName = 'Számla nyomtatása';
            $scope.canSaveInvoice = true;
            $scope.IsPaperFrom = false;
            $scope.isFromOrder = true;
        };

        $scope.saveInvoiceFromCustomerOrder = function () {
            browserModalService.confirm('Valóban ki akarja nyomtatni?').then(function (r) {
                if (!r) {
                    return;
                } else {
                    var isCompanyNew = status !== 43 && $scope.AddNewCompany(data, status);
                    if (isCompanyNew === null) {
                        return;
                    }
                    var checkActCerditLine = true;

                    //Ha visszaigazolt vevői megrendelést forgatunk át számlára, akkor nem kell az aktuális hitelkeretet figyelni
                    if (($scope.voucherData.VoucherType == 48 || $scope.voucherData.VoucherType === 59) && $scope.voucherData.VoucherStatus == 63) {
                        checkActCerditLine = false;
                    }
                    var isVoucherItemsOk = CheckVoucherItems.Check($scope.products, $scope.voucherData);

                    var isVoucherOk = CheckVoucher.Check($scope.products, $scope.voucherData);

                    if (!isCompanyNew && isVoucherItemsOk && isVoucherOk) {
                        var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
                        var status = 41;
                        var voucherType = getVoucherType($scope.SalesRadio);
                        var tempDates = {
                            IssueDate: $scope.voucherData.IssueDate,
                            DeadlineDate: $scope.voucherData.DeadlineDate,
                            FulfillmentDate: $scope.voucherData.FulfillmentDate
                        };
                        $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                        $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                        $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                        $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                        $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                        $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                        $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                        $scope.voucherData.Status = status;
                        $scope.voucherData.VoucherType = voucherType;

                        $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                        //Ha ellenőrizni kell az aktuális hitelkeretet
                        if (checkActCerditLine) {
                            //Számla és a fizetési mód átutalás
                            if (isInvoice && $scope.voucherData.PaymentTypeId == 21) {
                                var result = CheckSellerActCreditLine($scope.sumGrossValue);
                                if (!result) {
                                    return;
                                }
                            }
                        }

                        //Vat beállítása a TaxtRate alapján
                        angular.forEach($scope.products, function (pr) {
                            pr.Vat = pr.TaxRate.TaxRateValue;
                        });

                        var orderList = [];
                        orderList.push($scope.voucherData.VoucherId);
                        var data = {
                            StockItems: $scope.products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null, IsInvoice: isInvoice, Voucher: $scope.voucherData, CustomerOrderList: orderList
                        };

                        $http.post(apiurl.stock.takeOutFromCustomerOrder, data).success(function (sdata) {
                            $scope.ClearFrontEnd(sdata);
                            //A generált számla visszakérése az id alapján
                            $http.get(apiurl.voucher.getVoucherById + sdata).success(function (voucherdata) {
                                $scope.voucherData.VoucherNumber = voucherdata.VoucherNumber;
                                //A pénztár lista kinyitásának letiltása
                                $("select[id='cashregister']").attr('disabled', 'disabled');
                                //A számla nyomtatás gombra való nyomtatásához kell
                                tId = parseInt(tId);
                                tId += 1;
                                //A sztornó és a helyesbítő számla aktívvá tétele
                                $("button[id='stornoBtn']").removeAttr('disabled');
                            });
                            $scope.canSaveInvoice = false;
                        }).error(function (data, status) {
                            if (data.Message.indexOf("Argument validation failed") > -1) {
                                $scope.voucherData.CustomerName = '';
                                $scope.voucherData.Status = undefined;
                                $scope.voucherData.VoucherType = $scope.IsPaperFrom ? 59 : 48;
                                $scope.voucherData.PaymentTypeId = undefined;
                                $scope.voucherData.CurrencyTypeId = undefined;
                                $scope.voucherData.LanguageTypeId = undefined;
                                $scope.voucherData.IssueDate = tempDates.IssueDate;
                                $scope.voucherData.DeadlineDate = tempDates.DeadlineDate;
                                $scope.voucherData.FulfillmentDate = tempDates.FulfillmentDate;
                                $scope.voucherData.CashDesk = undefined;
                                browserModalService.alert(data.Notifications[0].Target);
                            } else {
                                $scope.voucherData.CustomerName = '';
                                $scope.voucherData.Status = undefined;
                                $scope.voucherData.VoucherType = $scope.IsPaperFrom ? 59 : 48;
                                $scope.voucherData.PaymentTypeId = undefined;
                                $scope.voucherData.CurrencyTypeId = undefined;
                                $scope.voucherData.LanguageTypeId = undefined;
                                $scope.voucherData.IssueDate = tempDates.IssueDate;
                                $scope.voucherData.DeadlineDate = tempDates.DeadlineDate;
                                $scope.voucherData.FulfillmentDate = tempDates.FulfillmentDate;
                                $scope.voucherData.CashDesk = undefined;
                                browserModalService.alert(data.Message);
                            }
                            console.log("sajat " + data);
                            console.log("sajat " + status);
                        });
                    }
                }
            });
        };

        function TakeOutPost(pdata, isCustomerOrderOrBid) {
            var postUrl = apiurl.stock.takeOut;
            if (isCustomerOrderOrBid) {
                if ($scope.voucherData.VoucherType == 48 || $scope.voucherData.VoucherType === 59) {
                    postUrl = apiurl.stock.createCustomerOrder;
                } else {
                    postUrl = apiurl.stock.createBid;
                }
            }
            if ($scope.isFromMoreDelivery) {
                postUrl = apiurl.stock.invoiceFromMoreDelivery;
                pdata.DeliveryIdentities = $scope.deliveryIdList;
            }

            $http.post(postUrl, pdata).success(function (data) {
                runningTakeOutStockMethod = false;
                ////Mielőtt visszaállítanánk az alapértelmezett értékeket visszaállítjuk a formot pristinere
                $scope.ClearFrontEnd(data);
            })
            .error(function (data) {
                $scope.loaderIsVisibleForTakeOut = false;
                toasterService.errorFromServerToast(data);
            }).then(function () {
                // Töltési animáció láthatósága
                $scope.loaderIsVisibleForTakeOut = false;
            });
        }

        $scope.ClearFrontEnd = function (data) {
            if ($scope.newTweak) {
                $scope.voucherData = {
                    VoucherNumber: '',
                    CustomerName: '',
                    CustomerAddress: '',
                    CustomerDeliveryAddress: '',
                    CustomerBankAccount: '',
                    CustomerTaxNumber: '',
                    CustomerEUTaxNumber: '',
                    IssueDate: '',
                    FulfillmentDate: '',
                    DeadlineDate: '',
                    LanguageTypeId: '',
                    CurrencyTypeId: '',
                    PaymentTypeId: '',
                    ContinuousTransaction: false,
                    IsPaid: true,
                    CashDesk: null,
                    RoundingDiff: 0,
                    DiscountType: 0,
                    DiscountAmount: 0,
                    DiscountTypeName: '%'
                };
                $scope.datePickers = {
                    deadline: {
                        isOpen: false, date: new Date()
                    },
                    fulfillment: {
                        isOpen: false, date: new Date()
                    },
                    validityDeadline: {
                        isOpen: false, date: new Date()
                    }
                };
                $scope.prdate2 = new Date();
                $scope.IsPaperFrom = false;
                $scope.sumInvoiceDiscount = '';
                $scope.products = [];
                $scope.canChange();
                $scope.vatSummList = [];
                $scope.actCompany = [];
                $scope.sumGrossValue = [];
                productsHasGotDiscountManually = [];
                $scope.actWarehouse = getDefaultOrFirstIfAny($scope.defaultWarehouse, $scope.Warehouses);
                $scope.voucherDiscountForProducts = null;
                $scope.warehouseOnSelect($scope.actWarehouse);
                $http.get(apiurl.stock.getOwnerCompanyDatas).success(function (data) {
                    if (data !== null) {
                        $scope.OwnerCompanyBankAccounts = data.OwnerCompanyBankAccounts;
                        MapObject.OwnerCompanyToVoucherDto(data, $scope.voucherData);
                    }

                    $http.get(apiurl.stock.getBaseDataForTakeOut + userId).success(function (data) {
                        $scope.Warehouses = data.UserData.Warehouses;

                        $scope.Languages = data.Languages;
                        $scope.Language = $scope.Languages[0];

                        $scope.Currencies = data.Currencies;
                        $scope.Currency = $scope.Currencies[0];

                        $scope.PaymentTypes = data.PaymentTypes;
                        if ($scope.SalesRadio !== "Bill" && $scope.SalesRadio !== "Voucher") {
                            $scope.PaymentType = $scope.PaymentTypes.length == 2 ? $scope.PaymentTypes[1] : $scope.PaymentTypes[3];
                        } else {
                            $scope.PaymentType = $scope.PaymentTypes[0];
                        }
                    });

                    angular.forEach($scope.Users, function (value, key) {
                        if (value.Id == userId) {
                            $scope.actualUser = {
                                Name: value.Name,
                                UserName: value.UserName,
                                Id: value.Id
                            };
                        }
                    });
                });
            }

            var pdfData = data;
            if ($scope.pricesVisible == true) {
                window.location.href = apiurl.stock.generateDocument + pdfData + '/' + $scope.pricesVisible;
            } else {
                window.location.href = apiurl.stock.generateDocument + pdfData;
            }
        };

        $scope.SaveTemp = function (pdata) {
            var sendUrl = $scope.newTweak ? '' : '';
            $http.post(sendUrl, pdata).success(toasterService.saveSuccess()).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.productTypes = productTypes;

        $scope.products = [];
        $scope.addRow = function () {
            var product = {
                Type: $scope.productTypes[0],
                Name: '',
                Quantity: 1,
                QuantityType: '',
                NetPrice: '',
                Vat: 27,
                DiscountType: 0,
                DiscountAmount: 0,
                Prefix: '',
                StartNumberOfRange: null,
                EndNumberOfRange: null,
                DiscountTypeName: '%',
                TaxRateId: null,
                CostCenters: [],
                isNew: true
            };
            $scope.products.push(product);
        };

        $scope.getProducts = function (productName, index) {
            if (productName.length < 3) {
                return [];
            }

            $scope.isBarcode = false;

            if (angular.isNumber(Number(productName))) {
                if (productName.length == 13) {
                    $scope.isBarcode = true;
                }
            }

            var partnerId = -1;
            if ($scope.actCompany !== null && typeof $scope.actCompany.Id !== 'undefined') {
                partnerId = $scope.actCompany.Id;
            }

            var getUrl;
            var params = {
                params: { productName: productName }
            };
            if ($scope.SalesRadio !== 'Order' && $scope.SalesRadio !== 'Bid' && !$scope.isFromOrder) {
                getUrl = apiurl.stock.getProductsOfWarehouse + '/' + $scope.actWarehouse.Id + '/' + partnerId + '/' + params.params.productName;

            } else if ($scope.SalesRadio === 'Order' && !$scope.isFromOrder) {
                getUrl = apiurl.stock.getProducts + '/' + productName + '/' + partnerId + '/' + $scope.actWarehouse.Id;

            } else if ($scope.isFromOrder) {
                getUrl = apiurl.stock.getProducts + '/' + productName + '/' + partnerId + '/' + $scope.actWarehouse.Id;
                params = { params: { productName: productName, onlyService: $scope.isFromOrder } };

            } else {
                getUrl = apiurl.stock.getProducts + '/' + productName + '/' + partnerId + '/' + 0;
            }

            return $http.get(getUrl, params).then(function (data) {
                if (data && data.data && data.data.length === 0) {
                    return [];
                }

                var productCount = data.data.length;
                if (productCount > 200) {
                    browserModalService.alert('Kérem pontosítsa a termék nevét');
                    return [];
                }

                if ($scope.isBarcode) {
                    $scope.taOnSelect(data.data[0], index);
                }

                return data.data;
            });
        };

        $scope.billDiscountType = "%";
        $scope.billDiscount = function () {
            if ($scope.billDiscountType === "%") {
                $scope.billDiscountType = "Fix";
            }
            else {
                $scope.billDiscountType = "%";
            }
        };

        $scope.discountButton = function (index) {
            if ($scope.products[index].DiscountTypeName === "%") {
                $scope.products[index].DiscountTypeName = "Fix";
                $scope.products[index].DiscountType = 1;
                $scope.changeRow(index);
            }
            else {
                $scope.products[index].DiscountTypeName = "%";
                $scope.products[index].DiscountType = 0;
                $scope.changeRow(index);
            }
        };

        $scope.invoiceDiscountButton = function () {
            if ($scope.voucherData.DiscountTypeName === "%") {
                $scope.voucherData.DiscountTypeName = "Fix";
                $scope.voucherData.DiscountType = 1;
                $scope.DiscountInvoiceMethod(false);
            }
            else {
                $scope.voucherData.DiscountTypeName = "%";
                $scope.voucherData.DiscountType = 0;
                $scope.DiscountInvoiceMethod(true);
            }
        };

        $scope.taOnSelect = function ($model, $index) {
            angular.forEach($scope.productTypes, function (pt) {
                if ($model.TypeId == pt.Id) {
                    $scope.products[$index].Type = pt;
                    $scope.products[$index].TypeId = pt.Id;
                    $scope.products[$index].ProductTypeId = pt.Id;
                }
            });

            var netprice = $model.NetWholesalePrice == 0 && $model.NetRetailPrice == 0
                            ? $model.NetPurchasePrice
                            : ($scope.actCompany != null && $scope.actCompany.IsWholesale)
                                ? $model.NetWholesalePrice
                                : $model.NetRetailPrice;

            $scope.products[$index].Name = $model.Name;
            $scope.products[$index].ProductId = $model.Id;
            $scope.products[$index].NetPrice = getNetprice($model, netprice);
            $scope.products[$index].Vat = $model.Vat;
            $scope.products[$index].QuantityType = $model.QuantityType;
            $scope.products[$index].QuantityTypeId = $model.QuantityTypeId;
            $scope.products[$index].TaxRateId = $model.TaxRateId;
            $scope.products[$index].PartnerDiscountRate = $model.PartnerDiscountRate;
            $scope.products[$index].PartnerDiscountType = $model.PartnerDiscountType;

            angular.forEach($scope.TaxRates, function (value, key) {
                if ($scope.products[$index].TaxRateId === value.Id) {
                    $scope.products[$index].TaxRate = value;
                }
            });

            getQuantityTypesByProductId($model.Id).then(function (response) {
                $scope.products[$index].quantityTypes = response;
            });
            $scope.changeRow($index);

            $scope.getManufacturingProcessesByProductId($model.Id);
        };

        function getNetprice(product, netPrice) {

            if (product.PartnerDiscountRate != null) {
                if (product.PartnerDiscountType == 'Ft') {
                    netPrice -= product.PartnerDiscountRate;
                }
                else if (product.PartnerDiscountType == '%') {
                    netPrice *= ((100 - product.PartnerDiscountRate) / 100);
                }
            }
            return Number(netPrice.toFixed(2));
        }

        function getQuantityTypesByProductId(productId) {
            return $http.get(apiurl.stock.getQuantityTypesByProductId + '/' + productId).then(function (response) {
                return response.data;
            });
        }

        //Új termék gomb tiltása/feloldása
        $scope.newItemDisabled = true;
        $scope.buyerBankAccountRequired = false;
        //**//
        $scope.$watchCollection(
            '[' + 'voucherData.CreationOwner'
            + ', voucherData.VendorName'
            + ', voucherData.VendorAddress'
            + ', voucherData.VendorBankAccount'
            + ', voucherData.VendorTaxNumber'
            + ', voucherData.VendorEUTaxNumber'
            + ', actCompany'
            + ', actWarehouse'
            + ', voucherData.CustomerAddress'
            + ', voucherData.CustomerBankAccount'
            + ', voucherData.CustomerDeliveryAddress'
            + ', PaymentType.Id'
            + ', SalesRadio'
            + ', cashRegister'
            + ']'
            , function () {
                if (typeof $scope.voucherData === 'undefined') {
                    return;
                }

                var actWarehouseFailed = typeof $scope.actWarehouse === 'undefined' || $scope.actWarehouse === "" || typeof $scope.actWarehouse !== 'object';
                var sellerNameFailed = typeof $scope.voucherData.VendorName === 'undefined' || $scope.voucherData.VendorName === "";
                var sellerAddressFailed = typeof $scope.voucherData.VendorAddress === 'undefined' || $scope.voucherData.VendorAddress === "";
                var customerDeliveryAddressFailed = typeof $scope.voucherData.CustomerDeliveryAddress === 'undefined' || $scope.voucherData.CustomerDeliveryAddress === "";

                if (actWarehouseFailed || sellerNameFailed || sellerAddressFailed || customerDeliveryAddressFailed) {
                    $scope.newItemDisabled = true;
                    return;
                }

                var cashRegisterFailed = !$scope.cashRegister || typeof $scope.cashRegister.Id === 'undefined';
                if ($scope.SalesRadio !== "Trans" && cashRegisterFailed) {
                    $scope.newItemDisabled = true;
                    return;
                }

                if ($scope.SalesRadio == "Voucher") {
                    $scope.newItemDisabled = false;
                    return;
                }

                if ($scope.SalesRadio == "Bid") {
                    $scope.newItemDisabled = false;
                    return;
                }

                var sellerBankAccountFailed = typeof $scope.voucherData.VendorBankAccount === 'undefined' || $scope.voucherData.VendorBankAccount === "";
                var sellerTaxNumberFailed = typeof $scope.voucherData.VendorTaxNumber === 'undefined' || $scope.voucherData.VendorTaxNumber === "";
                var buyerAddressFailed = typeof $scope.voucherData.CustomerAddress === 'undefined' || $scope.voucherData.CustomerAddress === "";

                if (sellerBankAccountFailed || sellerTaxNumberFailed || buyerAddressFailed) {
                    $scope.newItemDisabled = true;
                    return;
                }

                if ($scope.PaymentType !== undefined && $scope.PaymentType.Id !== null && $scope.PaymentType.Id === 21) {
                    //Ha számla és a fizetési mód átutalás, akkor ki kell számolni az aktuális hitelkeretet
                    if ($scope.SalesRadio === "Bill" && $scope.PaymentType.Id == 21) {
                        if (!runningTakeOutStockMethod && (angular.isUndefined($scope.voucherData.VoucherStatus) || $scope.correctionVis)) {
                            //A cégnek van-e tartozása
                            isDebitOfActCompany();
                        }
                    }
                } else {
                    $scope.buyerBankAccountRequired = false;
                    $scope.newItemDisabled = false;
                };
            });

        $scope.warehouseOnSelect = function (model) {
            $scope.costCenters = [];
            if (model.CostCenters.length > 0) {
                var percentage = Number((100 / $scope.actWarehouse.CostCenters.length).toFixed(0))
                var error = Number((100 % $scope.actWarehouse.CostCenters.length).toFixed(0))
                angular.forEach($scope.actWarehouse.CostCenters, function (costCenter) {
                    var tempCostCenter = {
                        Id: costCenter.Id,
                        Name: costCenter.Name,
                        loadingRate: percentage
                    };
                    $scope.costCenters.push(tempCostCenter);
                });

                $scope.costCenters[0].loadingRate += error;
            }
        };

        var tempPaymentTypeId = null;

        $scope.paymentTypeChange = function () {
            var date;
            if ($scope.PaymentType.Id === 21 && !angular.isUndefined($scope.actCompany.PaymentDeadline)) {
                tempPaymentTypeId = $scope.PaymentType.Id;
                date = new Date();
                date.setDate(date.getDate() + Number($scope.actCompany.PaymentDeadline));
                $scope.datePickers.deadline.date = date;
            }
            if (tempPaymentTypeId !== null && $scope.PaymentType.Id !== 21 && tempPaymentTypeId === 21) {
                date = new Date();
                $scope.datePickers.deadline.date = date;
            }
        }

        $scope.$watch("PaymentType", function () {
            if (typeof $scope.PaymentType === 'undefined') {
                return;
            }
            if ($scope.PaymentType.Id != null && $scope.PaymentType.Id === 21 && $scope.newTweak) {
                $scope.paymentTypeChange();
                $scope.PaidBtnOff = true;
            } else {
                $scope.PaidBtnOff = false;
            }

            if ($scope.products.length > 0) {
                $scope.changeRow(0);
            }
        });

        $scope.$watch("SalesRadio", function () {
            if ($scope.newTweak) {
                $scope.voucherData.VoucherNumber = null;
            }
            $scope.pricesVisible = false;
            if ($scope.newTweak && angular.isObject($scope.PaymentTypes) && $scope.SalesRadio !== "Trans" && $scope.SalesRadio !== "Order") {
                if ($scope.SalesRadio === "Voucher") {
                    $scope.PaymentTypes = $scope.VoucherPaymentTypes;
                }
                else {
                    $scope.PaymentTypes = $scope.TempPaymentTypes;
                }
                $scope.PaymentType = $scope.PaymentTypes[0];
            }

            if (!angular.isUndefined($scope.PaymentTypes))
                if ($scope.SalesRadio !== "Bill" && $scope.SalesRadio !== "Voucher") {
                    $scope.PaymentType = $scope.PaymentTypes.length == 2 ? $scope.PaymentTypes[1] : $scope.PaymentTypes[3];
                } else {
                    $scope.PaymentType = $scope.PaymentTypes[0];
                }
        });

        $scope.getTakeOutCompanies = function (companyName) {
            return $http.get(apiurl.stock.getCompanies + '/' + companyName + '/false').then(function (response) {
                return response.data;
            });
        };

        $scope.selectActCompany = function (id) {
            $http.get(apiurl.stock.getCompanyById + '/' + id).success(function (data) {
                $scope.actCompany = data;

                mapToVoucherCustomer(data);
                shoulCountDebitAmount();
                setCustomerPaymentType();
                updateSelectedProducts();
            });
        };

        function mapToVoucherCustomer(company) {
            $scope.voucherData.CustomerAddress = company.Address || $scope.voucherData.CustomerAddress || '';
            $scope.voucherData.CustomerDeliveryAddress = company.DeliveryAddress || company.Address || $scope.voucherData.CustomerDeliveryAddress || '';
            $scope.voucherData.CustomerBankAccount = company.BankAccount || $scope.voucherData.CustomerBankAccount || '';
            $scope.voucherData.CustomerTaxNumber = company.TaxNumber || $scope.voucherData.CustomerTaxNumber || '';
            $scope.voucherData.CustomerEUTaxNumber = company.EUTaxNumber || $scope.voucherData.CustomerEUTaxNumber || '';

            $scope.voucherData.CustomerZip = company.AddressObject.ZipCode;
            $scope.voucherData.CustomerCity = company.AddressObject.City;
            $scope.voucherData.CustomerDistrict = company.AddressObject.District;
            $scope.voucherData.CustomerPubliclyOwnedLandName = company.AddressObject.PubliclyOwnedLandName;
            $scope.voucherData.CustomerPubliclyOwnedLandType = company.AddressObject.PubliclyOwnedLandType;
            $scope.voucherData.CustomerHouseNumber = company.AddressObject.HouseNumber;
            $scope.voucherData.CustomerBuilding = company.AddressObject.Building;
            $scope.voucherData.CustomerStairway = company.AddressObject.Stairway;
            $scope.voucherData.CustomerFloor = company.AddressObject.Floor;
            $scope.voucherData.CustomerDoor = company.AddressObject.Door;

            $scope.PayId = company.TypeOfPaymentId || $scope.PayId || '';
        }

        function shoulCountDebitAmount() {
            if (!$scope.newTweak
                || !$scope.actCompany.DebitAmount) {
                return;
            }
            //Ha számla és a fizetési mód átutalás, akkor ki kell számolni az aktuális hitelkeretet
            if ($scope.SalesRadio === "Bill" && $scope.PaymentType.Id == 21) {
                //DebitAmount - a céghez tartozó tartozások összege
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
            }
            //Ha kiállított szállítólevél, akkor ki kell számolni az aktuális hitelkeretet
            if ($scope.SalesRadio === "Trans" && $scope.voucherData.Status == 41) {
                //DebitAmount - a céghez tartozó tartozások összege
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
            }
            //Ha vevői megrendelés, amely nem törölt és teljesített, akkor ki kell számolni az aktuális hitelkeretet
            if ($scope.SalesRadio === "Order" && $scope.voucherData.Status != 64 && $scope.voucherData.Status != 65) {
                //DebitAmount - a céghez tartozó tartozások összege
                CurrentCreditAmountCount($scope.actCompany, $scope.actCompany.DebitAmount);
            }
        }

        function setCustomerPaymentType() {
            if (!$scope.PayId) {
                return;
            }
            angular.forEach($scope.PaymentTypes, function (pt) {
                if (pt.Id !== $scope.PayId) {
                    return;
                }

                if ($scope.SalesRadio === "Bill") {
                    $scope.PaymentType = pt;
                }
            });
        }

        function updateSelectedProducts() {
            if (!$scope.actCompany || !$scope.newTweak) {
                return;
            }
            angular.forEach($scope.products, function (product, key) {
                var productName = product.Name;
                $http.get(apiurl.stock.getProductsOfWarehouse + '/' + $scope.actWarehouse.Id + '/' + $scope.actCompany.Id + '/' + productName).success(function (data) {
                    if (data[0].Name == productName) { //aszinkron gond, több termék esetén összekeveri, hogy a products tömbből mely indexű terméket kell frissítenie -> így védem ki
                        $scope.taOnSelect(data[0], key);
                    } else {
                        angular.forEach($scope.product, function (product2, key2) {
                            if (data[0].Name == productName) {
                                $scope.taOnSelect(data[0], key2);
                            }
                        });
                    }
                });
            });
        }

        $scope.canChange = function () {
            if ($scope.products.length > 0) {
                return true;
            }

            return false;
        };

        $scope.deleteRow = function (index) {
            // Ha volt hozzáadva kézzel kedvezmény, akkor törlés a tömbből
            if (productsHasGotDiscountManually.length > 0) {
                for (var i = 0; i < productsHasGotDiscountManually.length; i++) {
                    if (productsHasGotDiscountManually[i].Name === $scope.products[index].Name) {
                        productsHasGotDiscountManually.splice(i, 1);
                    }
                }
            }

            $scope.products.splice(index, 1);
            $scope.sumGrossValue = 0;
            $scope.sumInvoiceDiscount = 0;
            $scope.vatSummList = [];
            //Kerekítés
            $scope.InvoiceRounding();

            //Áfaösszesítő
            $scope.InvoiceVatSum();
        };

        $scope.changeQuantityType = function (index) {
            angular.forEach($scope.products[index].quantityTypes, function (item) {
                if ($scope.products[index].QuantityTypeId === item.Id) {
                    $scope.products[index].QuantityType = item.Name;
                }
            });

            getNetpriceForQuantityType($scope.products[index].ProductId, $scope.products[index].QuantityTypeId).then(function (response) {
                var netPrice = response.WholesalePrice == 0 && response.RetailPrice == 0
                            ? $scope.products[index].NetPrice
                            : ($scope.actCompany != null && $scope.actCompany.IsWholesale)
                                ? response.WholesalePrice
                                : response.RetailPrice;                

                if (response.PriceListGroupPrices.length > 0) {
                    angular.forEach(response.PriceListGroupPrices, function(price) {
                        if ($scope.actCompany && price.CompanyId == $scope.actCompany.Id) {
                            netPrice = price.NetPrice;
                        }
                    });
                }

                $scope.products[index].NetPrice = getNetprice($scope.products[index], netPrice);

                $scope.changeRow(index);
            });
        };

        function getNetpriceForQuantityType(productId, quantityTypeId) {
            var url = apiurl.stock.getNetpriceForQuantityType + '/' + productId + '/' + quantityTypeId;

            return $http.get(url).then(function (response) {
                return response.data;
            });
        }

        $scope.sumGrossValue = 0;
        $scope.sumInvoiceDiscount = 0;
        $scope.vatSummList = [];

        $scope.changeRow = function (index) {
            if (angular.isUndefined($scope.products[index])
                || angular.isUndefined($scope.products[index].Name)
                || angular.isUndefined($scope.products[index].NetPrice)
                || $scope.products[index].Name === '') {
                return;
            }
            if ($scope.products[index].NetPrice !== undefined) {
                $scope.products[index].NetPrice = $scope.products[index].NetPrice.toString().replace(',', '.');
            }
            $scope.DiscountMethods($scope.products[index]);
            $scope.CalculateAllProductsOfDiscount();

            //Alapsori számítások
            var vatValue = 0;
            if ($scope.products[index].TaxRate == undefined) {
                vatValue = $scope.products[index].Vat;
            } else {
                vatValue = $scope.products[index].TaxRate.TaxRateValue;
            }

            if ($scope.products[index].Type.Name == "Szolgáltatás") {
                $scope.products[index].GrossPrice = PriceCounter.GrossPrice($scope.products[index].CalculatedNetPrice, vatValue);
                if ($scope.products[index].Quantity > 0) {
                    $scope.products[index].NetValue = PriceCounter.NetValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity);
                    $scope.products[index].GrossValue = PriceCounter.GrossValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity, vatValue);
                    $scope.products[index].VatValue = PriceCounter.VatValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity, vatValue);
                }
            } else {
                if ($scope.products[index].CalculatedNetPrice >= 0) {
                    $scope.products[index].GrossPrice = PriceCounter.GrossPrice($scope.products[index].CalculatedNetPrice, vatValue);
                }
                if ($scope.products[index].CalculatedNetPrice >= 0 && $scope.products[index].Quantity > 0) {
                    $scope.products[index].NetValue = PriceCounter.NetValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity);
                    $scope.products[index].GrossValue = PriceCounter.GrossValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity, vatValue);
                    $scope.products[index].VatValue = PriceCounter.VatValue($scope.products[index].CalculatedNetPrice, $scope.products[index].Quantity, vatValue);
                }
            }
            $scope.products[index].TaxRateId = !angular.isUndefined($scope.products[index].TaxRate) ? $scope.products[index].TaxRate.Id : $scope.products[index].TaxRateId;

            //Kerekítés és Összesen
            $scope.InvoiceRounding();

            //Áfaösszesítő
            $scope.InvoiceVatSum();
        };

        //ÁFA összesítő
        $scope.InvoiceVatSum = function () {
            $scope.vatSummList = [];
            angular.forEach($scope.products, function (product) {
                var vt = null;
                var vat = 0;
                if ($scope.vatSummList.length == 0 && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                    vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                    vt = {
                        Vat: vat, NetValue: product.NetValue, VatValue: product.VatValue, GrossValue: product.GrossValue
                    };
                    $scope.vatSummList.push(vt);
                } else {
                    var isIn = false;
                    angular.forEach($scope.vatSummList, function (vtValue) {
                        vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                        if (vat == vtValue.Vat && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                            vtValue.NetValue += product.NetValue;
                            vtValue.VatValue += product.VatValue;
                            vtValue.GrossValue += product.GrossValue;
                            isIn = true;
                        }
                    });
                    if (isIn === false && !isNaN(product.NetValue) && !isNaN(product.VatValue) && !isNaN(product.GrossValue)) {
                        vat = !isNaN(product.TaxRate) || angular.isObject(product.TaxRate) ? product.TaxRate.TaxRateValue : product.Vat;
                        vt = {
                            Vat: vat, NetValue: product.NetValue, VatValue: product.VatValue, GrossValue: product.GrossValue
                        };
                        $scope.vatSummList.push(vt);
                    }
                }
            });
        };

        //Kerekítés
        $scope.InvoiceRounding = function () {
            $scope.voucherData.RoundingDiff = 0;
            var sumGross = 0;
            if ($scope.PaymentType.Id === 20) {
                angular.forEach($scope.products, function (product) {
                    if (!isNaN(product.GrossValue)) {
                        sumGross += product.GrossValue;
                    }
                });
                $scope.voucherData.RoundingDiff = PriceCounter.Rounding(sumGross);
            } else {
                angular.forEach($scope.products, function (product) {
                    if (!isNaN(product.GrossValue)) {
                        sumGross += product.GrossValue;
                    }
                });
            }
            $scope.sumGrossValue = Number((sumGross + $scope.voucherData.RoundingDiff).toFixed(0));
        };

        //A számlára adott kedvezmény beállításai
        $scope.changeInvoiceDiscount = function () {
            if ($scope.voucherData.DiscountTypeName === "%") {
                $scope.DiscountInvoiceMethod(true);
            }
            else {
                $scope.DiscountInvoiceMethod(false);
            }
        };

        $scope.sumInvoiceDiscountPerProduct = 0;
        //Termékenkénti kedvezmények számítása
        $scope.DiscountMethods = function (product) {
            $scope.sumInvoiceDiscountPerProduct = 0;
            if (product.DiscountAmount <= 0) {
                product.CalculatedNetPrice = product.NetPrice;
                $scope.sumInvoiceDiscountPerProduct = 0;
            } else if (product.DiscountAmount > 0 && (product.DiscountType === 0 || product.DiscountTypeName === '%')) {
                var disc = product.NetPrice * (product.DiscountAmount / 100);
                product.CalculatedNetPrice = product.NetPrice - disc;
                $scope.sumInvoiceDiscountPerProduct += disc;
            } else if (product.DiscountAmount > 0 && (product.DiscountType === 1 || product.DiscountTypeName === 'Ft')) {
                product.CalculatedNetPrice = product.NetPrice - product.DiscountAmount;
                $scope.sumInvoiceDiscountPerProduct += product.DiscountAmount;
            }
        };

        //Összesített kedvezmény számítása
        $scope.CalculateAllProductsOfDiscount = function () {
            $scope.sumInvoiceDiscount = 0;
            angular.forEach($scope.products, function (pr) {
                $scope.DiscountMethods(pr);
                $scope.sumInvoiceDiscount += PriceCounter.GrossValue($scope.sumInvoiceDiscountPerProduct, pr.Quantity, pr.Vat);
            });
        };

        //Számlára adott kedvezmény visszaszámolása termékekre
        $scope.sumGrossValueWithoutDiscount = 0;
        $scope.DiscountInvoiceMethod = function (isPercent) {
            $scope.CalculateSummGrossValueWithoutDiscount();

            if (isPercent) {
                angular.forEach($scope.products, function (pr) {
                    pr.DiscountTypeName = '%';
                    pr.DiscountType = 0;
                    pr.DiscountAmount = $scope.voucherData.DiscountAmount;
                    $scope.changeRow($scope.products.indexOf(pr));
                });
            } else {
                var percentDiscount = 100 - Number(((($scope.sumGrossValueWithoutDiscount - $scope.voucherData.DiscountAmount) / $scope.sumGrossValueWithoutDiscount) * 100).toFixed(2));
                angular.forEach($scope.products, function (pr) {
                    pr.DiscountTypeName = '%';
                    pr.DiscountType = 0;
                    pr.DiscountAmount = percentDiscount;
                    $scope.changeRow($scope.products.indexOf(pr));
                });
            }
        };

        //A kedvezmények nélküli számla bruttó érétékének kiszámítása (az összesen kedvezmények nélkül)
        $scope.CalculateSummGrossValueWithoutDiscount = function () {
            var sumGross = 0;
            var rounding = 0;
            if ($scope.PaymentType.Id === 20) {
                angular.forEach($scope.products, function (product) {
                    if (product.NetPrice >= 0 && product.Vat >= 0 && product.Vat < 101 && product.Quantity > 0) {
                        sumGross += PriceCounter.GrossValue(product.NetPrice, product.Quantity, product.Vat);
                    }
                });
                rounding = PriceCounter.Rounding(sumGross);
            } else {
                angular.forEach($scope.products, function (product) {
                    if (product.NetPrice >= 0 && product.Vat >= 0 && product.Vat < 101 && product.Quantity > 0) {
                        sumGross += PriceCounter.GrossValue(product.NetPrice, product.Quantity, product.Vat);
                    }
                });
            }
            $scope.sumGrossValueWithoutDiscount = Number((sumGross + rounding).toFixed(0));
        };

        $scope.deleteVoiceBtnClick = function () { };
        $scope.disableCorrection = false;

        $scope.cancellationBtnClick = function () {
            browserModalService.confirm('Valóban sztornózni szeretné a számlát?').then(function (r) {
                if (r) {
                    if ($scope.voucherData.VoucherType == 57) {
                        toasterService.successToast("", "Papír alapú számla sztornózása egyenlőre nem üzemel!");
                        return;
                    }
                    //$scope.tweakForm.$setPristine();
                    if ($scope.voucherData.VoucherType == 53) {
                        $http.get(apiurl.stock.cancelRecifyInvoice + '/' + tId).success(function (data) {
                            toasterService.successToast("", "A számla sztornózva!");
                            window.location.href = apiurl.stock.generateDocument + data;
                            $scope.stornoBtnDis = true;
                            $scope.disableCorrection = true;
                        }).error(function (data, status) {
                            toasterService.errorToast("", "A feldolgozás sikertelen!");
                            window.location.href = backUrl;
                        });
                    }
                    else {
                        $http.post(apiurl.voucher.cancelInvoiceById + tId).success(function (data) {
                            toasterService.successToast("", "A számla sztornózva!");
                            window.location.href = apiurl.stock.generateDocument + data;
                            $scope.stornoBtnDis = true;
                            $scope.disableCorrection = true;
                        }).error(function (data, status) {
                            toasterService.errorFromServerToast(data);
                        });
                    }
                }
            });
        };

        $scope.backButtonClick = function () {
            browserModalService.confirm('Valóban ki akar lépni?').then(function (r) {
                if (r) {
                    window.location.href = backUrl;
                }
            });
        };

        $scope.TemporarySave = function (products) {
            browserModalService.confirm('El akarja menteni ideiglenesen?').then(function (r) {
                if (!r) {
                    return;
                } else {
                    var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
                    var status = $scope.SalesRadio !== 'Order' ? 66 : 61;
                    var voucherType = getVoucherType($scope.SalesRadio);
                    $scope.voucherData.CustomerName = $scope.actCompany != null && angular.isObject($scope.actCompany) ? $scope.actCompany.Name : '';
                    $scope.voucherData.IssueDate = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
                    $scope.voucherData.DeadlineDate = $filter('date')($scope.datePickers.deadline.date, 'yyyy.MM.dd');
                    $scope.voucherData.FulfillmentDate = $filter('date')($scope.datePickers.fulfillment.date, 'yyyy.MM.dd');
                    $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                    $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                    $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                    $scope.voucherData.Status = status;
                    $scope.voucherData.VoucherType = voucherType;

                    $scope.voucherData.CashDesk = getCashDeskByVoucherTypeId(voucherType);

                    //Számla és a fizetési mód átutalás
                    if (isInvoice && $scope.voucherData.PaymentTypeId == 21) {
                        var result = CheckSellerActCreditLine($scope.sumGrossValue);
                        if (!result) {
                            return;
                        }
                    }

                    var data = {
                        StockItems: products, WarehouseId: $scope.actWarehouse.Id, CompanyId: $scope.actCompany != null ? $scope.actCompany.Id : null, IsInvoice: isInvoice, Voucher: $scope.voucherData
                    };

                    var hasStrictError = false;
                    var hasRangeError = false;
                    var hasProductIdError = false;

                    for (var i = 0; i < products.length; i++) {
                        data.StockItems[i].LastModified = $('#TweakDatePicker').val();

                        if (data.StockItems[i].ProductId == '') {
                            hasProductIdError = true;
                        }

                        if (data.StockItems[i].Type.Id === 28) {
                            if (data.StockItems[i].Prefix == '' || data.StockItems[i].StartNumberOfRange == null || data.StockItems[i].EndNumberOfRange == null) {
                                hasStrictError = true;
                            }
                            if (data.StockItems[i].StartNumberOfRange > data.StockItems[i].EndNumberOfRange || data.StockItems[i].StartNumberOfRange.length != data.StockItems[i].EndNumberOfRange.length) {
                                hasRangeError = true;
                            }
                        }
                    }

                    if (!CheckVoucher.Check(products, $scope.voucherData))
                        return;

                    var isCompanyNew = status !== 43 && $scope.AddNewCompany(data, status);
                    if (isCompanyNew === null) {
                        return;
                    }

                    if ($scope.products.length > 0 && !hasStrictError && !hasRangeError && !hasProductIdError && !isCompanyNew) {
                        $scope.SaveTemp(data);
                    }
                    else if ($scope.products.length == 0) {
                        toasterService.warningToast("Figyelem", "Nem adott meg terméket.");
                    } else if (hasStrictError) {
                        toasterService.warningToast("Figyelem", "A szigorú számadású termékek prefixének kezdő- és zárósorszámának kitöltése kötelező.");
                    } else if (hasRangeError) {
                        toasterService.warningToast("Figyelem", "Rosszul adta meg a szigorú számadású termék kezdő- és zárósorszámát. A két sorszám hosszának meg kell egyeznie! (pl:001-100)");
                    } else if (hasProductIdError) {
                        toasterService.warningToast("Figyelem", "Termék kiválasztása kötelező!");
                    }
                }
            });
        };

        //Termék neve mezőbe enter leütésre hozzáad egy sort
        $scope.isEnter = function (ev, index) {
            if (ev.which == 13) {
                $scope.addRow();
            }
        };
        $scope.addManuallyDiscountForProduct = function (event, index) {
            var productIsNotInTheList = true;
            angular.forEach(productsHasGotDiscountManually, function (product) {
                if (product.Name === $scope.products[index].Name) {
                    productIsNotInTheList = false;
                }
            });
            if (event.charCode >= 48 && event.charCode <= 57 && productIsNotInTheList) {
                productsHasGotDiscountManually.push($scope.products[index]);
            }
        };

        var allProductsHasGotName = true;
        var productsHasGotDiscountManually = [];

        $scope.testFunction = function () {
            if ($scope.voucherDiscountForProducts !== null) {
                for (var i = 0; i < $scope.products.length; i++) {
                    if ($scope.products[i].Name === undefined) {
                        allProductsHasGotName = false;
                        return false;
                    }
                }
                if (!allProductsHasGotName) {
                    toasterService.warningToast("Számlakedvezmény", "Nem adhat meg számlakedvezményt, amíg nincs kitöltve az összes termékekhez tartozó sor megfelelően!");
                    //browserModalService.alert('Nem adhat meg számlakedvezményt, amíg nincs kitöltve az összes termékekhez tartozó sor megfelelően!');
                    $scope.voucherDiscountForProducts = null;
                    allProductsHasGotName = true;
                } else {
                    for (var i = 0; i < $scope.products.length; i++) {
                        var needToChangeDiscountAmount = true;
                        for (var j = 0; j < productsHasGotDiscountManually.length; j++) {
                            if ($scope.products[i].Name === productsHasGotDiscountManually[j].Name) {
                                needToChangeDiscountAmount = false;
                                break;
                            }
                        }
                        if (needToChangeDiscountAmount) {
                            if ($scope.products[i].DiscountTypeName === 'Ft') {
                                $scope.products[i].DiscountTypeName = '%';
                                $scope.products[i].DiscountType = 0;
                            }
                            $scope.products[i].DiscountAmount = $scope.voucherDiscountForProducts;
                            $scope.changeRow(i);
                        }
                    }
                }
            }
            if ($scope.voucherDiscountForProducts === null) {
                for (var i = 0; i < $scope.products.length; i++) {
                    var needToChangeDiscountAmount = true;
                    for (var j = 0; j < productsHasGotDiscountManually.length; j++) {
                        if ($scope.products[i].Name === productsHasGotDiscountManually[j].Name) {
                            needToChangeDiscountAmount = false;
                            break;
                        }
                    }
                    if (needToChangeDiscountAmount) {
                        $scope.products[i].DiscountAmount = 0;
                        $scope.changeRow(i);
                    }
                }
            }
        };

        $scope.generateCloneBill = function () {
            browserModalService.confirm('Biztos, hogy klónozza a számlát?').then(function (r) {
                if (r) {
                    $scope.voucherData.VoucherNumber = null;
                    var i = 0, length = $scope.products.length;
                    for (i; i < length; i++) {
                        if ($scope.products[i].Identifier !== null) {
                            $scope.products[i].Identifier = null;
                        } else if ($scope.products[i].StartNumberOfRange !== null || $scope.products[i].EndNumberOfRange !== null || $scope.products[i].Prefix !== null) {
                            $scope.products[i].StartNumberOfRange = null;
                            $scope.products[i].EndNumberOfRange = null;
                            $scope.products[i].Prefix = null;
                        }
                    }

                    if ($scope.voucherData.VoucherStatus === 37 && $scope.voucherData.VoucherType === 46) {
                        localStorage.setItem('products', JSON.stringify($scope.products));
                        $scope.voucherData.CustomerName = $scope.actCompany.Name;
                        $scope.voucherData.CompanyId = $scope.actCompany.Id;
                        $scope.voucherData.LanguageTypeId = $scope.Language.Id;
                        $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
                        $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
                        localStorage.setItem('voucherData', JSON.stringify($scope.voucherData));
                        localStorage.setItem('costCenters', JSON.stringify($scope.costCenters));
                        window.location.href = siteurl.receipt;
                    }

                    $scope.newTweak = true;
                    $scope.isClone = true;
                    $scope.disabledElement = false;
                    removeDisableFromSelectPickers();
                }
            });
        };

        function removeDisableFromSelectPickers() {
            $("ul.selectpicker li.disabled").removeClass('disabled');
            $("#cashregister select").removeAttr('disabled');
            $("#cashregister button").removeClass('disabled');
            $("#paymentType select").removeAttr('disabled');
            $("#paymentType button").removeClass('disabled');
            $("#language select").removeAttr('disabled');
            $("#language button").removeClass('disabled');
            $("#currency select").removeAttr('disabled');
            $("#currency button").removeClass('disabled');
        }

        $scope.paperFormChanged = function () {
            if (!$scope.IsPaperFrom) {
                $scope.voucherData.VoucherNumber = "";
            }
        };

        //Befizetés hozzáadása számlához
        $scope.addPayment = function () {
            var debitAmountValue = Number($scope.sumRows.GrossValue).toFixed(0) - Number($scope.inPaymentValue).toFixed(0); //Aktuális tartozás értéke
            if ($scope.invoiceIsEqualized) {
                browserModalService.alert("A számla már kiegyenlítve!");
                return;
            } else if (debitAmountValue < 0) {
                browserModalService.alert("A tartozás értéke negatív!");
                return;
            } else {
                location.href = siteurl.addPaymentInvoice + "/" + tId + '?url=' + backUrl;
            }
        };

        function getCashDeskByVoucherTypeId(voucherType) {
            return (voucherType === 45 || voucherType === 46 || voucherType === 57)
                ? $scope.cashRegister.Id
                : null;
        }

        function getVoucherType(salesRadio) {
            if (!$scope.IsPaperFrom) { //Nem papír alapú
                switch (salesRadio) {
                    case 'Voucher':
                        return 45;
                        break;
                    case 'Bill':
                        return 46;
                        break;
                    case 'Trans':
                        return 47;
                        break;
                    case 'Order':
                        return 48;
                        break;
                    case 'Bid':
                        return 49;
                        break;
                }
            } else {
                switch (salesRadio) {
                    case 'Voucher':
                        return 45;
                        break;
                    case 'Bill':
                        return 57;
                        break;
                    case 'Trans':
                        return 58;
                        break;
                    case 'Order':
                        return 59;
                        break;
                    case 'Bid':
                        return 60;
                        break;
                }
            }
        }

        $scope.scheduleManufacturing = function () {
            manufacturingScheduleTweakService.scheduleManufacturing(tId)
                .success(function (response) {
                    toasterService.successToast('A gyártás sikeresen ütemezésre került');
                    $scope.setVoucherDataTasks(true);
                    $scope.getProbableEndingDateOfManufacturing();
                })
                .error(function (err) {
                    toasterService.errorFromServerToast(err);
                });
        };

        $scope.deleteScheduledManufacturing = function () {
            browserModalService.confirm('Biztosan törli a gyártási tervet?').then(function (r) {
                if (r) {
                    manufacturingScheduleTweakService.deleteScheduledManufacturing(tId)
                        .success(function (response) {
                            toasterService.successToast('A gyártás ütemezése sikeresen törlésre került');
                            $scope.setVoucherDataTasks(false);
                        })
                        .error(function (err) {
                            toasterService.errorFromServerToast(err);
                        });
                }
            });
        };

        $scope.istIdSet = function () {
            return !isNaN(tId) && tId > 0;
        };

        $scope.getManufacturingProcessesByProductId = function (productId) {
            if (productId && !$scope.allManufacturingProcessesByProducts[productId]) {
                manufacturingScheduleTweakService.getManufacturingProcessesByProductId(productId)
                    .success(function (response) {
                        $scope.allManufacturingProcessesByProducts[productId] = response;
                    })
                    .error(function (err) {
                        toasterService.errorFromServerToast(err);
                    });
            }
        };

        $scope.loadManufacturingProcesses = function (products) {
            var uniqueProductIds = [];

            for (var i = 0; i < products.length; i++) {
                if (uniqueProductIds.indexOf(products[i].ProductId)) {
                    uniqueProductIds.push(products[i].ProductId);
                }
            }

            for (var j = 0; j < uniqueProductIds.length; j++) {
                $scope.getManufacturingProcessesByProductId(uniqueProductIds[j]);
            }
        };

        $scope.getProbableEndingDateOfManufacturing = function () {
            if ($scope.voucherData && $scope.voucherData.ItHasTasks) {
                manufacturingScheduleTweakService.getProbableEndingDateOfManufacturing($scope.voucherData.VoucherId)
                    .success(function (response) {
                        $scope.probableEndingDateOfManufaturing = response;
                    })
                    .error(function (err) {
                        toasterService.errorFromServerToast(err);
                    });
            }
        };

        $scope.setVoucherDataTasks = function (value) {
            if ($scope.voucherData) {
                $scope.voucherData.ItHasTasks = value;
            }
        };

        $scope.probableEndingDateOfManufaturingIsAfterTheDeadline = function () {
            return $scope.probableEndingDateOfManufaturing &&
                $scope.voucherData && $scope.voucherData.UndertakeManufacturingDate &&
                moment($scope.probableEndingDateOfManufaturing).isAfter($scope.voucherData.UndertakeManufacturingDate, 'day');
        };
    }

})();