(function () {
    angular.module('IMasterApp')
        .controller('CashRegister_controller', CashRegister_controller)
        .controller('editCashRegister_controller', editCashRegister_controller);

    CashRegister_controller.$inject = ['$http', '$scope', 'siteurl', 'apiurl', 'toasterService', 'cashRegisterService'];
    function CashRegister_controller($http, $scope, siteurl, apiurl, toasterService, cashRegisterService) {

        $scope.CashRegisters = [];

        $scope.newCashRegister = function () {
            location.href = siteurl.editCashRegister + "/0";
        };

        $scope.editCashRegister = function (cashRegister) {
            location.href = siteurl.editCashRegister + "/" + cashRegister.Id;
        };

        $scope.deleteCashRegister = function (cashRegister, index) {
            if (confirm("Biztos benne, hogy törli a pénztárat?")) {
                cashRegisterService.deleteCashRegisterById(cashRegister.Id).success(function () {
                    toasterService.successToast("Törlés", "Sikeres törlés!");
                    $scope.CashRegisters.splice(index, 1);
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        function getAllCashRegister() {
            cashRegisterService.getAllCashRegister('').success(function (data) {
                $scope.CashRegisters = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        getAllCashRegister();
    }

    editCashRegister_controller.$inject = ['$http', '$scope', '$filter', 'siteurl', 'apiurl', 'toasterService', 'cashRegisterService'];
    function editCashRegister_controller($http, $scope, $filter, siteurl, apiurl, toasterService, cashRegisterService) {
        var cashRegisterId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        if (cashRegisterId != 0) {
            cashRegisterService.getCashRegisterById(cashRegisterId).success(function (data) {
                $scope.CashRegister = data;
                $scope.pageTitle = data.CashRegisterName + " pénztár módosítása";
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        } else {
            $scope.pageTitle = "Új pénztár létrehozása";
            $scope.CashRegister = {
                Id: '',
                CashRegisterName: '',
                CashRegisterCurrency: '',
                CashRegisterMaxLimit: '',
                CashRegisterAddress: '',
                CashRegisterComment: '',
                IsDeleted: ''
            }
        }

        $scope.save = function () {
            if (confirm("Biztos benne, hogy menti az adatokat?")) {
                if (cashRegisterId == 0) {
                    cashRegisterService.createCashRegister($scope.CashRegister).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    cashRegisterService.updateCashRegister($scope.CashRegister).success(function () {
                        toasterService.saveSuccess();
                        goBack();
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.back = function () {
            if (confirm("Biztos benne, hogy elveti a módosításokat és visszalép?")) {
                goBack();
            }
        };

        function goBack() {
            location.href = siteurl.cashRegister;
        };
    }

})();
