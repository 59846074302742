(function () {
    angular.module('IMasterApp')
        .controller('editInventory_controller', editInventory_controller);

    editInventory_controller.$inject = ['$scope', '$filter', '$window', 'siteurl', 'apiurl', '$http', 'toasterService', 'usSpinnerService'];
    function editInventory_controller($scope, $filter, $window, siteurl, apiurl, $http, toasterService, usSpinnerService) {
        //--InitStart
        $scope.isWarehouseAdded = false;
        $scope.modifiedItems = [];
        $scope.searchedList = [];
        $scope.emptyList = [];
        var inventoryId = parseInt(window.location.href.substring(window.location.href.lastIndexOf("/") + 1));
        $scope.isNewInventory = inventoryId === 0 ? true : false;
        $scope.title = "";
        $scope.warehouseLoaderIsVisible = false;
        var orderDirection = false;
        var tempProperty = "";
        var userName = $window.sessionStorage.getItem('username');
        //pager
        $scope.current = 1;
        $scope.totalItems = 0;
        $scope.pageSize = 25;
        $scope.search = {
            InventoryItemBarcode: "",
            InventoryItemName: "",
            InventoryItemItemNumber: ""
        };

        //láthatóságok
        $scope.showFullList = false;
        $scope.showQuantity = false;
        $scope.visibility = {
            showModified: false,
            showEmpty: false,
            spinnerActive: false
        };

        $scope.startSpin = function () {
            if (!$scope.visibility.spinnerActive) {
                usSpinnerService.spin('spinner-1');
                $scope.visibility.spinnerActive = true;
            }
        };

        $scope.stopSpin = function () {
            if ($scope.visibility.spinnerActive) {
                usSpinnerService.stop('spinner-1');
                $scope.visibility.spinnerActive = false;
            }
        };

        $scope.isFinalized = false;
        var checkFinalizeButtonvisibility = function () {
            var roles = $window.sessionStorage.getItem('roleids').split(',');
            var i = 0, length = roles.length, isItContain = false;
            for (i; i < length; i++) {
                if (roles[i] === '35') {
                    isItContain = true;
                    break;
                }
            }
            return isItContain && !$scope.isNewInventory && !$scope.isFinalized;
        }
        $scope.finalizeButtonVisibility = checkFinalizeButtonvisibility();

        $scope.inventory = {
            InventoryId: 0,
            InventoryWarehouse: {},
            InventoryStatus: {},
            InventoryItems: [],
            CreationOwner: ''
        };

        $scope.SumActualStockValue = 0;
        $scope.SumRealStockValue = 0;
        $scope.SumWasteStockValue = 0;

        var setInventory = function (data) {
            $scope.modifiedItems = [];
            $scope.searchedList = [];
            $scope.inventory.InventoryId = data.InventoryId;
            $scope.inventory.InventoryWarehouse = data.InventoryWarehouse;
            $scope.inventory.InventoryStatus = data.InventoryStatus;
            $scope.fullList = data.InventoryItems;
            angular.forEach($scope.fullList, function (item) {
                item.visibility = false;
                // Kereséshez szükséges átalakítani nullról üres stringre, mert a null-ra nem lehet toLowerCase-t hívni
                if (item.InventoryItemItemNumber === null) {
                    item.InventoryItemItemNumber = "";
                }
            });
            $scope.sortItems(tempProperty, "false");
            $scope.title = data.InventoryWarehouse.Name;
            inventoryId = data.InventoryId;
            $scope.isNewInventory = false;
            $scope.isFinalized = data.InventoryStatus.Id == 82;
            $scope.finalizeButtonVisibility = checkFinalizeButtonvisibility();
            $scope.stopSpin();
        };

        //Összegzősor
        var sumTotalStockValue = function (data) {
            var sumActualStockValue = 0;
            var sumRealStockValue = 0;
            var sumWasteStockValue = 0;

            angular.forEach(data, function (item) {
                sumActualStockValue += item.InventoryItemActualStockValue;
                sumRealStockValue += item.InventoryItemRealQuantity * item.InventoryItemStockPrice;
                sumWasteStockValue += item.InventoryItemWasteQuantity == '' || item.InventoryItemWasteQuantity == 0
                    ? 0
                    : item.InventoryItemRealStockValue - ((item.InventoryItemRealQuantity - item.InventoryItemWasteQuantity) * item.InventoryItemStockPrice);
            });

            //$scope.SumActualStockValue = Enumerable.From(data).Select(function (x) { return x.InventoryItemActualStockValue; }).Sum();
            //$scope.SumRealStockValue = Enumerable.From(data).Select(function (x) { return x.InventoryItemRealQuantity * x.InventoryItemStockPrice; }).Sum();
            //$scope.SumWasteStockValue = Enumerable.From(data).Select(function (x) { return x.InventoryItemWasteQuantity == '' || x.InventoryItemWasteQuantity == 0 ? 0 : x.InventoryItemRealStockValue - ((x.InventoryItemRealQuantity - x.InventoryItemWasteQuantity) * x.InventoryItemStockPrice); }).Sum();
        };

        var loadInventory = function () {
            if (inventoryId != 0) {
                $(document).ready(function () {
                    $scope.startSpin();
                });
                $http.get(apiurl.inventory.getInventory + inventoryId).success(function (data) {
                    setInventory(data);
                    sumTotalStockValue(data.InventoryItems);
                }).error(function (data) {
                    $scope.stopSpin();
                    toasterService.errorFromServerToast(data);
                });
            } else {
                $scope.stopSpin();
            }
        };

        loadInventory();
        //---InitEND 

        //--- Warehouse
        $scope.getWarehouse = function (warehouseName) {
            if (warehouseName.length < 3) {
                $scope.warehouseLoaderIsVisible = false;
                return [];
            }
            $scope.warehouseLoaderIsVisible = true;
            return $http.get(apiurl.inventory.getWarehouse + warehouseName).then(function (data) {
                $scope.warehouseLoaderIsVisible = false;
                return data.data;
            });
        };

        $scope.warehouseChanged = function () {
            $scope.isWarehouseAdded = false;
            $scope.inventory.InventoryItems = [];
            $scope.modifiedItems = [];
            $scope.title = "";
        };

        $scope.warehouseOnSelect = function (model) {
            $scope.inventory.InventoryWarehouse = model;
            $scope.isWarehouseAdded = true;
        };
        //--- END Warehouse

        $scope.addNewInventoryItem = function () {
            $scope.inventory.InventoryItems.unshift({
                InventoryItemName: "",
                InventoryItemBarcode: "",
                InventoryItemItemNumber: "",
                InventoryItemQuantityType: "",
                InventoryItemActualQuantity: 0,
                InventoryItemRealQauantity: null,
                InventoryItemWasteQuantity: null,
                InventoryItemStockPrice: 0,
                InventoryItemActualStockValue: 0,
                InventoryItemRealStockValue: 0,
                InventoryItemWasteStockValue: 0,
                isNewItem: true,
                visibility: false
            });
        };

        $scope.getInventoryItems = function (itemName, index) {
            if ($scope.inventory.InventoryItems[index].InventoryItemName.length < 3) {
                $scope.isVisibleOrNot = "followingBallsGHidden";
                $scope.inventory.InventoryItems[index].asyLoader = false;
                return [];
            }
            $scope.inventory.InventoryItems[index].asyLoader = true;
            $scope.isVisibleOrNot = "followingBallsG";
            return $http.get(apiurl.inventory.getProductsByName + $scope.inventory.InventoryWarehouse.Id + '/' + $scope.inventory.InventoryItems[index].InventoryItemName).then(function (data) {
                if (data.data.length > 200) {
                    toasterService.warningToast("Kereső figyelmeztetés", "A találatok száma túl sok, kérem pontosítson a keresési feltételen!");
                    $scope.isVisibleOrNot = "followingBallsGHidden";
                    $scope.inventory.InventoryItems[index].asyLoader = false;
                    return [];
                }

                $scope.isVisibleOrNot = "followingBallsGHidden";
                $scope.inventory.InventoryItems[index].asyLoader = false;
                return data.data;
            });
        };

        $scope.inventoryItemOnselect = function (model, index) {
            var hasInventory = false;
            angular.forEach($scope.fullList, function (item) {
                if (item.InventoryItemName == model.InventoryItemName) {
                    hasInventory = true;
                }
            });

            if (hasInventory) {
                $scope.inventory.InventoryItems[index].InventoryItemName = "";
                toasterService.warningToast("Hiba!", "Már szerepel a termék a leltáríven!");
            } else {
                model.isNewItem = true;
                model.isAdded = true;
                model.specialItem = model.InventoryItemProductTypeShortName == "EA" || model.InventoryItemProductTypeShortName == "SZIG";

                $scope.modifiedItems.push(model);
                $scope.fullList.unshift(model);
                $scope.inventory.InventoryItems[index] = model;
            }
        };

        $scope.deleteNewItem = function (index, model) {
            var name = angular.isDefined(model) ? model.InventoryItemName : "";
            if (confirm("Biztos hogy törli a(z) " + name + " terméket?")) {
                $scope.inventory.InventoryItems.splice(index, 1);
                $scope.fullList.splice($scope.fullList.indexOf(model), 1);
                $scope.modifiedItems.splice($scope.modifiedItems.indexOf(model), 1);
                sumTotalStockValue($scope.fullList);
            }
        };

        //-- Inventory Saves, and prints
        $scope.createInventory = function () {
            if (confirm("Biztos, hogy létrehozza a(z) " + $scope.inventory.InventoryWarehouse.Name + " raktár leltárját?")) {
                $scope.startSpin();
                $http.post(apiurl.inventory.createInventory + $scope.inventory.InventoryWarehouse.Id).success(function (data) {
                    siteurl.editInventory = siteurl.editInventory.slice(siteurl.editInventory[0], siteurl.editInventory.lastIndexOf("/"));
                    $scope.stopSpin();
                    window.location.href = siteurl.editInventory + '/' + data;
                }).error(function (data) {
                    $scope.stopSpin();
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        $scope.saveInventory = function () {
            if (confirm("Biztosan menti a változtatásokat?")) {
                $scope.startSpin();
                var inventory = angular.copy($scope.inventory);
                inventory.InventoryItems = $scope.modifiedItems;

                var errorMSg = checkInventoryItems();
                if (errorMSg.length > 0) {
                    $scope.stopSpin();
                    toasterService.warningToast("Hiba Történt", errorMSg);
                } else {
                    $http.post(apiurl.inventory.checkProductsQuantitiesAreModified, inventory).success(function () {
                        saveInventoryPost(inventory);

                    }).error(function (data) {
                        $scope.stopSpin();
                        if (angular.isDefined(data.Message)) {
                            if (confirm(data.Message + "\n Biztosan menti, ezzel felülírva az előző verziót?")) {
                                $scope.startSpin();
                                saveInventoryPost(inventory);
                            }
                        } else {
                            toasterService.errorToast("Hiba!", "Váratlan hiba történt");
                        }
                    });
                }
            }
        };

        $scope.finalizeInventory = function () {
            if (confirm("Biztos hogy véglegesíti a(z) " + $scope.inventory.InventoryWarehouse.Name + " raktár leltárját? \n Ezzel a művelettel felülírja a raktár készletét!")) {
                $scope.startSpin();
                var errorMSg = checkInventoryItems();
                if (errorMSg.length > 0) {
                    $scope.stopSpin();
                    toasterService.warningToast("Hiba Történt", errorMSg);
                } else {
                    var hasNullRealQuantityOrWasteQuantity = false;
                    angular.forEach($scope.fullList, function (item) {
                        if (item.InventoryItemRealQuantity == null || item.InventoryItemWasteQuantity == null) {
                            hasNullRealQuantityOrWasteQuantity = true;
                        }
                    });

                    if (hasNullRealQuantityOrWasteQuantity) {
                        $scope.stopSpin();
                        toasterService.warningToast("Hiba!", "Még nem töltött ki minden mezőt!");
                    } else {
                        var inventory = angular.copy($scope.inventory);
                        inventory.InventoryItems = $scope.modifiedItems;
                        $http.post(apiurl.inventory.checkProductsQuantitiesAreModified, inventory).success(function () {
                            inventory.CreationOwner = userName;
                            $http.post(apiurl.inventory.finalizeInventory, inventory).success(function () {
                                $scope.stopSpin();
                                toasterService.successToast("", "Sikeres lezárás!");
                                window.location = siteurl.inventory;
                            }).error(function (edata) {
                                $scope.stopSpin();
                                toasterService.errorFromServerToast(edata);
                            });
                        }).error(function (edata) {
                            $scope.stopSpin();
                            if (angular.isDefined(edata.Message)) {
                                if (confirm(edata.Message + "\n Biztosan menti, ezzel felülírva az előző verziót?")) {
                                    saveInventoryPost(inventory);
                                }
                            } else {
                                toasterService.errorToast("Hiba!", "Váratlan hiba történt");
                            }
                        });
                    }
                }
            }
        };

        $scope.showQuantitys = function () {
            $scope.showQuantity = !$scope.showQuantity;
        };

        $scope.printInventory = function () {
            $scope.loaderIsVisibleForPrint = true;
            var inventory = angular.copy($scope.inventory);

            if ($scope.searchedList.length == 0) {
                inventory.InventoryItems = $scope.fullList;
            }
            $http({
                method: 'POST',
                url: apiurl.inventory.printInventory + !$scope.showQuantity,
                data: inventory,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (dataPdf) {
                var blob = new Blob([dataPdf], { type: "application/pdf" });
                var actDate2 = $filter('date')(new Date(), 'yyyyMMdd.');
                navigator.saveBlob = navigator.saveBlob || navigator.msSaveOrOpenBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
                window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;
                if (window.saveAs) {
                    window.saveAs(blob, "leltariv_" + $scope.inventory.InventoryWarehouse.Name + actDate2 + ".pdf");
                } else if (navigator.saveBlob) {
                    navigator.saveBlob(blob, "leltariv_" + $scope.inventory.InventoryWarehouse.Name + actDate2 + ".pdf");
                } else {
                    var url = URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.setAttribute("href", url);
                    link.setAttribute("download", "leltariv" + $scope.inventory.InventoryWarehouse.Name + actDate2 + ".pdf");
                    var event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                }
            }).error(function () {
                $scope.loaderIsVisibleForPrint = false;
                toasterService.errorToast("Hiba!", "Az ön által megadott adatokból nem lehet leltárívet generálni!");
            }).then(function () {
                // Töltési animáció láthatósága
                $scope.loaderIsVisibleForPrint = false;
            });
        };

        var saveInventoryPost = function (inventory) {
            $http.post(apiurl.inventory.saveInventory, inventory).success(function () {
                toasterService.successToast("", "Sikeres mentés");
                loadInventory();
            }).error(function (data) {
                $scope.stopSpin();
                toasterService.errorFromServerToast(data);
            });
        };
        //-- END Inventory Saves, and prints

        $scope.pageChanged = function () {

            var inventoryItemList = [];
            var from = ($scope.current - 1) * $scope.pageSize;
            var to = (($scope.current - 1) * $scope.pageSize) + $scope.pageSize;
            var i = 0;

            if (!$scope.visibility.showModified && !$scope.visibility.showEmpty && $scope.search.InventoryItemBarcode === "" && $scope.search.InventoryItemName === "" && $scope.search.InventoryItemItemNumber === "") {
                for (i = from; i < to; i++) {
                    if ($scope.fullList[i]) {
                        inventoryItemList.push($scope.fullList[i]);
                    }
                }

                $scope.inventory.InventoryItems = inventoryItemList;
                $scope.totalItems = $scope.fullList.length;
                $scope.searchedList = [];
            } else if ($scope.visibility.showModified) {
                for (i = from; i < to; i++) {
                    if ($scope.modifiedItems[i]) {
                        inventoryItemList.push($scope.modifiedItems[i]);
                    }
                }

                $scope.inventory.InventoryItems = inventoryItemList;
                $scope.totalItems = $scope.modifiedItems.length;
            } else if ($scope.visibility.showEmpty) {
                for (i = from; i < to; i++) {
                    if ($scope.emptyList[i]) {
                        inventoryItemList.push($scope.emptyList[i]);
                    }
                }

                $scope.inventory.InventoryItems = inventoryItemList;
                $scope.totalItems = $scope.emptyList.length;
            } else {
                for (i = from; i < to; i++) {
                    if ($scope.searchedList[i]) {
                        inventoryItemList.push($scope.searchedList[i]);
                    }
                }

                $scope.inventory.InventoryItems = inventoryItemList;
                $scope.totalItems = $scope.searchedList.length;
            }
        };

        //--Items
        $scope.inventoryItemRealQuantityChanged = function (index, model) {
            var sumRealStockValue = 0;
            angular.forEach($scope.fullList, function (item) {
                sumRealStockValue += item.InventoryItemRealQuantity * item.InventoryItemStockPrice;
            });

            if (model.InventoryItemProductTypeShortName == "SZIG" || model.InventoryItemProductTypeShortName == "EA") {
                if (model.InventoryItemRealQauantity != '' && model.InventoryItemRealQuantity > 500) {
                    toasterService.warningToast("Hiba!", "Egyedi azonosítójú terméknél a tényleges mennyiség nem lehet nagyobb 500-nál!");
                    model.InventoryItemRealQuantity = 500;
                }
            }
            if (model.InventoryItemWasteQuantity != '' && model.InventoryItemWasteQuantity != 0) {
                var itemRealQuantity = model.InventoryItemRealQuantity == '' ? 0 : model.InventoryItemRealQuantity;
                if (parseInt(model.InventoryItemWasteQuantity) > parseInt(itemRealQuantity)) {
                    toasterService.warningToast("Hiba!", "A selejt szám nem lehet nagyobb a talált mennyiségnél!");
                    model.InventoryItemRealQuantity = model.InventoryItemWasteQuantity;
                    model.InventoryItemRealStockValue = model.InventoryItemRealQuantity * model.InventoryItemStockPrice;
                    changeInventoryItemInLists(model);



                    $scope.SumRealStockValue = sumRealStockValue;
                    return;
                }
            }

            $scope.addIdentityRows(model);

            model.InventoryItemRealStockValue = model.InventoryItemRealQuantity * model.InventoryItemStockPrice;
            $scope.SumRealStockValue = sumRealStockValue;
            changeInventoryItemInLists(model);
        };

        $scope.inventoryItemWasteQuantityChanged = function (index, model) {

            var sumWasteStockValue = 0;

            angular.forEach($scope.fullList, function (item) {
                sumWasteStockValue += item.InventoryItemWasteQuantity == '' || item.InventoryItemWasteQuantity == 0
                    ? 0
                    : item.InventoryItemRealStockValue - ((item.InventoryItemRealQuantity - item.InventoryItemWasteQuantity) * item.InventoryItemStockPrice);
            });

            model.InventoryItemRealQuantity = model.InventoryItemRealQuantity == null || model.InventoryItemRealQuantity == '' ? 0 : model.InventoryItemRealQuantity;
            if (parseInt(model.InventoryItemWasteQuantity) > parseInt(model.InventoryItemRealQuantity)) {
                toasterService.warningToast("Hiba!", "A selejt szám nem lehet nagyobb a talált mennyiségnél!");
                model.InventoryItemWasteQuantity = '';
                model.InventoryItemWasteStockValue = model.InventoryItemWasteQuantity == '' || model.InventoryItemWasteQuantity == 0 ? 0 : model.InventoryItemRealStockValue - ((model.InventoryItemRealQuantity - model.InventoryItemWasteQuantity) * model.InventoryItemStockPrice);
                changeInventoryItemInLists(model);
                $scope.SumWasteStockValue = sumWasteStockValue;
                $scope.addWasteIdentityRow(model);
                return;
            }
            model.numberOfStockItems = model.InventoryItemRealQuantity = model.InventoryItemRealQuantity == '' ? 0 : model.InventoryItemRealQuantity;
            model.InventoryItemWasteStockValue = model.InventoryItemWasteQuantity == '' || model.InventoryItemWasteQuantity == 0 ? 0 : model.InventoryItemRealStockValue - ((model.InventoryItemRealQuantity - model.InventoryItemWasteQuantity) * model.InventoryItemStockPrice);
            $scope.SumWasteStockValue = sumWasteStockValue;
            changeInventoryItemInLists(model);
            $scope.addWasteIdentityRows(model);
        };

        //--Identitys
        $scope.addIdentityRows = function (model) {
            if (model.InventoryItemProductTypeShortName === "SZIG" || model.InventoryItemProductTypeShortName === "EA") {
                if (angular.isUndefined(model.InventoryItemUniqueIdentifier) || model.InventoryItemUniqueIdentifier == null) {
                    model.InventoryItemUniqueIdentifier = [];
                }
                var numberOfRows = model.InventoryItemRealQuantity < 500 ? model.InventoryItemRealQuantity : 500;

                if (model.InventoryItemUniqueIdentifier.length > numberOfRows) {
                    numberOfRows = model.InventoryItemUniqueIdentifier.length;
                }
                for (var i = 0; i < numberOfRows; i++) {
                    model.InventoryItemUniqueIdentifier[i] = angular.isDefined(model.InventoryItemUniqueIdentifier[i]) ? model.InventoryItemUniqueIdentifier[i] : "";
                    if (model.InventoryItemRealQuantity == 0 && (model.InventoryItemUniqueIdentifier[i] == "" || !angular.isDefined(model.InventoryItemUniqueIdentifier[i]))) {
                        model.InventoryItemUniqueIdentifier.splice(i, 1);
                        i--;
                        numberOfRows--;
                    }
                    model.visibility = true;
                }
            }
        };

        $scope.addWasteIdentityRows = function (model) {
            if (model.InventoryItemProductTypeShortName === "SZIG" || model.InventoryItemProductTypeShortName === "EA") {
                if (angular.isUndefined(model.InventoryItemWasteUniqueIdentifier) || model.InventoryItemWasteUniqueIdentifier == null) {
                    model.InventoryItemWasteUniqueIdentifier = [];
                }

                var numberOfRows = model.InventoryItemWasteQuantity < 500 ? model.InventoryItemWasteQuantity : 500;

                if (angular.isDefined(model.InventoryItemWasteUniqueIdentifier)) {
                    if (model.InventoryItemWasteQuantity == 0 || model.inventoryItemWasteQuantity == "") {
                        numberOfRows = model.InventoryItemWasteUniqueIdentifier.length;
                        for (var k = 0; k < numberOfRows; k++) {
                            if (model.InventoryItemWasteUniqueIdentifier[k] == "" || !angular.isDefined(model.InventoryItemWasteUniqueIdentifier[k])) {
                                model.InventoryItemWasteUniqueIdentifier.splice(k, 1);
                                k--;
                                numberOfRows--;
                            }
                        }
                    } else {
                        for (var j = model.InventoryItemWasteUniqueIdentifier.length ; j < numberOfRows; j++) {
                            model.InventoryItemWasteUniqueIdentifier.push("");
                        }
                    }
                } else {
                    model.InventoryItemWasteUniqueIdentifier = [];
                    for (var i = 0; i < numberOfRows; i++) {
                        model.InventoryItemWasteUniqueIdentifier.push("");
                    }
                }
            }
        };

        var changeInventoryItemInLists = function (model) {
            var hasInventory = false;
            angular.forEach($scope.modifiedItems, function (item) {
                if (item.InventoryItemId == model.InventoryItemId) {
                    hasInventory = true;
                }
            });

            if (!hasInventory) {
                $scope.modifiedItems.push(model);
            }
        };
        //--End identitys

        $scope.deleteIdentityRow = function (item, index, isWaste) {
            isWaste ? item.InventoryItemWasteUniqueIdentifier.splice(index, 1) : item.InventoryItemUniqueIdentifier.splice(index, 1);
            changeInventoryItemInLists(item);

        };
        //--Items end

        $scope.searchChanged = function () {
            // Ha az eddigi változások be van pipálva, akkor csak abban a listában szűrünk egyébként a teljesben
            var arrayName = $scope.visibility.showModified ? "modifiedItems" : "fullList";
            if ($scope.visibility.showEmpty) {
                arrayName = "emptyList";
            }
            $scope.searchedList = [];

            // Leszűrjük a listát az adott feltételeknek megfelelően
            // Ha a termék neve keresőmező nem üres akkor aszerint leszűrjük
            if ($scope.search.InventoryItemName !== "") {
                // Kisbetűsre alakítjuk a kapott stringet
                var searchArray = $scope.search.InventoryItemName.split(" ");
                angular.forEach($scope[arrayName], function (item) {
                    // Kisbetűsre alakítjuk az adott elem nevét is
                    var lowerCaseItemName = item.InventoryItemName.toLowerCase();
                    // Ha megtalálható az elemben a megadott szöveg akkor hozzáadjuk a listához
                    var count = 0;
                    for (var i = 0; i < searchArray.length; i++) {
                        var lowerCaseSearchItemName = searchArray[i].toLowerCase();
                        if (lowerCaseItemName.indexOf(lowerCaseSearchItemName) !== -1) {
                            count++;
                        }
                    }
                    if (count == searchArray.length) {
                        $scope.searchedList.push(item);
                    }
                });
            }

            // Ha vonalkódra keresünk és a termék nevére nem
            if ($scope.search.InventoryItemBarcode !== "" && $scope.searchedList.length === 0) {
                // Kisbetűsre alakítjuk a kapott stringet
                var lowerCaseSearchItemBarcode = $scope.search.InventoryItemBarcode.toLowerCase();

                angular.forEach($scope[arrayName], function (item) {
                    // Kisbetűsre alakítjuk az adott elem vonalkódját is
                    var lowerCaseItemBarcode = item.InventoryItemBarcode.toLowerCase();
                    // Ha megtalálható az elemben a megadott szöveg akkor hozzáadjuk a listához
                    if (lowerCaseItemBarcode.indexOf(lowerCaseSearchItemBarcode) !== -1) {
                        $scope.searchedList.push(item);
                    }
                });
            }

            // Ha vonalkódra és a termék nevére is kerestünk
            if ($scope.search.InventoryItemBarcode !== "" && $scope.searchedList.length > 0) {

                // A már előzőleg megszűrt listából az adott feltételeknek megfelelő elemek listája
                var tempList = [];
                // Kisbetűsre alakítjuk a kapott stringet
                var lowerCaseSearchItemBarcode2 = $scope.search.InventoryItemBarcode.toLowerCase();

                angular.forEach($scope.searchedList, function (searchedItem) {
                    // Kisbetűsre alakítjuk az adott elem vonalkódját is
                    var lowerCaseItemBarcode = searchedItem.InventoryItemBarcode.toLowerCase();
                    // Ha megtalálható az elemben a megadott szöveg akkor hozzáadjuk a listához
                    if (lowerCaseItemBarcode.indexOf(lowerCaseSearchItemBarcode2) !== -1) {
                        tempList.push(searchedItem);
                    }
                });
                // Végül visszaírjuk a keresett listába
                $scope.searchedList = angular.copy(tempList);
            }

            // Ha nem kerestünk csak a cikkszámra
            if ($scope.search.InventoryItemItemNumber !== "" && $scope.searchedList.length === 0) {
                // Kisbetűsre alakítjuk a kapott stringet
                var lowerCaseSearchItemNumber = $scope.search.InventoryItemItemNumber.toLowerCase();
                angular.forEach($scope[arrayName], function (item) {
                    // Kisbetűsre alakítjuk az adott elem vonalkódját is
                    var lowerCaseItemNumber = item.InventoryItemItemNumber.toLowerCase();
                    // Ha megtalálható az elemben a megadott szöveg akkor hozzáadjuk a listához
                    if (lowerCaseItemNumber.indexOf(lowerCaseSearchItemNumber) !== -1) {
                        $scope.searchedList.push(item);
                    }
                });
            }

            // Ha kerestünk a cikkszámra és mellette még vagy a termék nevére vagy a vonalkódra
            if ($scope.search.InventoryItemItemNumber !== "" && $scope.searchedList.length > 0) {

                // A már előzőleg megszűrt listából az adott feltételeknek megfelelő elemek listája
                var tempList2 = [];
                // Kisbetűsre alakítjuk a kapott stringet
                var lowerCaseSearchItemNumber2 = $scope.search.InventoryItemItemNumber.toLowerCase();

                angular.forEach($scope.searchedList, function (searchedItem) {
                    // Kisbetűsre alakítjuk az adott elem cikkszámát is
                    var lowerCaseItemNumber = searchedItem.InventoryItemItemNumber.toLowerCase();
                    // Ha megtalálható az elemben a megadott szöveg akkor hozzáadjuk a listához
                    if (lowerCaseItemNumber.indexOf(lowerCaseSearchItemNumber2) !== -1) {
                        tempList2.push(searchedItem);
                    }
                });
                // Végül visszaírjuk a keresett listába
                $scope.searchedList = angular.copy(tempList2);
            }

            // Visszaállunk az első oldalra
            $scope.current = 1;
            // Majd megjelenítjük a szűrt elemeket
            $scope.sortItems(tempProperty, "false");
        };

        // Fejléc rendezés
        $scope.sortItems = function (property, isFromSurface) {
            // Ha felületről kattintottunk a fejlécre, akkor megnézzük, hogy ugyanaz-e amire már egyszer volt kattintva, akkor csak az irányt változtatjuk
            // egyébként pedig növekvőbe rendezzük az új fejléc szerint
            if (isFromSurface === "true") {
                if (property === tempProperty) {
                    orderDirection = !orderDirection;
                } else {
                    orderDirection = true;
                    tempProperty = property;
                }
            }

            // A rendezni kívánt tömb neve, alapértelmezetten a teljes lista
            var arrayName = "fullList";

            // Ha az eddigi változtatások be van pipálva, akkor azt a listát rendezzük
            if ($scope.visibility.showModified && $scope.search.InventoryItemBarcode === "" && $scope.search.InventoryItemName === "" && $scope.search.InventoryItemItemNumber === "") {
                arrayName = "modifiedItems";
            } else if ($scope.visibility.showEmpty && $scope.search.InventoryItemBarcode === "" && $scope.search.InventoryItemName === "" && $scope.search.InventoryItemItemNumber === "") {
                arrayName = "emptyList";
            }// Ha van szűrve akkor a szűrt listát rendezzük
            else if ($scope.search.InventoryItemBarcode !== "" || $scope.search.InventoryItemName !== "" || $scope.search.InventoryItemItemNumber !== "") {
                arrayName = "searchedList";
            }

            // Csak akkor kell rendezni a listát, ha már egyszer volt valamire kattintva
            if (tempProperty !== "") {
                $scope[arrayName].sort(function (a, b) {
                    return orderDirection === true ? a[tempProperty] > b[tempProperty] ? 1 : -1 : a[tempProperty] < b[tempProperty] ? 1 : -1;
                });
            }

            var inventoryItemList = [];
            var from = ($scope.current - 1) * $scope.pageSize;
            var to = (($scope.current - 1) * $scope.pageSize) + $scope.pageSize;
            var i = 0;

            for (i = from; i < to; i++) {
                if ($scope[arrayName][i]) {
                    inventoryItemList.push($scope[arrayName][i]);
                };
            }

            $scope.inventory.InventoryItems = inventoryItemList;
            $scope.totalItems = $scope[arrayName].length;
        };

        var checkIdentityNumber = function (model) {
            var numberOfItems = model.InventoryItemRealQuantity + model.InventoryItemWasteQuantity;
            return numberOfItems == model.InventoryItemUniqueIdentifier.length + model.InventoryItemWasteUniqueIdentifier.length;
        };

        var checkInventoryItems = function () {
            var error = "";
            for (var i = 0; i < $scope.inventory.InventoryItems.length; i++) {
                if ($scope.inventory.InventoryItems[i].InventoryItemProductTypeShortName === "EA" && !($scope.inventory.InventoryItems[i].InventoryItemRealQuantity == "" || $scope.inventory.InventoryItems[i].InventoryItemRealQuantity == null)) {
                    var hasEmptyUniqueIdentifier = false;
                    angular.forEach($scope.inventory.InventoryItems[i].InventoryItemUniqueIdentifier, function (item) {
                        if (item == "") {
                            hasEmptyUniqueIdentifier = true;
                        }
                    });


                    if (hasEmptyUniqueIdentifier) {
                        error = $scope.inventory.InventoryItems[i].InventoryItemName + " terméknél nem töltött ki minden egyedi azonosító mezőt!";
                        break;
                    }
                    var isQuantityValid = checkIdentityNumber($scope.inventory.InventoryItems[i]);
                    if (!isQuantityValid) {

                        error += error.length > 0 ? "\n " + $scope.inventory.InventoryItems[i].InventoryItemName : "Az alábbi egyedi azonosítós terméknél a mennyiségek és egyedi azonosítók száma nem áll összefüggésben: " + $scope.inventory.InventoryItems[i].InventoryItemName;
                    }
                }
            };
            return error;
        };

        $scope.checkIdentityRowChanged = function (item, identifier, isWaste, index) {
            if (identifier != "") {
                //var arrayName = isWaste ? "InventoryItemWasteUniqueIdentifier" : "InventoryItemUniqueIdentifier";

                var countSameUniqueIdentifier = 0;
                angular.forEach(item.InventoryItemUniqueIdentifier, function (identifierItem) {
                    if (identifierItem == identifier) {
                        countSameUniqueIdentifier++;
                    }
                });

                //var haveSameIdentifier = Enumerable.From(item.InventoryItemUniqueIdentifier).Where(function (x) { return x == identifier; }).Count();
                //var haveSameWateIdentifier = Enumerable.From(model.InventoryItemWasteUniqueIdentifier).Where(function (x) { return x == identifier; }).Count()

                if (countSameUniqueIdentifier > 1) {
                    toasterService.warningToast("Hiba!", "Az alábbi : " + identifier + " azonosító már szerepel a listában!");
                    item.InventoryItemUniqueIdentifier[index] = "";
                }
                changeInventoryItemInLists(item);
            }
        };

        //---ClickEvents
        $scope.backButtonClick = function () {
            if ($scope.modifiedItems.length == 0) {
                if (confirm("Biztosan visszalép?")) {
                    window.location = siteurl.inventory;
                }
            } else {
                if (confirm("Nem mentett minden adatot, biztosan visszalép?")) {
                    window.location = siteurl.inventory;
                }
            }
        };

        $scope.visibilityClick = function (item) {
            item.visibility = !item.visibility;
        };

        $scope.showModifiedItems = function () {
            $scope.visibility.showEmpty = false;
            $scope.current = 1;
            $scope.sortItems(tempProperty, "false");
        };

        $scope.showEmptyItems = function () {
            $scope.visibility.showModified = false;
            $scope.emptyList = [];
            angular.forEach($scope.fullList, function (item) {
                if (item.InventoryItemRealQuantity == null || item.InventoryItemWasteQuantity == null) {
                    $scope.emptyList.push(item);
                }
            });
            $scope.searchChanged();
        };
        //-- ENd click events

    }

})();
