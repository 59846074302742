(function () {

    angular.module('IMasterApp')
        .controller('moveToWarehouseCtrl', moveToWarehouseCtrl);

    moveToWarehouseCtrl.$inject = ['$scope', '$http', '$filter', 'siteurl', 'apiurl', 'toasterService'];
    function moveToWarehouseCtrl($scope, $http, $filter, siteurl, apiurl, toasterService) {
        var warehouseId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        $scope.products = [];
        $scope.productTypes = productTypes;
        $scope.receivingWarehouse = null;
        $scope.visibility = false;



        $scope.getProducts = function (productName) {
            if (productName.length < 3) {
                return [];
            }
            var params = { params: { productName: productName } };
            return $http.get(apiurl.interWarehouse.getProductsOfWarehouse + warehouseId, params)
            .then(function (data) {
                return data.data;
            });
        };

        $scope.emptyReceivingWarehouse = true;
        $scope.$watch("receivingWarehouse", function () {
            if (($scope.receivingWarehouse != null && $scope.receivingWarehouse != "") && ($scope.receivingWarehouse.Name != $scope.senderWarehouse.Name) && $scope.receivingWarehouse.Id != null) {
                $scope.emptyReceivingWarehouse = false;
            }
            else {
                $scope.emptyReceivingWarehouse = true;
            }
        });


        $scope.takeOutStockMovement = function () {

            var products = $scope.products;
            var data = { Items: products, SenderWarehouse: { Id: $scope.senderWarehouse.Id, Name: '' }, ReceiverWarehouse: { Id: $scope.receivingWarehouse.Id, Name: '' }, Transporter: $scope.Transporter };

            var hasStrictError = false;
            var hasRangeError = false;
            var hasProductIdError = false;
            var hasIdentifierError = false;


            for (var i = 0; i < products.length; i++) {
                if (data.Items[i].ProductId == '') {
                    hasProductIdError = true;
                }

                if (data.Items[i].Type.Id === 28) {
                    if (data.Items[i].Prefix == '' || data.Items[i].StartNumberOfRange == null || data.Items[i].EndNumberOfRange == null) {
                        hasStrictError = true;
                    }
                    if (!hasStrictError) {
                        if (data.Items[i].StartNumberOfRange > data.Items[i].EndNumberOfRange || data.Items[i].StartNumberOfRange.length != data.Items[i].EndNumberOfRange.length) {
                            hasRangeError = true;
                        }
                    }
                }

                if (data.Items[i].Type.Id === 39) {
                    if (data.Items[i].Identifier == '' || data.Items[i].Identifier === null) {
                        hasIdentifierError = true;
                    }
                }
            }

            if ($scope.products.length > 0 && !hasStrictError && !hasRangeError && !hasProductIdError && !hasIdentifierError) {
                if (!confirm('Valóban menteni akarja?')) {
                    return;
                }
                $http.post(apiurl.interWarehouse.takeOutStockMovement, data)
                .success(function () {
                    toasterService.saveSuccess();
                    window.location.href = siteurl.stock;
                }).error(function (data, status, xhrm) {
                    toasterService.errorFromServerToast(data);
                });
            }
            else if ($scope.products.length == 0) {
                toasterService.warningToast("Figyelem", "Nem adott meg terméket.");
            } else if (hasStrictError) {
                toasterService.warningToast("Figyelem", "A szigorú számadású termékek prefixének kezdő- és zárósorszámának kitöltése kötelező.");
            } else if (hasRangeError) {
                toasterService.warningToast("Figyelem", "Rosszul adta meg a szigorú számadású termék kezdő- és zárósorszámát. A két sorszám hosszának meg kell egyeznie! (pl:001-100)");
            } else if (hasProductIdError) {
                toasterService.warningToast("Figyelem", "Termék kiválasztása kötelező!");
            } else if (hasIdentifierError) {
                toasterService.warningToast("Figyelem", "Az egyedi azonosító megadása kötelező.");
            }
        };

        $scope.addRow = function () {
            $scope.product = {
                Type: $scope.productTypes[0],
                Name: '',
                Quantity: '',
                QuantityType: '',
                NetPrice: '',
                Vat: 27,
                Prefix: '',
                ProductId: '',
                StartNumberOfRange: null,
                EndNumberOfRange: null,
                Identifier: null,
                TaxRateName: null
            };
            $scope.products.push($scope.product);
        };

        $scope.deleteRow = function (index) {
            $scope.products.splice(index, 1);
        };


        $scope.taOnSelect = function ($model, $index) {
            console.log($model);
            console.log($index);

            angular.forEach($scope.productTypes, function (pt) {
                if ($model.TypeId == pt.Id) {
                    $scope.products[$index].Type = pt;
                    $scope.products[$index].TypeId = pt.Id;
                }
            });

            $scope.products[$index].Name = $model.Name;
            $scope.products[$index].ProductId = $model.ProductId;
            $scope.products[$index].NetPrice = $model.NetPrice;
            $scope.products[$index].Vat = $model.Vat;
            $scope.products[$index].QuantityTypeId = $model.QuantityTypeId;
            $scope.products[$index].QuantityType = $model.QuantityType;
            $scope.products[$index].TaxRateName = $model.TaxRateName;

            // A kiválasztott termékhez tartozó mennyiségi egységek lekérése
            $http.get(apiurl.interWarehouse.getQuantityTypesByProductId + $model.ProductId).success(function (data) {
                $scope.products[$index].QuantityTypes = data;

                // Ellenőrizzük, hogy a termék alapértelmezett mennyiségi egysége szerepel-e
                // a termék mennyiségi egységeinek listáján
                var defaults = $filter('filter')(data, { Id: $model.QuantityTypeId });

                // Ha nem szerepel, akkor...
                if (!defaults.length) {
                    // kiválasztjuk az első elemet a választhatóak listájáról
                    if (data.length) {
                        $scope.products[$index].QuantityTypeId = data[0].Id;
                        $scope.products[$index].QuantityType = data[0].Name;
                    }

                    // Ha a válaszható elemek listája üres, de van a terméknek
                    // alapértelmezett mennyiségi egysége, akkor jobb híján azt állítjuk be a comboboxban
                    if (!data.length && $model.QuantityTypeId && $model.QuantityType.length) {
                        var quantity = { Id: $model.QuantityTypeId, Name: $model.QuantityType };
                        $scope.products[$index].QuantityTypes.push(quantity);
                    }
                }
            });
        };

        $scope.quantityTypeOnSelect = function ($index, product) {
            if (product) {
                var selected = $filter('filter')(product.QuantityTypes, { Id: product.QuantityTypeId });

                if (selected.length === 1) {
                    $scope.products[$index].QuantityTypeId = selected[0].Id;
                    $scope.products[$index].QuantityType = selected[0].Name;
                }
            }
        };

        $http.get(apiurl.interWarehouse.getWarehouses).success(function (data) {
            $scope.warehouseList = data;
            angular.forEach(data, function (item) {
                if (item.Id.toString() === warehouseId)
                    $scope.senderWarehouse = item;
            });

        });

        //Saját cég kiválasztása
        $scope.getOwnerCompany = function () {
            $http.get(apiurl.interWarehouse.getOwnerCompany + $scope.receivingWarehouse.Id).success(function (data) {
                $scope.receivingWarehouse.companyLongName = data.LongName;
            });
        }

        $scope.back = function () {
            location.href = siteurl.stock;
        };

    }

})();
