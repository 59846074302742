(function () {

    angular.module('IMasterApp')
        .controller('stock_receipt_controller', stock_receipt_controller);

    stock_receipt_controller.$inject = ['$scope', '$uibModal', '$http', '$filter', 'PriceCounter', 'siteurl', 'apiurl', '$window', 'toasterService', 'CheckCostCenters', 'CheckVoucherItems', 'userService'];
    function stock_receipt_controller($scope, $uibModal, $http, $filter, PriceCounter, siteurl, apiurl, $window, toasterService, CheckCostCenters, CheckVoucherItems, userService) {
        //Datepickers 
        $scope.format = "yyyy.MM.dd";

        //Receipt datepicker
        $scope.isValidateOff = false;
        $scope.Receipttoday = function () {
            $scope.ReceiptDatePicker = new Date();
            $http.get(apiurl.stock.getIncomingVoucherSetting).success(function(data) {
                $scope.isValidateOff = !data;
            });
        };

        $scope.Receipttoday();

        $scope.clear = function () {
            $scope.ReceiptDatePicker = null;
        };
        $scope.openReceiptDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedReceiptDp = true;
        };
        $scope.ReceiptDateOptions = {
            startingDay: 1
        };

        //Date of Issue
        $scope.Issuetoday = function () {
            $scope.IssueDatePicker = new Date();
        };
        $scope.Issuetoday();

        $scope.clear = function () {
            $scope.IssueDatePicker = null;
        };
        $scope.openIssueDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedIssueDp = true;
        };
        $scope.openedIssueDp = false;
        $scope.IssueDateOptions = {
            startingDay: 1
        };

        //Date of Fulfillment
        $scope.Fulfillmenttoday = function () {
            $scope.FulfillmentDatePicker = new Date();
        };
        $scope.Fulfillmenttoday();

        $scope.clear = function () {
            $scope.FulfillmentDatePicker = null;
        };
        $scope.openFulfillmentDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedFulfillmentDp = true;
        };
        $scope.FulfillmentDateOptions = {
            startingDay: 1
        };


        //payment deadline
        $scope.Deadlinetoday = function () {
            $scope.DeadlineDatePicker = new Date();
        };
        $scope.Deadlinetoday();

        $scope.clear = function () {
            $scope.DeadlineDatePicker = null;
        };
        $scope.openDeadlineDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedDeadlineDp = true;
        };
        $scope.DeadlineDateOptions = {
            startingDay: 1
        };


        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate = new Date();
            $scope.prdate = $filter('date')($scope.prdate, 'yyyy.MM.dd');
        };
        $scope.today();
        $scope.clear = function () {
            $scope.prdate = null;
        };

        $scope.dtopen = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.dtopened = true;
        };
        $scope.prdateformat = "yyyy.MM.dd";
        $scope.dtoptions = {
            startingDay: 1
        };


        $scope.dtopen2 = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.dtopened2 = true;
        };

        function getOwnerCompanyData() {
            $http.get(apiurl.stock.getOwnerCompanyDatas).success(function (data) {
                if (data !== null) {
                    $scope.OwnerCompanyBaseDatas = {
                        LongName: data.LongName,
                        HomeAddress: data.HomeAddress,
                        TaxNumber: data.TaxNumber,
                        EuTaxNumber: data.EuTaxNumber
                    }
                    $scope.OwnerCompanyBankAccounts = data.OwnerCompanyBankAccounts;
                    $scope.OwnerCompanySitesAddresses = data.OwnerCompanySitesAddresses;
                    $scope.voucherData.CustomerName = $scope.OwnerCompanyBaseDatas.LongName;
                    $scope.voucherData.CustomerAddress = $scope.OwnerCompanyBaseDatas.HomeAddress;
                    $scope.voucherData.CustomerTaxNumber = $scope.OwnerCompanyBaseDatas.TaxNumber;
                    $scope.voucherData.CustomerEUTaxNumber = $scope.OwnerCompanyBaseDatas.EuTaxNumber;
                    $scope.voucherData.CustomerBankAccount = $scope.OwnerCompanyBankAccounts[0].BankAccount;
                    $scope.voucherData.CustomerDeliveryAddress = $scope.OwnerCompanySitesAddresses[0].Site;
                }
            });
        }

        //Regisztrált felhasználók letöltése
        $scope.actualUser = "";
        $scope.Users = [];
        $http.get(apiurl.user.getAllUser).success(function (data) {
            for (var i = 0; i < data.length; i++) {
                $scope.Users.push({
                    Id: data[i].UserId,
                    Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                    UserName: data[i].Username
                });

                //új bevételezés
                if (sendNoteList == null || sendNoteList.length <= 2) {
                    if (data[i].UserId == userId) {
                        $scope.actualUser = {
                            Name: data[i].UserFirstname + ' ' + data[i].UserLastname,
                            UserName: data[i].Username,
                            Id: data[i].UserId
                        }
                    }
                }
            }
            //ilyen esetben adminnal vagyok belépve
            if (angular.isUndefined($scope.actualUser) || $scope.actualUser == "") {
                userService.getUserById(userId).success(function (data2) {
                    var adminUser = {
                        Name: data2.UserFirstname + ' ' + data2.UserLastname,
                        UserName: data2.Username,
                        Id: data2.UserId
                    }
                    //új bevételezés
                    if (sendNoteList == null || sendNoteList.length <= 2) {
                        $scope.actualUser = adminUser;
                    }
                    $scope.Users.push(adminUser); //ez a felhasználó az admin, szóval nem kell rá ellenőrzés, hogy a lista tartalmazza-e
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }).error(function (data) {
            toasterService.errorFromServerToast(data);
        });

        //felhasználók közötti keresés
        $scope.searchUsers = function (nameValue) {
            if ($scope.Users.length == 0) return;

            return Enumerable.From($scope.Users).Where(function (x) { return x.UserName.toLowerCase().indexOf(nameValue) != -1 || x.Name.toLowerCase().indexOf(nameValue) != -1; }).Select(function (x) { return x; }).ToArray();
        };

        $scope.supplierIsEmpty = true;
        $scope.buyerNameIsEmpty = true;
        $scope.buyerAdressIsEmpty = true;
        $scope.isClone = false;
        var isTakeIn = true;
        $scope.isFromMoreDelivery = false;
        var userId = $window.sessionStorage.getItem('userid');
        $scope.SalesRadio = 'Bill';
        $scope.costCenterIsCollapsed = true;
        $scope.costCenters = [];
        var deliveryIdentities = [];
        var alreadySearched = false;

        var partnerId = localStorage.getItem('partnerId');
        var sendNoteList = localStorage.getItem('sendNoteList');

        var deliveryIdentityWithCompanyId =
            {
                DeliveryIdentityList: JSON.parse(localStorage.getItem('sendNoteList')),
                CompanyId: partnerId
            };

        localStorage.removeItem('sendNoteList');

        var products = JSON.parse(localStorage.getItem('products'));
        localStorage.removeItem('products');
        var voucherData = JSON.parse(localStorage.getItem('voucherData'));
        localStorage.removeItem('voucherData');
        var costCenters = JSON.parse(localStorage.getItem('costCenters'));
        localStorage.removeItem('costCenters');

        // A felhasználóhoz tartozó raktár és a raktárhoz tartozó költséghelyek beállítása, 
        // valamint a nyelv, pénznemek, fizetési típus, áfák beállítása
        $http.get(apiurl.stock.getBaseDataForTakeIn + userId).success(function (data) {
            $scope.Warehouses = data.UserData.Warehouses;
            $scope.actWarehouse = getDefaultOrFirstIfAny(data.UserData.DefaultWarehouse, data.UserData.Warehouses);
            if ($scope.actWarehouse.CostCenters.length > 0) {
                var percentage = Number((100 / $scope.actWarehouse.CostCenters.length).toFixed(0));
                var error = Number((100 % $scope.actWarehouse.CostCenters.length).toFixed(0));
                angular.forEach($scope.actWarehouse.CostCenters, function (costCenter) {
                    var tempCostCenter = {
                        Id: costCenter.Id,
                        Name: costCenter.Name,
                        loadingRate: percentage
                    };
                    $scope.costCenters.push(tempCostCenter);
                });

                $scope.costCenters[0].loadingRate += error;

            }
            $scope.Languages = data.Languages;
            $scope.Language = $scope.Languages[0];

            $scope.Currencies = data.Currencies;
            $scope.Currency = $scope.Currencies[0];

            $scope.PaymentTypes = data.PaymentTypes;
            $scope.PaymentType = $scope.PaymentTypes[0];

            $scope.TaxRates = data.TaxRates;
        }).then(function () {

            if (products !== null && voucherData !== null) {
                $scope.newButtonText = "Új termék";
                $scope.isClone = true;
                var productsIds = {};
                var origiPurch = {};

                var i = 0, length = products.length;
                for (i; i < length; i++) {
                    productsIds[products[i].ProductId] = 0;
                }

                $http.post(apiurl.stock.getOriginalPurchasePricesByProductId, productsIds).success(function (data) {
                    origiPurch = data;
                }).then(function () {
                    i = 0, length = products.length;
                    for (i; i < length; i++) {
                        for (key in origiPurch) {
                            if (products[i].ProductId == key) {
                                products[i].OriginalPurchasePrice = origiPurch[key];
                                delete origiPurch[key];
                            }
                        }
                        products[i].Id = products[i].ProductId;
                        products[i].TaxRateName = products[i].TaxRate.TaxRateName;
                        products[i].TaxRateId = products[i].TaxRate.Id;
                        products[i].TaxRate = getDefaultOrFirstIfAny({ Id: products[i].TaxRateId }, $scope.TaxRates);
                        $scope.products.push(products[i]);

                        $scope.changeRow(i);
                    }
                });

                voucherData.RoundingDiff = 0;
                $scope.voucherData = voucherData;
                $scope.actCompany = {
                    Name: voucherData.VendorName,
                    Id: voucherData.CompanyId
                };

                i = 0, length = $scope.Languages.length;
                for (i; i < length; i++) {
                    if ($scope.Languages[i].Id === $scope.voucherData.LanguageTypeId) {
                        $scope.Language = $scope.Languages[i];
                        break;
                    }
                }

                i = 0, length = $scope.Currencies.length;
                for (i; i < length; i++) {
                    if ($scope.Currencies[i].Id === $scope.voucherData.CurrencyTypeId) {
                        $scope.Currency = $scope.Currencies[i];
                        break;
                    }
                }

                i = 0, length = $scope.PaymentTypes.length;
                for (i; i < length; i++) {
                    if ($scope.PaymentTypes[i].Id === $scope.voucherData.PaymentTypeId) {
                        $scope.PaymentType = $scope.PaymentTypes[i];
                        break;
                    }
                }

                $scope.costCenters = costCenters;

                $scope.supplierIsEmpty = false;
                $scope.buyerNameIsEmpty = false;
                $scope.buyerAdressIsEmpty = false;
            }
            else if (sendNoteList == null || sendNoteList.length <= 2) { // Új bevételezés létrehozása
                // Értékek default értékre állítása
                $scope.newButtonText = "Új termék";
                $scope.actCompany = null;
                $scope.actProduct = null;
                $scope.productList = [];
                $scope.voucherData = {
                    VoucherNumber: '',
                    VendorName: '',
                    VendorAddress: '',
                    VendorBankAccount: '',
                    VendorTaxNumber: '',
                    VendorEUTaxNumber: '',
                    CustomerName: '',
                    CustomerAddress: '',
                    CustomerDeliveryAddress: '',
                    CustomerBankAccount: '',
                    CustomerTaxNumber: '',
                    CustomerEUTaxNumber: '',
                    IssueDate: new Date(),
                    FulfillmentDate: '',
                    DeadlineDate: '',
                    LanguageTypeId: '',
                    CurrencyTypeId: '',
                    PaymentTypeId: '',
                    ContinuousTransaction: false,
                    IsPaid: true,
                    CashDesk: '',
                    RoundingDiff: 0,
                    CreationOwner: '',
                    Status: 0,
                    VoucherType: 0,
                    CreationOwnerUserId: userId,
                    Note: null
                };

                // A saját cégadatok lekérdezése
                getOwnerCompanyData();
            }
            else { // Több bejövő szállítólevél átforgatása számlává
                $http.post(apiurl.stock.takeInInvoiceFromMoreDelivery, deliveryIdentityWithCompanyId).success(function (data) {
                    $scope.newButtonText = "Szolgáltatás felvitele";
                    $scope.costCenters = [];
                    $scope.isFromMoreDelivery = true;
                    // id-k beállítása

                    switch (data.Voucher.VoucherType) {
                        case 46: //Számla
                            $scope.SalesRadio = "Bill";
                            break;
                        case 47: //Szállítólevél
                            $scope.SalesRadio = "Trans";
                            break;
                        default:
                            $scope.SalesRadio = "Bill";
                            break;
                    }

                    $scope.actCompany = {
                        Name: data.Voucher.VendorName,
                        Id: data.CompanyId
                    };

                    //Alapadatok feltöltése
                    $scope.voucherData = {
                        VendorName: data.Voucher.VendorName,
                        VendorAddress: data.Voucher.VendorAddress,
                        VendorBankAccount: data.Voucher.VendorBankAccount,
                        VendorTaxNumber: data.Voucher.VendorTaxNumber,
                        VendorEUTaxNumber: data.Voucher.VendorEUTaxNumber,
                        CustomerName: data.Voucher.CustomerName,
                        CustomerAddress: data.Voucher.CustomerAddress,
                        CustomerDeliveryAddress: data.Voucher.CustomerDeliveryAddress,
                        CustomerBankAccount: data.Voucher.CustomerBankAccount,
                        CustomerTaxNumber: data.Voucher.CustomerTaxNumber,
                        CustomerEUTaxNumber: data.Voucher.CustomerEUTaxNumber,
                        BuyerPersonName: data.Voucher.BuyerPersonName,
                        BuyerPersonTaxNumber: data.Voucher.BuyerPersonTaxNumber,
                        IssueDate: data.Voucher.IssueDate,
                        FulfillmentDate: data.Voucher.FulfillmentDate,
                        DeadlineDate: data.Voucher.DeadlineDate,
                        ContinuousTransaction: data.Voucher.ContinuousTransaction,
                        IsPaid: data.Voucher.IsPaid,
                        CashDesk: data.Voucher.CashDesk,
                        RoundingDiff: data.Voucher.RoundingDiff,
                        DiscountType: data.Voucher.DiscountType,
                        DiscountAmount: data.Voucher.DiscountAmount,
                        DiscountTypeName: data.Voucher.DiscountType === 0 ? '%' : 'Fix',
                        VoucherStatus: data.Voucher.Status,
                        VoucherType: data.Voucher.VoucherType,
                        ValidityDeadline: data.Voucher.ValidityDeadline,
                        Note: data.Voucher.Note
                    };

                    // Az aktuális felhasználó beállítása
                    angular.forEach($scope.Users, function (value, key) {
                        if (value.Id == userId) {
                            $scope.actualUser = {
                                Name: value.Name,
                                UserName: value.Username,
                                Id: value.Id
                            }
                        }
                    });

                    deliveryIdentities = data.DeliveryIdentities;

                    $scope.datePickers = {
                        deadline: { isOpen: false, date: $scope.voucherData.DeadlineDate },
                        fulfillment: { isOpen: false, date: $scope.voucherData.FulfillmentDate },
                        validityDeadline: { isOpen: false, date: $scope.voucherData.ValidityDeadline }
                    };

                    for (var i = 0; i < data.StockItems.length; i++) {
                        var product = {
                            Name: data.StockItems[i].Name,
                            Quantity: data.StockItems[i].Quantity,
                            QuantityType: data.StockItems[i].QuantityType,
                            QuantityTypeId: data.StockItems[i].QuantityTypeId,
                            NetPrice: data.StockItems[i].NetPrice,
                            Vat: data.StockItems[i].Vat,
                            ProductId: data.StockItems[i].ProductId,
                            DiscountAmount: data.StockItems[i].DiscountAmount,
                            DiscountTypeName: data.StockItems[i].DiscountType == 0 ? '%' : 'Fix',
                            TaxRateId: data.StockItems[i].TaxRateId,
                            Identifier: data.StockItems[i].Identifier,
                            Prefix: data.StockItems[i].Prefix,
                            StartNumberOfRange: data.StockItems[i].StartNumberOfRange,
                            EndNumberOfRange: data.StockItems[i].EndNumberOfRange,
                            Type: {},
                            IsFromMoreDelivery: true,
                            CostCenters: []
                        }

                        // ÁFA beállítása
                        for (var j = 0; j < $scope.TaxRates.length; j++) {
                            if (product.TaxRateId === $scope.TaxRates[j].Id) {
                                product.TaxRate = $scope.TaxRates[j];
                            }
                        }

                        product.Type.Id = data.StockItems[i].ProductTypeId;
                        switch (product.Type.Id) {
                            case 26:
                                product.Type = $scope.productTypes[0];
                                break;
                            case 27:
                                product.Type = $scope.productTypes[1];
                                break;
                            case 28:
                                product.Type = $scope.productTypes[2];
                                break;
                            case 39:
                                product.Type = $scope.productTypes[3];
                                break;
                            default:
                                product.Type = $scope.productTypes[0];
                                break;
                        }
                        $scope.products.push(product);

                        $scope.changeRow(i);
                    }

                    angular.forEach($scope.products, function (prod) {
                        getQuantityTypesByProductId(prod.ProductId).then(function (response) {
                            prod.quantityTypes = response;
                        });
                    });
                });
            }
        });

        $scope.addNewProductIsDisabled = true;

        $scope.$watch("actCompany", function () {
            if (typeof $scope.actCompany == "undefined") {
                $scope.CompanyList = [];
            }

            if ($scope.actCompany != null && angular.isObject($scope.actCompany)) {
                $scope.supplierIsEmpty = false;
            } else {
                if ($scope.actCompany != null && !angular.isObject($scope.actCompany) && $scope.actCompany.length > 2) {
                    var companyName = $scope.actCompany.replace('.', '');
                    $http.get(apiurl.stock.getCompanies + '/' + companyName + '/' + isTakeIn).success(function (data) {
                        $scope.CompanyList = data;
                    });
                } else {
                    $scope.supplierIsEmpty = $scope.isClone ? false : true;
                }
            }

            if ($scope.isFromMoreDelivery === false && $scope.actCompany != null && (!$scope.isClone || alreadySearched)) {
                $scope.voucherData.VendorAddress = $scope.actCompany.Address;
                $scope.voucherData.VendorBankAccount = $scope.actCompany.BankAccount;
                $scope.voucherData.VendorTaxNumber = $scope.actCompany.TaxNumber;
                $scope.voucherData.VendorEUTaxNumber = $scope.actCompany.EUTaxNumber;

            }
            $scope.addNewProductIsDisabled = ($scope.supplierIsEmpty || $scope.buyerNameIsEmpty);
        });

        $scope.selectActCompany = function (id) {
            $http.get(apiurl.stock.getCompanyById + '/' + id).success(function (data) {
                $scope.actCompany = data;
                alreadySearched = true;
            });
        };

        $scope.$watchCollection('[voucherData.VendorAddress, voucherData.VendorTaxNumber, voucherData.CustomerName, voucherData.CustomerAddress , actCompany, PaymentType]', function () {

            $scope.summValue = 0;
            var sValue = 0;
            angular.forEach($scope.products, function (product) {
                if (!isNaN(product.GrossValue)) {
                    sValue += product.GrossValue;
                }
            });

            //Ha készpénzes a fizetési típusa akkor kerekít 5 forintra
            $scope.getRoundedSumValue(sValue);

            if (typeof $scope.PaymentType !== "undefined" && $scope.PaymentType.Id != 20) {
                $scope.voucherData.RoundingDiff = 0;
            }

            if ($scope.actCompany != null && angular.isObject($scope.actCompany)) {
                $scope.supplierIsEmpty = false;
            } else {
                $scope.supplierIsEmpty = true;
            }

            if (!$scope.isFromMoreDelivery && !angular.isUndefined($scope.voucherData) && $scope.voucherData.CustomerName != null && $scope.voucherData.CustomerName != "" && $scope.voucherData.CustomerAddress && $scope.voucherData.CustomerAddress != "" &&
                $scope.voucherData.VendorAddress != null && $scope.voucherData.VendorAddress != "" && $scope.voucherData.VendorTaxNumber != null && $scope.voucherData.VendorTaxNumber != "") {
                $scope.buyerNameIsEmpty = false;
            }
            else {
                $scope.buyerNameIsEmpty = $scope.isFromMoreDelivery ? false : true;
            }
            $scope.addNewProductIsDisabled = ($scope.supplierIsEmpty || $scope.buyerNameIsEmpty);
        });

        $scope.discountButton = function (product) {
            if (product.DiscountType === "%") {
                product.DiscountType = "Fix";
            }
            else {
                product.DiscountType = "%";
            }
        }

        $scope.takeInStock = function (products) {
            var costCenterDictionary = {};

            if (CheckCostCenters.Check(products, $scope.costCenters, costCenterDictionary)) {
                return;
            }

            if (!confirm('Valóban el akarja menteni?')) {
                return;
            }

            var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
            var status = 37;
            var voucherType = $scope.SalesRadio === 'Bill' ? 46 : 47;
            $scope.voucherData.VendorName = $scope.actCompany.Name;
            $scope.voucherData.IssueDate = $filter('date')($scope.IssueDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.FulfillmentDate = $filter('date')($scope.FulfillmentDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.DeadlineDate = $filter('date')($scope.DeadlineDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.LanguageTypeId = $scope.Language.Id;
            $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
            $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
            $scope.voucherData.Status = status;
            $scope.voucherData.VoucherType = voucherType;
            $scope.voucherData.CreationDate = $filter('date')($scope.ReceiptDatePicker, 'yyyy.MM.dd');

            if (!angular.isObject($scope.actualUser)) {
                toasterService.warningToast("Figyelem", 'A kiállító nevének megadása kötelező és egy regisztrált felhasználónak kell lennie.');
                return;
            }

            $scope.voucherData.CreationOwnerUserId = $scope.actualUser.Id;
            $scope.voucherData.CreationOwner = $scope.actualUser.Name;

            if (!CheckVoucherItems.Check(products, $scope.voucherData, true)) {
                return;
            }

            var data = {
                StockItems: products,
                WarehouseId: $scope.actWarehouse.Id,
                CompanyId: $scope.actCompany.Id,
                IsInvoice: isInvoice,
                Voucher: $scope.voucherData,
                CostCenterIdentites: costCenterDictionary
            };

            $scope.data = data;

            //Hozzá kell-e adni új terméket?
            hasProductIdError = $scope.AddNewProduct(data);

            if (hasProductIdError === null) {
                return;
            }

            if ($scope.products.length > 0 && !hasProductIdError) {
                $scope.UpdatePricesAndTakeIn();
            }
            else if ($scope.products.length == 0) {
                toasterService.warningToast("Figyelem", "Nem adott meg terméket.");
            }
        };

        $scope.takeInStockWithoutStockMoving = function (products) {
            var costCenterDictionary = {};

            if (CheckCostCenters.Check(products, $scope.costCenters, costCenterDictionary)) {
                return;
            }

            if (!confirm('Valóban el akarja menteni?')) {
                return;
            }

            var isInvoice = $scope.SalesRadio === 'Bill' ? true : false;
            var status = 37;
            var voucherType = $scope.SalesRadio === 'Bill' ? 46 : 47;
            $scope.voucherData.VendorName = $scope.actCompany.Name;
            $scope.voucherData.IssueDate = $filter('date')($scope.IssueDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.FulfillmentDate = $filter('date')($scope.FulfillmentDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.DeadlineDate = $filter('date')($scope.DeadlineDatePicker, 'yyyy.MM.dd');
            $scope.voucherData.LanguageTypeId = $scope.Language.Id;
            $scope.voucherData.CurrencyTypeId = $scope.Currency.Id;
            $scope.voucherData.PaymentTypeId = $scope.PaymentType.Id;
            $scope.voucherData.Status = status;
            $scope.voucherData.VoucherType = voucherType;

            if (!angular.isObject($scope.actualUser)) {
                toasterService.warningToast("Figyelem", 'A kiállító nevének megadása kötelező és egy regisztrált felhasználónak kell lennie.');
                return;
            }

            $scope.voucherData.CreationOwnerUserId = $scope.actualUser.Id;
            $scope.voucherData.CreationOwner = $scope.actualUser.Name;

            var data = {
                StockItems: products,
                WarehouseId: $scope.actWarehouse.Id,
                CompanyId: $scope.actCompany.Id,
                IsInvoice: isInvoice,
                Voucher: $scope.voucherData,
                CostCenterIdentites: costCenterDictionary,
                DeliveryIdentities: deliveryIdentities
            };

            $http.post(apiurl.stock.takeInStockWithoutStockMoving, data).success(function () {
                window.location = siteurl.salesCertification;
            });
        };

        $scope.AddNewProduct = function (postdata) {
            $scope.newProductList = [];
            var newProductNameList = '\n';
            angular.forEach($scope.products, function (pr) {
                if (pr.ProductId == '') {
                    pr.Vat = pr.TaxRate.TaxRateValue;
                    pr.TaxRateId = pr.TaxRate.Id;

                    var np = {
                        Name: pr.Name,
                        QuantityType: pr.QuantityType,
                        NetPrice: pr.NetPrice,
                        Vat: pr.TaxRate.TaxRateValue,
                        TaxRateId: pr.TaxRate.Id,
                        CompanyId: $scope.actCompany.Id
                    };
                    $scope.newProductList.push(np);
                    newProductNameList += pr.Name + '\n';
                }
            });

            if (!$scope.newProductList.length) {
                return false;
            }

            if (!confirm("A következő termék(ek) LEHETSÉGES, hogy nincs(enek) benne a Terméktörzsbe, " +
                "vagy nincsenek a beszállítóhoz rendelve:" + newProductNameList + " \nSzeretné a Terméktörzsbe rögzíteni?")
            ) {
                return null;
            }

            //Terméktörzsbe mentés
            $http.post(apiurl.stock.addProduct, $scope.newProductList).success(function (data) {
                angular.forEach(postdata.StockItems, function (pr) {
                    angular.forEach(data, function (prod) {
                        if (pr.Name == prod.Name) {
                            pr.ProductId = prod.ProductId;
                        }
                    });
                });

                $scope.TakeInPost(postdata);
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
            return true;
        };

        $scope.TakeInPost = function (data) {

            $http.post(apiurl.stock.takeIn, data).success(function () {
                //$scope.receiptForm.$setPristine();
                window.location.href = siteurl.receipt;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        $scope.products = [];

        $scope.warehouseOnSelect = function (model) {
            $scope.costCenters = [];
            if (model.CostCenters.length > 0) {
                var percentage = Number((100 / $scope.actWarehouse.CostCenters.length).toFixed(0));
                var error = Number((100 % $scope.actWarehouse.CostCenters.length).toFixed(0));
                angular.forEach($scope.actWarehouse.CostCenters, function (costCenter) {
                    var tempCostCenter = {
                        Id: costCenter.Id,
                        Name: costCenter.Name,
                        loadingRate: percentage
                    };
                    $scope.costCenters.push(tempCostCenter);
                });

                $scope.costCenters[0].loadingRate += error;
            }
        };

        $scope.productTypes = productTypes;

        $scope.actProductType = $scope.productTypes[0];

        $scope.addRow = function () {

            var product = {
                Type: $scope.actProductType,
                Name: '',
                ProductId: '',
                Quantity: 1,
                QuantityType: '',
                NetPrice: '',
                DiscountType: "%",
                Vat: '',
                Prefix: '',
                StartNumberOfRange: '',
                EndNumberOfRange: '',
                Identifier: null,
                TaxRateName: null,
                TaxRateValue: null,
                TaxRateId: null,
                TaxRate: $scope.TaxRates[$scope.TaxRates.length - 1],
                CostCenters: [],
                LastModified: $filter('date')($scope.ReceiptDatePicker, 'yyyy.MM.dd')
            };

            $scope.products.push(product);
        };

        //Költséghelyekhez init
        $scope.haveCostCenter = false;
        $scope.haveItemCostCenter = false;

        //Egy új sor hozzáadása
        $scope.AddCostCenterRow = function () {
            for (var i = 0; i < $scope.products.length; i++) {
                if ($scope.products[i].CostCenters.length != 0) {
                    $scope.haveItemCostCenter = true;
                    break;
                } else {
                    $scope.haveItemCostCenter = false;
                }
            };

            if ($scope.haveItemCostCenter) {
                toasterService.warningToast("Figyelem", "Már adott meg a tételekhez költséghelyet!");

            } else {
                $scope.haveCostCenter = true;

                $scope.costCenter = {
                    Name: '',
                    loadingRate: 0
                };

                $scope.costCenters.push($scope.costCenter);
            }
        };

        //Egy sor törlése
        $scope.DeleteCostCenterRow = function (index) {
            $scope.costCenters.splice(index, 1);
            if ($scope.costCenters.length == 0) {
                $scope.haveCostCenter = false;
            }
        };

        //Költséghely keresése
        $scope.GetCostCenters = function (costCenterName) {
            if (costCenterName.length < 3) {
                return [];
            }
            return $http.get(apiurl.costCenter.getAllCostCenter + costCenterName)
            .then(function (data) {
                return data.data;
            });
        };

        //Költséghelyeknél enter leütésre hozzáad egy sort
        $scope.IsEnterAtCostCenter = function (ev) {
            if (ev.which == 13) {
                $scope.AddCostCenterRow();
            }
        };

        //A kiválasztott költséghely hozzáadása a listához
        $scope.CostCenterSelected = function ($model, $index) {
            var isNew = true;
            angular.forEach($scope.costCenters, function (costCenter) {
                if ($model.Id == costCenter.Id) {
                    toasterService.errorToast("Már hozzáadta a listához ezt a költséghelyet!");
                    isNew = false;
                    $scope.costCenters[$index] = {
                        Name: '',
                        loadingRate: 0
                    }
                }
                return;
            });
            if (isNew) {
                $scope.costCenters[$index].Id = $model.Id;
            }
        };
        ////Termékhez tartozó költséghelyek

        //Termékhez egy új költséghely hozzáadása
        $scope.AddCostCenterRowForProduct = function (index) {
            if ($scope.costCenters.length > 0) {
                toasterService.warningToast("Figyelem", "Már adott meg a számlához költséghelyet!");
            } else {

                $scope.costCenterForProduct = {
                    Name: '',
                    loadingRate: 0
                };
                $scope.products[index].CostCenters.push($scope.costCenterForProduct);
            }
        };

        //Termékhez tartozó költséghely hozzáadása a termékhez
        $scope.CostCenterSelectedForProduct = function (product, $model, $index) {
            var isNew = true;
            angular.forEach(product.CostCenters, function (costCenter) {
                if ($model.Id == costCenter.Id) {
                    toasterService.errorToast("Már hozzáadta a listához ezt a költséghelyet!");
                    isNew = false;
                    product.CostCenters[$index] = {
                        Name: '',
                        loadingRate: 0
                    }
                }
                return;
            });
            if (isNew) {
                product.CostCenters[$index].Id = $model.Id;
            }
        };

        //Termékhez tartozó költséghely törlése
        $scope.DeleteCostCenterRowForProduct = function (index, model) {
            model.CostCenters.splice(index, 1);
        };

        $scope.taChange = function ($index) {
            $scope.products[$index].ProductId = '';
        };

        $scope.taOnSelect = function ($model, $index) {
            angular.forEach($scope.productTypes, function (pt) {
                if ($model.TypeId == pt.Id) {
                    $scope.products[$index].Type = pt;
                    $scope.products[$index].TypeId = pt.Id;
                }
            });

            $scope.products[$index].Id = $model.Id;
            $scope.products[$index].Name = $model.Name;
            $scope.products[$index].ProductId = $model.Id;
            $scope.products[$index].NetPrice = $model.NetPurchasePrice;
            $scope.products[$index].Vat = $model.Vat;
            $scope.products[$index].QuantityType = $model.QuantityType;
            $scope.products[$index].QuantityTypeId = $model.QuantityTypeId;
            $scope.products[$index].TaxRateName = $model.TaxRateName;
            $scope.products[$index].TaxRateId = $model.TaxRateId;
            $scope.products[$index].TaxRate = getDefaultOrFirstIfAny({ Id: $model.TaxRateId }, $scope.TaxRates);
            $scope.products[$index].OriginalPurchasePrice = $model.OriginalPurchasePrice;
            $scope.changeRow($index);

            getQuantityTypesByProductId($model.Id).then(function (response) {
                $scope.products[$index].quantityTypes = response;
            });
        };

        function getQuantityTypesByProductId(productId) {
            return $http.get(apiurl.stock.getQuantityTypesByProductId + '/' + productId).then(function (response) {
                return response.data;
            });
        }

        $scope.deleteRow = function (index) {
            $scope.products.splice(index, 1);

            $scope.summValue = 0;
            var sValue = 0;
            angular.forEach($scope.products, function (product) {
                if (!isNaN(product.GrossValue)) {
                    sValue += product.GrossValue;
                }
            });

            //Ha készpénzes a fizetési típusa akkor kerekít 5 forintra
            $scope.getRoundedSumValue(sValue);

        };


        $scope.changeRow = function (index) {
            var vat = $scope.isFromMoreDelivery ? $scope.products[index].Vat : $scope.products[index].TaxRate.TaxRateValue;
            if ($scope.products[index].NetPrice !== undefined) {
                $scope.products[index].NetPrice = $scope.products[index].NetPrice.toString().replace(',', '.');
            }

            if ($scope.products[index].NetPrice > 0) {
                $scope.products[index].GrossPrice = PriceCounter.GrossPrice($scope.products[index].NetPrice, vat);

            }
            if ($scope.products[index].NetPrice > 0 && $scope.products[index].Quantity > 0) {
                $scope.products[index].NetValue = PriceCounter.NetValue($scope.products[index].NetPrice, $scope.products[index].Quantity);
                $scope.products[index].GrossValue = PriceCounter.GrossValue($scope.products[index].NetPrice, $scope.products[index].Quantity, vat);
                $scope.products[index].VatValue = PriceCounter.VatValue($scope.products[index].NetPrice, $scope.products[index].Quantity, vat);
            }

            $scope.summValue = 0;
            var sValue = 0;
            angular.forEach($scope.products, function (product) {
                if (!isNaN(product.GrossValue)) {
                    sValue += product.GrossValue;
                }
            });
            // $scope.sumValue = sValue;

            //Ha készpénzes a fizetési típusa akkor kerekít 5 forintra
            $scope.getRoundedSumValue(sValue);
        };

        $scope.canChange = function () {
            if ($scope.products.length > 0) {
                return true;
            }

            return false;
        };

        $scope.backButtonClick = function () {
            if (confirm("Valóban ki akar lépni?")) {
                //$scope.receiptForm.$setPristine();
                window.location.href = siteurl.home;
            }
        };

        $scope.salesRadioChange = function () {
            if ($scope.SalesRadio === 'Trans') {
                $scope.voucherData.IsPaid = false;
                $scope.voucherData.ContinuousTransaction = false;
            } else {
                $scope.voucherData.IsPaid = true;
                $scope.voucherData.ContinuousTransaction = false;
            }
        };

        $scope.getRoundedSumValue = function (sValue) {
            //Ha készpénzes a fizetési típusa akkor kerekít 5 forintra
            if (typeof $scope.PaymentType === 'undefined' || $scope.PaymentType.Id == 20) {
                $scope.sumValue = Math.round(Number((sValue).toFixed(0)) / 5) * 5;
            }
            else {
                $scope.sumValue = Number((sValue).toFixed(0));
            }
        };

        // Termék keresése
        $scope.getProducts = function (productName) {
            if (productName.length < 3) {
                return [];
            }

            var params = {
                params: {
                    productName: productName,
                    isFromMoreDelivery: $scope.isFromMoreDelivery,
                },
            };
            return $http.get(apiurl.stock.getSupplierProductsWithName + $scope.actCompany.Id, params)
            .then(function (data) {
                return data.data;
            });
        };

        $scope.UpdatePricesAndTakeIn = function () {
            var idList = [];
            angular.forEach($scope.products, function (product) {
                if (product.OriginalPurchasePrice != product.NetPrice) {
                    idList.push(product.Id);
                }
            });

            if (idList.length == 0) {
                $scope.TakeInPost($scope.data);
            }
            else {
                $http.post(apiurl.stock.getProductPrices, idList).success(function (data) {
                    $scope.ProductsToUpdate = data;
                    idList = [];
                    angular.forEach($scope.products, function (prod) {
                        angular.forEach($scope.ProductsToUpdate, function (prodToUpdate) {
                            if (prod.Id === prodToUpdate.Id) {
                                prodToUpdate.NewPurchasePrice = prod.NetPrice;
                            }
                        });
                    });
                    var modalInstance = $uibModal.open({
                        templateUrl: 'receiptModalContent.html',
                        controller: ModalInstanceCtrl,
                        size: 'lg',
                        windowClass: 'modal_extlg',
                        resolve: {
                            ProductsToUpdate: function () {
                                return $scope.ProductsToUpdate;
                            }
                        }
                    });

                    modalInstance.result.then(function (ProductsToUpdate) {
                        $http.post(apiurl.stock.updatePrices, ProductsToUpdate).success(function () {
                            $scope.TakeInPost($scope.data);
                        }).error(function (data2) {
                            toasterService.errorFromServerToast(data2);
                        });
                    }, function () {
                    });
                });
            }
        }

        //Termék neve mezőbe enter leütésre hozzáad egy sort
        $scope.isEnter = function (ev) {
            if (ev.which == 13) {
                $scope.addRow();
            }
        };
    }

    ModalInstanceCtrl.$inject = ['$scope', '$http', '$uibModalInstance', 'ProductsToUpdate'];
    function ModalInstanceCtrl($scope, $http, $uibModalInstance, ProductsToUpdate) {

        $scope.ProductsToUpdate = ProductsToUpdate;

        $scope.changedNetPrice = function (index) {
            //$scope.ProductsToUpdate[index].NewPurchasePrice = $scope.ProductsToUpdate[index].NewPurchasePrice.replace(",", ".");
            $scope.ProductsToUpdate[index].NewRetailPrice = Number(($scope.ProductsToUpdate[index].NewPurchasePrice * (1 + ($scope.ProductsToUpdate[index].NewRetailMargin / 100))).toFixed(2));
            $scope.ProductsToUpdate[index].NewWholesalePrice = Number(($scope.ProductsToUpdate[index].NewPurchasePrice * (1 + ($scope.ProductsToUpdate[index].NewWholesaleMargin / 100))).toFixed(2));
        };

        $scope.changedRetailPrice = function (index) {
            //$scope.ProductsToUpdate[index].NewRetailPrice = $scope.ProductsToUpdate[index].NewRetailPrice.replace(",", ".");
            $scope.ProductsToUpdate[index].NewRetailMargin = Number(((($scope.ProductsToUpdate[index].NewRetailPrice - $scope.ProductsToUpdate[index].NewPurchasePrice) / $scope.ProductsToUpdate[index].NewPurchasePrice) * 100).toFixed(2));
        }

        $scope.changedRetailMargin = function (index) {
            //$scope.ProductsToUpdate[index].NewRetailMargin = $scope.ProductsToUpdate[index].NewRetailMargin.replace(",", ".");
            $scope.ProductsToUpdate[index].NewRetailPrice = Number(($scope.ProductsToUpdate[index].NewPurchasePrice * (1 + ($scope.ProductsToUpdate[index].NewRetailMargin / 100))).toFixed(4));
        };

        $scope.changedWholesalePrice = function (index) {
            //$scope.ProductsToUpdate[index].NewWholesalePrice = $scope.ProductsToUpdate[index].NewWholesalePrice.replace(",", ".");
            $scope.ProductsToUpdate[index].NewWholesaleMargin = Number(((($scope.ProductsToUpdate[index].NewWholesalePrice - $scope.ProductsToUpdate[index].NewPurchasePrice) / $scope.ProductsToUpdate[index].NewPurchasePrice) * 100).toFixed(2));
        };

        $scope.changedWholesaleMargin = function (index) {
            //$scope.ProductsToUpdate[index].NewWholesaleMargin = $scope.ProductsToUpdate[index].WholesaleMargin.replace(",", ".");
            $scope.ProductsToUpdate[index].NewWholesalePrice = Number(($scope.ProductsToUpdate[index].NewPurchasePrice * (1 + ($scope.ProductsToUpdate[index].NewWholesaleMargin / 100))).toFixed(4));
        };

        angular.forEach($scope.ProductsToUpdate, function (product, key) {
            $scope.ProductsToUpdate[key].PurcasePriceDiff = $scope.ProductsToUpdate[key].NewPurchasePrice - $scope.ProductsToUpdate[key].OriginalPurchasePrice;
            product.NewWholesaleMargin = angular.copy(product.OriginalWholesaleMargin);
            product.NewRetailMargin = angular.copy(product.OriginalRetailMargin);
            $scope.changedNetPrice(key);
        });


        $scope.selectAll = false;

        $scope.changeAll = function () {
            $scope.selectAll = !($scope.selectAll);
            angular.forEach($scope.ProductsToUpdate, function (prod) {
                prod.IsAcceppted = $scope.selectAll;
            });
        };

        $scope.ok = function () {
            if (confirm("Valóban menteni szeretné?")) {
                $uibModalInstance.close($scope.ProductsToUpdate);
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

})();
