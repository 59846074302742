(function () {

    angular.module('IMasterApp')
        .controller("OrderRequestCtrl", OrderRequestCtrl)
        .controller("EditOrderRequestCtrl", EditOrderRequestCtrl);

    OrderRequestCtrl.$inject = ['$scope', '$http', '$filter', 'apiurl', 'toasterService', '$window', 'OrderRequestService', 'siteurl'];
    function OrderRequestCtrl($scope, $http, $filter, apiurl, toasterService, $window, OrderRequestService, siteurl) {

        $scope.selectAll = false;
        //init
        $scope.filter = {
            PageSize: 25,
            TotalItemCount: 0,
            PageIndex: 0,
            OrderRequestNumber: "",
            Customer: "",
            CostCenter: "",
            Warehouse: "",
            FromNetPrice: "",
            ToNetPrice: "",
            CostCenterPeriod: "",
            SubmitClaim: true,
            UnderOrdering: true,
            Declaimed: true,
            NeedToChange: true,
            Accomplished: true,
            SortIndex: "",
            OrderDirection: true
        };

        // constructor
        $scope.GetOrderRequestsList = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.filter.SortIndex) {
                    $scope.filter.OrderDirection = !$scope.filter.OrderDirection;
                }
                else {
                    $scope.filter.OrderDirection = true;
                    $scope.filter.SortIndex = sortIndex;
                }
            }

            OrderRequestService.GetOrderRequests($scope.filter).success(function (data) {
                $scope.data = new OrderRequestDtoForList(data);
                $scope.filter.TotalItemCount = data.TotalItems;
            }).error(function () {
                toasterService.errorFromServerToast(data);
            });
        };
        $scope.GetOrderRequestsList();

        // Pager & filter
        $scope.changeSelected = function () {
            $scope.GetOrderRequestsList();
        };

        $scope.pageChanged = function () {
            $scope.filter.PageIndex = $scope.current - 1;
            $scope.GetOrderRequestsList();
        };

        $scope.NewRequest = function () {
            window.location.href = siteurl.editorderrequest + '/0';
        };

        $scope.editOrderRequest = function (orderRequestId) {
            window.location.href = siteurl.editorderrequest + '/' + orderRequestId;
        };

        $scope.deleteSearch = function () {

            $scope.filter.OrderRequestNumber = "";
            $scope.filter.Customer = "";
            $scope.filter.CostCenter = "";
            $scope.filter.Warehouse = "";
            $scope.filter.FromNetPrice = "";
            $scope.filter.ToNetPrice = "";
            $scope.filter.CostCenterPeriod = "";
            $scope.filter.SubmitClaim = true;
            $scope.filter.UnderOrdering = true;
            $scope.filter.Declaimed = true;
            $scope.filter.NeedToChange = true;
            $scope.filter.Accomplished = true;

            $scope.GetOrderRequestsList();
        };

        $scope.changeAll = function () {
            angular.forEach($scope.data.OrderRequestList, function (orderRequest) {
                if (orderRequest.OrderStatusName !== "Visszautasítva" && orderRequest.OrderStatusName !== "Megrendelés alatt" && orderRequest.OrderStatusName !== "Módosítandó") {
                    orderRequest.isSelected = $scope.selectAll;
                }
            });
        };

        $scope.CreateOrder = function () {
            if (confirm("Biztosan át akarja forgatni ezeket az igényeket?")) {
                var orderReqestIds = [];
                angular.forEach($scope.data.OrderRequestList, function (orderRequest) {
                    if (orderRequest.isSelected) {
                        orderReqestIds.push(orderRequest.Id);
                    }
                });
                if (orderReqestIds.length == 0) {
                    toasterService.warningToast("Figyelem", "Nem választott ki megrendelési igényt!");
                } else {
                    OrderRequestService.OrderFromOrderRequests(orderReqestIds).success(function (data) {
                        //Felület firssítése
                        $scope.GetOrderRequestsList();

                        window.location.href = apiurl.excelReport.generateExcelReport + data;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        $scope.canChangeStatus = function () {
            var temp = $window.sessionStorage.getItem('roleids').split(',');
            var canChange = false;
            for (var i = 0; i < temp.length; i++) {
                if (temp[i] == 32) {
                    canChange = true;
                    break;
                }
            }
            return canChange;
        };
        $scope.canChangeStatus = $scope.canChangeStatus();

    }

    EditOrderRequestCtrl.$inject = ['$scope', '$http', 'apiurl', '$locale', '$filter', '$window', 'OrderRequestService', 'toasterService', 'siteurl', 'PriceCounter'];
    function EditOrderRequestCtrl($scope, $http, apiurl, $locale, $filter, $window, OrderRequestService, toasterService, siteurl, PriceCounter) {

        // init
        var orderRequestId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        $scope.isNewOrderRequest = orderRequestId == 0;
        $scope.CostCenterPeriods = [];
        $scope.prod = "";
        $scope.costCenterPeriodDisabled = true;
        $scope.SumNetValue = 0;
        $scope.SumGrossValue = 0;
        $scope.LimitDifferent = 0;
        $scope.btnDisabled = true;
        $scope.limitDifferentClass = "alert-success";
        $scope.canChangeStatus = false;
        $scope.HUFOrderLimit = 0;
        $scope.isCostCenterDisabled = true;
        $scope.isPeriodDisabled = true;
        $scope.isRestrictedVisible = false;
        $scope.isChangableVisible = false;
        $scope.isRequestVisible = true;
        $scope.disabledAddButton = true;
        var original_OrderRequest = [];

        var LoadOrderRequest = function (orderRequestId) {
            if (orderRequestId == 0) {
                $scope.orderRequest = new OrderRequestDTO({});
                $scope.title = "Új megrendelés";
                $scope.setCostCenterPeriod();
            }
            else {
                OrderRequestService.GetOrderRequest(orderRequestId)
                .success(function (data) {
                    $scope.orderRequest = new OrderRequestDTO(data);
                    original_OrderRequest = angular.copy($scope.orderRequest);
                    $scope.canChangeStatus = canChangeStatus();
                    if ($scope.orderRequest.OrderRequestStatus.Name === "Megrendelés alatt") {
                        $scope.disabledElement = true;
                    }
                    $scope.CostCenterPeriods.push(data.CostCenterPeriod);
                    $scope.isRestrictedVisible = $scope.canChangeStatus;
                    $scope.isChangableVisible = $scope.canChangeStatus;
                    if (!$scope.canChangeStatus) {
                        $scope.isRequestVisible = isRequestButtonVisible();
                    }
                    $scope.isRequestVisible = true;
                    angular.forEach($scope.orderRequest.CostCenter.OrderLimitList, function (value) {
                        if (value.CurrencyId == 1) { //HUF
                            $scope.HUFOrderLimit = value.OrderLimit;
                        }
                    });
                    $scope.disabledAddButton = false;

                    //Limiteltérés lekérdezése
                    var limitParameter = { CostCenterId: $scope.orderRequest.CostCenter.Id, Period: $scope.orderRequest.CostCenterPeriod };
                    OrderRequestService.CountedLimit(limitParameter).success(function (limit) {
                        $scope.orderRequest.CountedLimit = Number(limit);

                        if ($scope.orderRequest.Items.length > 0) {
                            angular.forEach($scope.orderRequest.Items, function (product, key) {
                                $scope.countGrossAndNetPrice(key);
                            });
                        } else {
                            $scope.SumValues();
                        }

                    }).error(function (data2) {
                        toasterService.errorFromServerToast(data2);
                    });

                    $scope.title = data.CostCenterPeriod;

                }).error(function () {
                    toasterService.errorToast("Sikertelen Adatlekérés", "A megrendelés lekérése sikertelen!");
                });
            }
        }

        // Partnerek lekérése
        $scope.getPartners = function () {
            if (angular.isString($scope.orderRequest.Customer) && ($scope.orderRequest.Customer.length > 2)) {
                OrderRequestService.GetCustomers($scope.orderRequest.Customer).success(function (data) {
                    $scope.partnerList = new PartnersDTO(data);
                });
            }
        }

        // Raktárak lekérése
        $scope.getWarehouses = function () {
            if (angular.isString($scope.orderRequest.Warehouse) && $scope.orderRequest.Warehouse.length > 2) {
                OrderRequestService.GetActiveWarehouses($scope.orderRequest.Warehouse).success(function (data) {
                    $scope.warehouseList = new WarehousesDTO(data);
                })
                .error(function () {
                    toasterService.errorToast("Sikertelen adatlekérés", "A raktárak lekérése sikertelen!");
                });
            }
        }

        // Rendelési időszak
        $scope.setCostCenterPeriod = function () {
            var actualDate = new Date();
            var nextDate = new Date();
            nextDate.setMonth(actualDate.getMonth() + 1);

            $scope.CostCenterPeriods = [
                $filter('date')(actualDate, 'yyyy. MMMM'),
                $filter('date')(nextDate, 'yyyy. MMMM')
            ];
        };

        $scope.checkPeriod = function () {
            $scope.btnDisabled = false;
            if ($scope.orderRequest.CostCenter.Id == 0) {
                toasterService.warningToast("Figyelem!", "Még nem választott ki költséghelyet!");
                $scope.orderRequest.CostCenterPeriod = "";
                return;
            }
            var searchasd = {
                CostCenterId: $scope.orderRequest.CostCenter.Id,
                CostCenterPeriod: $scope.orderRequest.CostCenterPeriod
            };

            angular.forEach($scope.orderRequest.CostCenter.OrderLimitList, function (value) {
                if (value.CurrencyId == 1) { //HUF
                    $scope.HUFOrderLimit = value.OrderLimit;
                }
            });

            if (angular.isObject($scope.orderRequest.CostCenter) && $scope.orderRequest.CostCenterPeriod != "") {
                $scope.disabledAddButton = false;
                $http.post(apiurl.orderRequest.isExistInPeriod, searchasd).success(function (data) {
                    if (data !== 0) {
                        if (confirm("Már létezik erre a rendelési időszakra rendelési igény, kívánja megnyitni?")) {
                            window.location.href = siteurl.editorderrequest + '/' + data;
                        } else {
                            if (confirm("Biztos hogy visszalép?")) {
                                window.location.href = siteurl.orderrequest;
                            } else {
                                $scope.orderRequest.CostCenterPeriod = "";
                            }
                        }
                    } else {
                        var limitParameter = { CostCenterId: $scope.orderRequest.CostCenter.Id, Period: $scope.orderRequest.CostCenterPeriod };
                        OrderRequestService.CountedLimit(limitParameter).success(function (limit) {
                            $scope.orderRequest.CountedLimit = Number(limit);
                            $scope.SumValues();
                        }).error(function (data2) {
                            toasterService.errorFromServerToast(data2);
                        });
                    }
                }).error(function (data2) {
                    toasterService.errorFromServerToast(data2);
                });
            }

            $scope.isPeriodDisabled = false;
        }

        // Új sor (termék) hozzáadása 
        $scope.AddRow = function () {
            var product = new ProdtuctDTO({});
            $scope.orderRequest.Items.push(product);
        };
        // Sor (termék) törlése 
        $scope.DeleteRow = function (index) {
            if (!$scope.isNewOrderRequest && Enumerable.From(original_OrderRequest.Items[index]).Any()) {
                if ($scope.HUFOrderLimit < $scope.orderRequest.CountedLimit + original_OrderRequest.Items[index].NetPrice) {
                    $scope.orderRequest.CountedLimit = $scope.HUFOrderLimit;
                } else {
                    $scope.orderRequest.CountedLimit += original_OrderRequest.Items[index].NetPrice;
                }
                original_OrderRequest.Items.splice(index, 1);
            }
            $scope.orderRequest.Items.splice(index, 1);
            $scope.SumValues();
        };

        // Termék lekérése
        $scope.getProducts = function (productName) {
            if (productName.length < 3) {
                return [];
            }
            return OrderRequestService.GetProducts(productName).then(function (data) {
                return data.data;
            });
        };

        // Termékek figyelése
        $scope.productChange = function (index) {
            // A termékkeresőnél a keresőcsík
            if ($scope.orderRequest.Items[index] !== undefined) {
                if ($scope.orderRequest.Items[index].ProductName !== undefined && $scope.orderRequest.Items[index].ProductName !== "") {
                    $scope.isVisibleOrNot = "followingBallsG";
                }
                else {
                    $scope.isVisibleOrNot = "followingBallsGHidden";
                }
            }

            if (!angular.isObject($scope.orderRequest.Items[index].ProductName)) {
                $scope.orderRequest.Items[index].Quantity = 1;
                $scope.orderRequest.Items[index].QuantityType = "";
                $scope.orderRequest.Items[index].NetValue = null;
                $scope.orderRequest.Items[index].Vat = null;
                $scope.orderRequest.Items[index].GrossValue = null;
                $scope.orderRequest.Items[index].ItemNumber = "";
                $scope.orderRequest.Items[index].ProductCategoryName = "";
                $scope.orderRequest.Items[index].ProductId = null;
            }
            $scope.SumValues();
        };

        // Termék kiválasztása
        $scope.selectedProduct = function (product, index) {
            for (var i = 0; i < $scope.orderRequest.Items.length; i++) {
                if ($scope.orderRequest.Items[i].Id == product.ProductId) {
                    toasterService.warningToast("Figyelem", "A termék már szerepel a listában!");
                    $scope.orderRequest.Items[index] = new ProdtuctDTO({});
                    return;
                }
            }
            $scope.orderRequest.Items[index] = new ProdtuctDTO(product);
            $scope.countGrossAndNetPrice(index);
        };

        // Termék mennyiségének változtatása
        $scope.countGrossAndNetPrice = function (index) {
            $scope.orderRequest.Items[index].GrossValue = PriceCounter.GrossValue($scope.orderRequest.Items[index].NetPrice, $scope.orderRequest.Items[index].Quantity, $scope.orderRequest.Items[index].Vat);
            $scope.orderRequest.Items[index].NetValue = PriceCounter.NetValue($scope.orderRequest.Items[index].NetPrice, $scope.orderRequest.Items[index].Quantity);

            $scope.SumValues();
        };

        //Összesítő és limit számítás
        $scope.SumValues = function () {
            // Összesítő számítása
            $scope.SumNetValue = 0;
            $scope.SumGrossValue = 0;
            $scope.LimitDifferent = 0;

            angular.forEach($scope.orderRequest.Items, function (value, key) {
                if (angular.isDefined($scope.orderRequest.Items[key].NetValue) && angular.isDefined($scope.orderRequest.Items[key].GrossValue)) {
                    $scope.SumNetValue += $scope.orderRequest.Items[key].NetValue;
                    $scope.SumGrossValue += $scope.orderRequest.Items[key].GrossValue;
                }
            });

            // Limit számítás
            $scope.LimitDifferent = $scope.orderRequest.CountedLimit - Enumerable.From($scope.orderRequest.Items).Except(original_OrderRequest.Items, "$.ProductName").Select(function (x) { return angular.isUndefined(x.NetValue) || x.NetValue == '' ? 0 : x.NetValue; }).Sum();
            if ($scope.LimitDifferent >= 0) {
                $scope.limitDifferentClass = "alert-success";
            } else {
                if ($scope.limitDifferentClass === "alert-success") {
                    toasterService.warningToast("Költséghely limit", "A megrendélés értéke meghaladja a költséghely limitet!");
                }
                $scope.limitDifferentClass = "alert-danger";
            }
        }

        //Mentheti-e a költséghelyet
        var checkOrderRequest = function () {
            var msg = "";
            angular.forEach($scope.orderRequest.Items, function (item) {
                if (item.ProductCategoryName === "Nincs típus") {
                    item.ProductCategoryName = null;
                }
                if (item.ProductId == 0) {
                    msg += "Nem adott meg minden terméket!";
                }
                if (item.ProductId === null) {
                    msg += "A(z) " + item.ProductName + " nevű termék nem szerepel a terméktörzsben!";
                }
            });

            if ($scope.orderRequest.Warehouse.Id == 0) {
                msg += "Raktár megadása kötelező! \n";
            }
            if ($scope.orderRequest.CostCenter.Id == 0) {
                msg += "Költséghely megadása kötelező! \n";
            }
            if ($scope.orderRequest.OrderRequestStatus.Id == 0) {
                msg += "Státusz megadása kötelező! \n";
            }
            if ($scope.orderRequest.CostCenterPeriod.Id == "") {
                msg += "Raktár megadása kötelező! \n";
            }
            if ($scope.orderRequest.Customer.Id == 0) {
                msg += "Partner megadása kötelező";
            }
            if (msg == "") {
                return true;
            } else {
                toasterService.warningToast("Figyelem", msg);
                return false;
            }
        }

        // Mentés gomb
        $scope.saveButtonClick = function (id) {
            switch (id) {
                case 79:
                    $scope.orderRequest.OrderRequestStatus = { Id: 79, Name: "Visszautasított" }
                    break;
                case 80:
                    $scope.orderRequest.OrderRequestStatus = { Id: 80, Name: "Módosítandó" };
                    break;
                default:
                    $scope.orderRequest.OrderRequestStatus = { Id: 76, Name: "Igény leadása" };
                    break;
            }
            if (checkOrderRequest()) {
                if (confirm("Valóban menteni szeretne?")) {
                    if ($scope.isNewOrderRequest) {
                        OrderRequestService.CreateOrderRequest($scope.orderRequest).success(function () {
                            toasterService.successToast("", "Sikeres mentés!");
                            window.location.href = siteurl.orderrequest;
                        }).error(function (data) {
                            toasterService.errorFromServerToast(data);
                        });
                    } else {
                        OrderRequestService.UpdateOrderRequest({
                            orderRequest: $scope.orderRequest,
                            userId: Number($window.sessionStorage.getItem('userid'))
                        }).success(function () {
                            toasterService.successToast("", "Sikeres frissítés!");
                            window.location.href = siteurl.orderrequest;
                        }).error(function (data) {
                            toasterService.errorFromServerToast(data);
                        });
                    }
                }
            }
        };

        // Vissza gomb
        $scope.backButtonClick = function () {
            if (confirm("Valóban visszalép?")) {
                window.location.href = siteurl.orderrequest;
            }
        }

        //Változtathatja-e státuszt
        var canChangeStatus = function () {
            var temp = $window.sessionStorage.getItem('roleids').split(',');
            return Enumerable.From(temp)
            .Any(function (x) { return x == 32; });
        };

        // Raktár kiválasztására hívódó esemény,
        // Megnézzük, hogy tartozik-e hozzá költséghely
        $scope.loadCostCentersOfWarehouse = function () {
            $scope.costCenterList = new CostCentersDTO($scope.orderRequest.Warehouse.CostCenters);
            if ($scope.costCenterList.costCenters.length === 0) {
                toasterService.errorToast("Költséghely hiba", "A kiválasztott raktárhoz nem tartozik költséghely, így rendelési igény nem adható le ebből a raktárból!");
            } else {
                $scope.isCostCenterDisabled = false;
            }
        };


        var isRequestButtonVisible = function () {
            return !($scope.orderRequest.OrderRequestStatus.Id == 79 || $scope.orderRequest.OrderRequestStatus.Id == 77);
        };

        LoadOrderRequest(orderRequestId);
    }

    //DTOs
    function OrderRequestDtoForList(data) {
        var _this = this;
        var listElement = {};
        this.OrderRequestList = [];

        if (angular.isDefined(data) && data.Items.length !== 0) {
            angular.forEach(data.Items, function (value) {
                listElement = {
                    Id: value.Id || 0,
                    OrderRequestNumber: value.OrderRequestNumber || "",
                    CustomerName: value.CustomerName || "",
                    CostCenterName: value.CostCenterName || "",
                    WarehouseName: value.WarehouseName || "",
                    OrderPeriod: value.OrderRequestPeriod || "",
                    OrderStatusName: value.OrderStatusName || "",
                    OrderNetValue: value.OrderNetValue || 0,
                    LastModificationDate: value.LastModificationDate || "",
                    CostCenterOrderLimit: value.CostCenterOrderLimit || 0,
                    CostCenterBalance: value.CostCenterBalance || 0,
                    isSelected: false
                }
                if (listElement.OrderStatusName === "Visszautasítva") {
                    listElement.statusColour = "Delete";
                } else if (listElement.OrderStatusName === "Módosítandó") {
                    listElement.statusColour = "Warning";
                } else if (listElement.OrderStatusName === "Megrendelés alatt") {
                    listElement.statusColour = "Save";
                } else if (listElement.CostCenterBalance < 0) {
                    listElement.statusColour = "UnderBalance";
                } else {
                    listElement.statusColour = "Default";
                }
                _this.OrderRequestList.push(listElement);
            });
        }
    }

    function PartnersDTO(data) {
        var _this = this;
        this.partners = [];
        var listElement = {};

        if (angular.isDefined(data)) {
            angular.forEach(data, function (value) {
                listElement = {
                    Id: value.Id || 0,
                    Name: value.Name || "",
                    Address: value.Address || ""
                }
                _this.partners.push(listElement);
            });
        }
    }

    function CostCentersDTO(data) {
        var _this = this;
        var listElement = {};
        this.costCenters = [];

        if (angular.isDefined(data)) {
            angular.forEach(data, function (value) {
                listElement = {
                    Id: value.Id || 0,
                    Name: value.Name || "",
                    Note: value.Note || "",
                    OrderLimitList: value.OrderLimitList || null,
                    ParentId: value.ParentId || 0
                }
                _this.costCenters.push(listElement);
            });
        }
    }

    function WarehousesDTO(data) {
        var _this = this;
        var listElement = {};
        this.warehouses = [];

        if (angular.isDefined(data)) {
            angular.forEach(data, function (value) {
                listElement = {
                    Id: value.Id || 0,
                    Name: value.Name || "",
                    CostCenters: value.CostCenters || []
                }
                _this.warehouses.push(listElement);
            });
        }
    }

    function ProdtuctDTO(data) {
        this.ProductId = data.ProductId || 0;
        this.ProductName = data.ProductName || "";
        this.Quantity = data.Quantity || 1;
        this.QuantityType = data.QuantityType || "";
        this.NetPrice = data.NetPrice || null;
        this.Vat = data.Vat || null;
        this.GrossValue = data.GrossValue || null;
        this.ItemNumber = data.ItemNumber || "";
        this.ProductCategoryName = data.ProductCategoryName || "Nincs típus";
    }

    function OrderRequestDTO(data) {
        var _this = this;
        var listElement = {};
        this.Items = [];

        this.Id = data.Id || 0;
        this.Warehouse = data.Warehouse || { Id: 0, Name: "" };
        this.Customer = data.Customer || { Id: 0, Name: "" }
        this.CostCenter = data.CostCenter ||
            {
                Id: data.Id || 0,
                Name: data.Name || "",
                Note: data.Note || "",
                OrderLimitList: data.OrderLimitList || null,
                ParentId: data.ParentId || 0
            }
        this.CostCenterPeriod = data.CostCenterPeriod || "";
        this.OrderRequestStatus = data.OrderRequestStatus ||
        {
            Id: data.Id || 76,
            Name: data.Name || "Igény leadása",
            Level: data.Level || 0,
            Department: data.Department || "",
            Editable: data.Editable || false
        }
        this.LastModificationDate = data.LastModificationDate || "";

        this.Note = data.Note || "";
        this.OrderRequestNumber = data.OrderRequestNumber || "";
        this.CountedLimit = data.CountedLimit || 0;


        if (angular.isDefined(data.Items) && data.Items.length > 0) {
            angular.forEach(data.Items, function (value) {
                listElement = new ProdtuctDTO(value);
                _this.Items.push(listElement);
            });
        }
    }

})();
