(function () {
    angular.module('IMasterApp')
        .controller('CostCenterController', CostCenterController)
        .controller('EditCostCenterController', EditCostCenterController);

    CostCenterController.$inject = ['$scope', '$http', 'siteurl', 'apiurl', 'toasterService'];
    function CostCenterController($scope, $http, siteurl, apiurl, toasterService) {
        var actualCostCenter = null;
        $scope.search = {
            Name: ""
        };
        var isSearch = false;
        var doing_GetAllCostCenter = false;
        $scope.doing_async = false;

        var loadCostCenters = function () {
            $scope.treeArray = [];
            $scope.my_tree = tree = {
            };
            $scope.costCenterList = [];
            doing_GetAllCostCenter = true;

            $http.get(apiurl.costCenter.getAllCostCenter + $scope.search.Name).success(function (data) {
                $scope.costCenterList = data;
                //Rendezés
                var ccList = [];
                angular.forEach(data, function (cc) {
                    if (ccList.length == 0 || cc.ParentId == null) {
                        ccList.push(cc);
                    } else {
                        var hadReplace = false;
                        angular.forEach(ccList, function (scc) {
                            if (cc.Id == scc.ParentId) {
                                var temporary = {
                                    Id: scc.Id, ParentId: scc.ParentId, Name: scc.Name, Note: scc.Note
                                };
                                scc.Id = cc.Id;
                                scc.ParentId = cc.ParentId;
                                scc.Name = cc.Name;
                                scc.Note = cc.Note;
                                ccList.push(temporary);
                                hadReplace = true;
                            }
                        });

                        if (!hadReplace) {
                            ccList.push(cc);
                        }
                    }
                });

                $scope.costCenterList = ccList;

                $scope.costCenterList.length > 0 ? $scope.zeroCategories = false : $scope.zeroCategories = true;

                $scope.doing_async = true
                doing_GetAllCostCenter = false;

                var costOutIndex = 0;
                var costInIndex = [];
                angular.forEach($scope.costCenterList, function (costCenter) {
                    if (isSearch == false) {
                        if (costCenter.ParentId == null) {
                            costOutIndex++;
                            costInIndex.push({ ParentId: costCenter.Id, ParentIdex: costOutIndex, Children: 0 });
                            costCenter.LevelIdentifier = costOutIndex + '. ';
                            $scope.treeArray.push({ label: costOutIndex + '. ' + costCenter.Name, data: { description: costCenter.Id }, children: [] });
                        } else {
                            find($scope.treeArray, costCenter.ParentId, costCenter);
                        }
                    } else {
                        if (costCenter.ParentId == null) {
                            costOutIndex++;
                            costInIndex.push({ ParentId: costCenter.Id, ParentIdex: costOutIndex, Children: 0 });
                            costCenter.LevelIdentifier = costOutIndex + '. ';
                            $scope.treeArray.push({ label: costOutIndex + '. ' + costCenter.Name, data: { description: costCenter.Id }, children: [] });
                        } else {
                            if ($scope.treeArray.length > 0) {
                                for (var i = 0; i < $scope.treeArray.length; i++) {
                                    if ($scope.treeArray[i].children.length > 0) {
                                        findOfElementToSearch($scope.treeArray[i].children, costCenter); //meghívok egy rekurzív metódust, hogy a vizsgált költséghely szülője megtalálható-e a fa i.elemének gyerekeiben
                                    }

                                    if ($scope.treeArray[i].data.description == costCenter.ParentId) {
                                        costCenter.isMatchParent = true;
                                    }
                                }
                            }

                            if (costCenter.isMatchParent == null) { //Nem található, hozzá kell adni
                                costOutIndex++;
                                costInIndex.push({ ParentId: costCenter.Id, ParentIdex: costOutIndex, Children: 0 });
                                costCenter.LevelIdentifier = costOutIndex + '. ';
                                $scope.treeArray.push({ label: costOutIndex + '. ' + costCenter.Name, data: { description: costCenter.Id }, children: [] });
                            } else {
                                find($scope.treeArray, costCenter.ParentId, costCenter);
                            }
                        }
                    }
                });

                function findOfElementToSearch(childrenArray, costCenter) {
                    if (typeof childrenArray != 'undefined') {
                        for (var i = 0; i < childrenArray.length; i++) {
                            if (childrenArray[i].data.description == costCenter.ParentId) {
                                costCenter.isMatchParent = true;
                                return;
                            }
                            findOfElementToSearch(childrenArray[i].children, costCenter);
                        }
                    }
                }

                $scope.doing_async = false;

                $scope.my_tree_handler = function (branch) {
                    angular.forEach($scope.costCenterList, function (costCenter) {
                        if (costCenter.LevelIdentifier + costCenter.Name === branch.label) {
                            actualCostCenter = costCenter;
                        }
                    });
                };

                function find(array, id, costCenter) {
                    if (typeof array != 'undefined') {
                        if (array.length > 0) {
                            for (var i = 0; i < array.length; i++) {
                                if (array[i].data.description == id) {
                                    var parentIndex = 0;
                                    var chidlIndex = 0;
                                    angular.forEach(costInIndex, function (cI) {
                                        if (cI.ParentId == id) {
                                            parentIndex = cI.ParentIdex;
                                            chidlIndex = cI.Children + 1;
                                            cI.Children++;
                                        }
                                    });
                                    costCenter.LevelIdentifier = parentIndex + '.' + chidlIndex + '. ';
                                    array[i].children.push({ label: parentIndex + '.' + chidlIndex + '. ' + costCenter.Name, data: { description: costCenter.Id }, children: [] });
                                    costInIndex.push({ ParentId: costCenter.Id, ParentIdex: parentIndex + '.' + chidlIndex, Children: 0 });
                                    return;
                                }
                                find(array[i].children, id, costCenter);
                            }
                        }
                    }
                }
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        }

        loadCostCenters();

        $scope.EditChoosedCostCenter = function () {
            if (actualCostCenter == null) {
                toasterService.warningToast("Figyelem", "Nem választott ki költséghelyet!");
            } else {
                window.location.href = siteurl.editcostcenter + "/" + actualCostCenter.Id;
            }
        };

        $scope.AddCostCenter = function () {
            window.location.href = siteurl.editcostcenter + "/0";
        };


        // Kereső szöveg beírása és adatlekérés
        $scope.searchQuery = function () {
            if ($scope.search.Name.length > 2) {
                isSearch = true;
                //Lekérés
                if (doing_GetAllCostCenter == false && $scope.doing_async == false) {
                    loadCostCenters();
                }
            }
            if ($scope.search.Name.length === 0) {
                isSearch = false;
                //Lekérés
                if (doing_GetAllCostCenter == false && $scope.doing_async == false) {
                    loadCostCenters();
                }
            }
        }

        $scope.DeleteCostCenterById = function () {
            if (actualCostCenter == null) {
                toasterService.warningToast("Figyelem", "Nem választott ki költséghelyet!");
            } else {
                $http.delete(apiurl.costCenter.deleteCostCenterById + actualCostCenter.Id).success(function (data) {
                    if ($scope.search.Name == actualCostCenter.Name) {
                        $scope.search.Name = "";
                        isSearch = false;
                    }
                    loadCostCenters();
                    actualCostCenter = null;
                    toasterService.successToast("", "Sikeres törlés!")
                }).error(function (data) {
                    toasterService.errorFromServerToast(data);
                });
            }
        }
    }

    EditCostCenterController.$inject = ['$scope', '$http', 'siteurl', 'apiurl', '$filter', '$location', 'toasterService'];
    function EditCostCenterController($scope, $http, siteurl, apiurl, $filter, $location, toasterService) {

        //Init
        var costCenterId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        $scope.actCost = "";
        $scope.newCostCenter = false;
        $scope.orderLimitList = [];

        var getCurrenciesList = function () {
            $http.get(apiurl.costCenter.getCurrenciesListUrl).success(function (data) {
                $scope.currencies = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };

        getCurrenciesList(); //Valuták letöltése

        if (costCenterId === "0") {
            $scope.newCostCenter = true;
            $scope.Title = "Új költséghely létrehozása";
        } else {
            $scope.Title = "Költséghely szerkesztése";
            // Lekérjük az adott költséghelyhez tartozó adatokat
            $http.get(apiurl.costCenter.getCostCenter + costCenterId).success(function (data) {
                $scope.CostCenter = data;
                // Ha van szülője, akkor lekérjük a szülőjét
                if ($scope.CostCenter.ParentId !== null) {
                    $http.get(apiurl.costCenter.getCostCenter + $scope.CostCenter.ParentId).success(function (data2) {
                        $scope.actCost = data2;
                        // A limitek lekérése
                        $scope.getTotalLimitOfAlCost($scope.actCost.Id);
                    });
                } else {
                    $scope.getTotalLimitOfAlCost(costCenterId);
                }

                $scope.orderLimitList = angular.copy($scope.CostCenter.OrderLimitList);

                //Limiteknél a pénznem beállítása
                angular.forEach($scope.orderLimitList, function (value, key) {
                    angular.forEach($scope.currencies, function (value2) {
                        if (value2.Id == value.CurrencyId) {
                            $scope.orderLimitList[key].currency = value2;
                        }
                    });
                });
            });
        }

        $scope.getCostCenterByName = function (costCenterName) {
            if (angular.isString(costCenterName) && costCenterName.length < 3) {
                $scope.isVisibleOrNot = "followingBallsG";
                return [];
            } else {
                return $http.get(apiurl.costCenter.getAllCostCenter + costCenterName).then(function (data) {
                    $scope.isVisibleOrNot = "followingBallsGHidden";
                    return data.data;
                });
            }
        };

        $scope.checkParent = function () {
            if ($scope.actCost.Id == $scope.CostCenter.Id) {
                toasterService.warningToast("Figyelem", "Nem lehet a költséghely önmaga szülője!");
                $scope.actCost = '';
            } else {
                $scope.CostCenter.ParentId = $scope.actCost.Id;
                $scope.getTotalLimitOfAlCost($scope.actCost.Id);
            }
        }


        $scope.backButton = function () {
            if (confirm('Valóban vissza akar lépni?')) {
                window.location.href = siteurl.costcenter;
            }
        }

        $scope.saveButton = function () {
            if (angular.isUndefined($scope.actCost)) {
                toasterService.warningToast("Figyelem", "Rosszul választotta ki a szülő költséghelyet!");
                return;
            }

            if ($scope.orderLimitList != null && $scope.orderLimitList.length > 0) {
                var error = false;
                angular.forEach($scope.orderLimitList, function (value, key) {
                    if (value.Visibility == true) {
                        error = true;
                    }
                });
                if (error == true) {
                    toasterService.warningToast("Figyelem", "A költséghelyhez hozzá van rendelve olyan limit, ami még nincs elmentve!");
                    return;
                }
            }

            $scope.CostCenter.OrderLimitList = angular.copy($scope.orderLimitList); //limitek hozzákapcsolása

            if (confirm("Valóban menteni szeretne?")) {
                $scope.CostCenter.ParentId = $scope.actCost.Id;
                if ($scope.newCostCenter) {
                    $http.post(apiurl.costCenter.createCostCenter, $scope.CostCenter).success(function () {
                        toasterService.saveSuccess();
                        window.location.href = siteurl.costcenter;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                } else {
                    $http.put(apiurl.costCenter.updateCostCenter, $scope.CostCenter).success(function (data) {
                        toasterService.saveSuccess();
                        window.location.href = siteurl.costcenter;
                    }).error(function (data) {
                        toasterService.errorFromServerToast(data);
                    });
                }
            }
        };

        //OrderLimit paramétereinek ellenőrzése
        var checkOrderLimit = function (orderLimit) {
            var error = false;

            if (orderLimit.OrderLimit == 0) {
                toasterService.warningToast("Figyelem", "Nem adta meg a limit értékét!");
                return false;
            }

            if (orderLimit.CurrencyId == '' || orderLimit.CurrencyId == 0) {
                toasterService.warningToast("Figyelem", "Nem választott ki pénznemet!");
                return false;
            }

            if (orderLimit.OrderLimit < 0) {
                toasterService.warningToast("Figyelem", "Limit értéke nem lehet negatív!");
                return false;
            }

            if ($scope.orderLimitList != null && $scope.orderLimitList.length > 0) {
                var count = 0;
                angular.forEach($scope.orderLimitList, function (value, key) {
                    if (value.CurrencyId == orderLimit.CurrencyId) {
                        count++;
                    }
                });
                if (count > 1) {
                    error = true;
                }
            }

            if (error) {
                toasterService.warningToast("Figyelem", "A kiválasztott pénznemben már van limit hozzárendelve a költséghelyhez!");
                return false;
            }

            if ($scope.actCost != null && $scope.actCost.Id != null) {
                var sumTotalLimitOfParentCost = 0; //Az aktuális pénznemhez tartozó szülő költséghely limit összege
                var sumTotalLimitOfAllCost = 0; //Aktuális pénznemhez tartozó alköltséghelyek summázott limitjeinek összege
                var sumTotalLimitOfCurrentCost = 0; //Az aktuális pénznemhez tartozó aktuális költséghely limit összege

                angular.forEach($scope.actCost.OrderLimitList, function (value, key) {
                    if (orderLimit.CurrencyId == value.CurrencyId) {
                        sumTotalLimitOfParentCost += value.OrderLimit;
                    }
                });

                angular.forEach($scope.CostCenter.OrderLimitList, function (value, key) {
                    if (orderLimit.CurrencyId == value.CurrencyId) {
                        sumTotalLimitOfCurrentCost += value.OrderLimit;
                    }
                });
                if (!angular.isUndefined($scope.allCostOfTotalLimitsFromCapitalCost) && $scope.allCostOfTotalLimitsFromCapitalCost.length > 0) {
                    angular.forEach($scope.allCostOfTotalLimitsFromCapitalCost, function (value, key) {
                        if (orderLimit.CurrencyId == value.CurrencyId) {
                            sumTotalLimitOfAllCost += value.TotalLimit;
                        }
                    });
                }

                if ((sumTotalLimitOfParentCost - (sumTotalLimitOfAllCost - sumTotalLimitOfCurrentCost)) < orderLimit.OrderLimit) {
                    toasterService.warningToast("Figyelem", "Az alköltséghelyek limit értéke nem haladhatja meg a főköltséghely limit értékét!\nBeállítható max limit érték: " +
                    (sumTotalLimitOfParentCost - (sumTotalLimitOfAllCost - sumTotalLimitOfCurrentCost)));
                    return false;
                }
            }

            if ($scope.newCostCenter == false) {
                if ($scope.CostCenter.ParentId == null) {
                    if (!angular.isUndefined($scope.allCostOfTotalLimitsFromCapitalCost) && $scope.allCostOfTotalLimitsFromCapitalCost.length > 0) {
                        sumTotalLimitOfAllCost = 0; //Aktuális pénznemhez tartozó alköltséghelyek summázott limitjeinek összege
                        angular.forEach($scope.allCostOfTotalLimitsFromCapitalCost, function (value, key) {
                            if (orderLimit.CurrencyId == value.CurrencyId) {
                                sumTotalLimitOfAllCost += value.TotalLimit;
                            }
                        });

                        if (orderLimit.OrderLimit < sumTotalLimitOfAllCost) {
                            toasterService.warningToast("Figyelem", "A főköltséghely limit értéke nem lehet kisebb az alköltséghelyeihez tartozó szummázott limit értékénél!\nBeállítható max csökkentett limit érték: " +
                                sumTotalLimitOfAllCost);
                            return false;
                        }
                    }
                }
            }

            return true;
        };

        //Limitek kezelése
        $scope.addOrderLimit = function () {
            var newOrderLimit = { OrderLimit: Number(0).toFixed(2), CurrencyId: $scope.currencies[0].Id, currency: $scope.currencies[0] };
            $scope.orderLimitList.push(newOrderLimit);
            newOrderLimit.Visibility = true;
        };

        //Költséghelyhez hozzárendelt limit szerkesztése
        $scope.editOrderLimit = function (orderLimit) {
            orderLimit.Visibility = true;
        }

        //Költséghelyhez hozzárendelt limit törlése
        $scope.deleteOrderLimit = function (orderLimit, index) {
            if (!confirm('Valóban törölni szeretnéd a költséghelyhez tartozó limitet?')) {
                return;
            }

            $scope.orderLimitList.splice(index, 1);
        };

        //Költséghelyhez limitek hozzáadása
        $scope.saveOrderLimit = function (orderLimit, currency) {
            orderLimit.CurrencyId = currency.Id;
            //Kerekítés
            if (orderLimit.CurrencyId == 1) { //HUF
                orderLimit.OrderLimit = Number(orderLimit.OrderLimit).toFixed(2);
            } else {
                orderLimit.OrderLimit = Number(orderLimit.OrderLimit).toFixed(4);
            }

            if (checkOrderLimit(orderLimit)) {
                orderLimit.Visibility = false;
            } else {
                //Ez akkor szükséges ha figyelmeztetés esetén beakarjuk zárni a kártya ablakot és ki is akarjuk törölni a hozzáadott limitet
                //var index = $scope.orderLimitList.length;
                //while (index > 0) {
                //    index--;
                //    if ($scope.orderLimitList[index].OrderLimit != null && $scope.orderLimitList[index].CurrencyId != null) {
                //        if ($scope.orderLimitList[index].OrderLimit == orderLimit.OrderLimit && $scope.orderLimitList[index].CurrencyId == orderLimit.CurrencyId) {
                //            $scope.orderLimitList.splice(index, 1);
                //        }
                //    }
                //}
                orderLimit.Visibility = true;
            }
        }

        $scope.getTotalLimitOfAlCost = function (costCenterId) {
            //Szülő alköltséghelyeinél beállított limit érték összegének lekérése
            $http.get(apiurl.costCenter.getTotalLimitsByCostCenterIdUrl + costCenterId).success(function (data) {
                $scope.allCostOfTotalLimitsFromCapitalCost = data;
            }).error(function (data) {
                toasterService.errorFromServerToast(data);
            });
        };
    }

})();
