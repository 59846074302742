(function () {
    angular.module('IMaster', ['urlFactory'])
        .constant('AUTH_EVENTS', {
            loginSuccess: 'auth-login-success',
            loginFailed: 'auth-login-failed',
            logoutSuccess: 'auth-logout-success',
            sessionTimeout: 'auth-session-timeout',
            notAuthenticated: 'auth-not-authenticated',
            notAuthorized: 'auth-not-authorized'
        })
        .service('Session', function () {
            this.create = function (Id, Username) {
                this.Id = Id;
                this.Username = Username;
            };
            this.destroy = function () {
                this.Id = null;
                this.Username = null;
            };
            return this;
        });

    // Main application module
    angular.module('IMasterApp', [
        'ui.bootstrap', 'IMaster', 'snap', 'Filters',
        'ngTagsInput', 'angularBootstrapNavTree', 'ngAnimate',
        'angular-bootstrap-select', 'ngSanitize', 'ngStorage',
        'localytics.directives', 'toaster', 'angularSpinner',
        'angularFileUpload', 'mgo-angular-wizard', 'treeGrid', 'nya.bootstrap.select',
        ApiServices.appModule.name,
        'delayed-change', 'CzeGabo',
        CommonParts.appModul.name,
        Worksheet.appModul.name,
        AssetDatasheet.appModul.name,
        Counter.appModul.name,
        Contract.appModul.name,
        Nav.appModul.name,
        PartnerPriceListGroup.appModul.name,
        GroupBilling.appModul.name,
        ManufacturingCell.appModul.name,
        ManufacturingProduct.appModul.name,
        ManufacturingSchedule.appModul.name,
        RemoteDevice.appModule.name,
        Partners.appModule.name
    ])
        .service("toasterService", toasterService)
        .service('browserModalService', browserModalService)
        .controller('BrowserModalInstanceCtrl', BrowserModalInstanceCtrl)
        .directive('ngFocus', ngFocus)
        .directive('storeEnterKey', storeEnterKey)
        .directive('storeEscKey', storeEscKey)
        .directive('storeDecimalPoint', storeDecimalPoint)
        .filter('storeDecimalPoint', storeDecimalPointFilter)
        .directive('storeIntegerOnly', storeIntegerOnly)
        .directive('storeCharLimit', storeCharLimit)
        .directive('storeDatePicker', storeDatePicker)
        .directive('storeTimePicker', storeTimePicker)
        .directive('storeDateTimePicker', storeDateTimePicker)
        .filter('storeFormatAsTime', storeFormatAsTime)
        .controller('MainCtrl', MainCtrl)
        .controller('EditModalCtrl', EditModalCtrl)
        .controller('Account_controller', Account_controller)
        .controller('LoginController', LoginController)
        .config(['uibDatepickerConfig', 'uibDatepickerPopupConfig', function (datepickerConfig, datepickerPopupConfig) {
            datepickerConfig.formatDayTitle = 'yyyy. MMMM';
            datepickerConfig.startingDay = 1;

            // Ezek nem nyelvi fájlból jönnek
            datepickerPopupConfig.clearText = 'Töröl';
            datepickerPopupConfig.closeText = 'Bezár';
            datepickerPopupConfig.currentText = 'Ma';
        }])
        .run(['customSelectDefaults', function (customSelectDefaults) {
            customSelectDefaults.displayText = 'Kérem válasszon';
            customSelectDefaults.emptyListText = 'Nincs találat';
            customSelectDefaults.emptySearchResultText = '"$0" nem található';
            customSelectDefaults.addText = 'Hozzáadás';
        }]);

    toasterService.$inject = ['toaster'];
    function toasterService(toaster) {
        // Toaster sikeres eredményekhez
        function successToast(title, text) {
            return toaster.pop('success', title, text);
        }

        // Toaster hibás eredményekhez
        function errorToast(title, text) {
            return toaster.pop('error', title, text);
        }

        function saveSuccess() {
            return toaster.pop('success', 'Mentés', 'Sikeres mentés!');
        }

        //Serverről visszatérő hiba
        function errorFromServerToast(data) {
            if (typeof data === "string" || data instanceof String) {
                msg = data.length < 150 ? data : "Váratlan hiba történt!";
            } else {
                var msg = "";
                if (data.Message != undefined) {
                    if (data.Message.indexOf("Argument validation failed") > -1) {
                        for (var i = 0; i < data.Notifications.length; i++) {
                            msg += data.Notifications[i].Target + "\n";
                        }
                    } else {
                        msg = data.Message;
                    }
                } else {
                    msg = "Váratlan hiba történt!";
                }
            }
            return toaster.pop('error', "Sikertelen", msg);
        }

        // Toaster figyelmeztetésekhez
        function warningToast(title, text) {
            return toaster.pop('warning', title, text);
        }

        // Toaster egyéb üzenetekhez
        function noteToast(title, text) {
            return toaster.pop('note', title, text);
        }

        // Public methods
        return {
            successToast: successToast,
            errorToast: errorToast,
            warningToast: warningToast,
            noteToast: noteToast,
            errorFromServerToast: errorFromServerToast,
            saveSuccess: saveSuccess
        }
    }

    browserModalService.$inject = ['$uibModal', '$q'];
    function browserModalService($uibModal, $q) {
        var template = '<div class="modal-body">' +
            '    <p style="font-size:1.3em;margin:1em;" ng-bind-html="message"></p>' +
            '</div>' +
            '<div class="modal-footer">' +
            '    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>' +
            '    <button class="btn btn-warning" type="button" ng-click="cancel()" ng-if="hasCancelButton">Mégse</button>' +
            '</div>';

        this.alert = function (message) {
            return createModal(message, false);
        };

        this.confirm = function (message) {
            return createModal(message, true);
        };

        /**
         * Modal ablak feldobása a felhasználónak
         * 
         * @param {string} message Üzenet szövege
         * @param {boolean} hasCancelButton Mégse gombot meg kell-e jeleníteni
         */
        function createModal(message, hasCancelButton) {
            message = message.replace('\n', '<br>');

            var modalInstance = $uibModal.open({
                template: template,
                windowClass: 'vertical-center',
                controller: 'BrowserModalInstanceCtrl',
                resolve: {
                    hasCancelButton: function () {
                        return hasCancelButton;
                    },
                    message: function () {
                        return message;
                    }
                }
            });

            return modalInstance.result.catch(function (reason) {
                return $q.when(false);
            });
        }
    }

    BrowserModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance', 'message', 'hasCancelButton'];
    function BrowserModalInstanceCtrl($scope, $uibModalInstance, message, hasCancelButton) {
        $scope.message = message;
        $scope.hasCancelButton = hasCancelButton;

        $scope.ok = function () {
            $uibModalInstance.close(true);
        };

        $scope.cancel = function () {
            $uibModalInstance.close(false);
        };
    }

    ngFocus.$inject = ['$timeout'];
    function ngFocus($timeout) {
        return {
            link: function (scope, element, attrs) {
                scope.$watch(attrs.ngFocus, function (val) {
                    if (angular.isDefined(val) && val) {
                        $timeout(function () { element[0].focus(); });
                    }
                }, true);
            }
        };
    }

    function storeEnterKey() {
        // http://stackoverflow.com/a/17472118
        return function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.storeEnterKey);
                    });

                    event.preventDefault();
                }
            });
        };
    }

    function storeEscKey() {
        // http://stackoverflow.com/a/17472118
        return function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 27) {
                    scope.$apply(function () {
                        scope.$eval(attrs.storeEscKey);
                    });

                    event.preventDefault();
                }
            });
        };
    }

    function storeDecimalPoint() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                ngModelCtrl.$formatters.push(function (value) {
                    if (value) {
                        return value.toString().replace('.', ',');
                    }
                });
                ngModelCtrl.$parsers.push(function (value) {
                    if (value) {
                        return value.toString().replace(',', '.');
                    }
                });
                scope.$watch(attrs.ngModel, function (value) {
                    if (value) {
                        element.val(value.toString().replace('.', ','));
                    }
                });
            }
        };
    }

    function storeDecimalPointFilter() {
        return function (value) {
            if (value && !isNaN(value)) {
                if (value.toString().indexOf('.') !== -1) {
                    return parseFloat(value.toString()).toFixed(2).replace('.', ',');
                }
            }

            return !isNaN(value) ? value : '-';
        };
    }

    function storeIntegerOnly() {
        // http://stackoverflow.com/a/15556249
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text) {
                    if (text) {
                        var transformedInput = text.replace(/[^0-9]/g, '');
                        if (transformedInput !== text) {
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                        }
                        return transformedInput; // or return Number(transformedInput)
                    }
                    return text;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    }

    function storeCharLimit() {
        // https://github.com/igreulich/angular-charlimit
        // http://jsfiddle.net/ksevksev/J7MJF/
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attributes, $ctrl) {
                $attributes.$set('ngTrim', 'false');
                var maxlength = parseInt($attributes.storeCharLimit, 10);

                $ctrl.$parsers.push(function (value) {
                    if (value) {
                        $element.toggleClass('textarea-char-limit-warning', (maxlength - value.length <= 10) && (value.length < maxlength));
                        $element.toggleClass('textarea-char-limit-error', value.length >= maxlength);

                        if (value.length > maxlength) {
                            value = value.substr(0, maxlength);
                            $ctrl.$setViewValue(value);
                            $ctrl.$render();
                        }
                    } else {
                        $element.removeClass('textarea-char-limit-warning textarea-char-limit-error');
                    }

                    return value;
                });
            }
        };
    }

    function storeDatePicker() {
        function template(tElement, tAttrs) {
            var text = '<input class="form-control' + (tAttrs['isSmall'] && tAttrs['isSmall'] === 'true' ? ' input-sm' : '') + '" ng-disabled="ctrl.ngDisabled" ng-model="ctrl.date" ng-change="ctrl.changed()" ng-click="ctrl.toggleOpen($event)" uib-datepicker-popup="{{ctrl.format}}" datepicker-options="ctrl.options" is-open="ctrl.isOpen" datepicker-append-to-body="true" />\n';
            if (tAttrs['hasButton'] && tAttrs['hasButton'] === 'true') {
                text = '<div class="input-group' + (tAttrs['isSmall'] && tAttrs['isSmall'] === 'true' ? ' input-group-sm' : '') + '">\n'
                    + '<input class="form-control" ng-disabled="ctrl.ngDisabled" ng-model="ctrl.date" ng-change="ctrl.changed()" uib-datepicker-popup="{{ctrl.format}}" datepicker-options="ctrl.options" is-open="ctrl.isOpen" datepicker-append-to-body="true" />\n'
                    + '<div class="input-group-btn">\n'
                    + '<button class="btn btn-default " type="button" ng-disabled="ctrl.ngDisabled" ng-click="ctrl.toggleOpen($event)"><span class="glyphicon glyphicon-calendar"></span></button>\n'
                    + '</div>\n'
                    + '</div>\n';
            }
            return text;
        }

        function link(scope, element, attrs, ctrls) {
            element.removeClass();
            var storeDatePickerCtrl = ctrls[0];
            var ngModelCtrl = ctrls[1];

            storeDatePickerCtrl.init(ngModelCtrl);
        }

        ctrl.$inject = ['$element', '$attrs', '$filter'];
        function ctrl($element, $attrs, $filter) {
            var _this = this;
            _this.isOpen = false;

            if (!_this.options) {
                _this.options = {};
            }

            if (!_this.format) {
                // localized view format
                _this.format = 'yyyy.MM.dd.';
            }

            if (!_this.ngDisabled) {
                _this.ngDisabled = false;
            }

            //------------------------
            _this.changed = function () {
                _this.ngModel.$setViewValue($filter('date')(_this.date, 'yyyy-MM-dd'));
            };

            _this.init = function (_ngModel) {
                _this.ngModel = _ngModel;

                _this.ngModel.$formatters.push(formatter);
            };

            _this.toggleOpen = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                _this.isOpen = !_this.isOpen;
            };

            function formatter(value) {
                if (value) {
                    _this.date = convertDate(value);
                    _this.changed();
                }
            }
        }

        function convertDate(dateString) {
            if (!dateString) {
                return null;
            }
            var date = new Date(dateString);
            if (date.toString() === 'Invalid date') {
                date = new Date();
            }

            return date;
        }

        return {
            restrict: 'E',
            require: ['storeDatePicker', 'ngModel'],
            scope: {
                format: '@',
                hasButton: '@',
                isSmall: '@',
                ngDisabled: '=?',
                options: '=?',
            },
            template: template,
            link: link,
            controller: ctrl,
            controllerAs: 'ctrl',
            bindToController: true,
        };
    }

    function storeTimePicker() {
        function template(tElement, tAttrs) {
            var text = '<uib-timepicker show-meridian="false" show-spinners="false" ng-model="ctrl.date" ng-change="ctrl.changed()"></uib-timepicker>\n';
            return text;
        }

        function link(scope, element, attrs, ctrls) {
            var storeTimePickerCtrl = ctrls[0];
            var ngModelCtrl = ctrls[1];

            storeTimePickerCtrl.init(ngModelCtrl);
        }

        ctrl.$inject = ['$element', '$attrs', '$filter'];
        function ctrl($element, $attrs, $filter) {
            var _this = this;

            if (angular.isUndefined(_this.ngDisabled)) {
                _this.disabled = false;
            }

            //------------------------
            _this.changed = function () {
                _this.ngModel.$setViewValue($filter('date')(_this.date, 'HH:mm:ss'));
            };

            _this.init = function (_ngModel) {
                _this.ngModel = _ngModel;
                _this.ngModel.$formatters.push(formatter);
            };

            function formatter(value) {
                if (value) {
                    _this.date = convertDate(value);
                    _this.changed();
                }
            }
        }

        function convertDate(dateString) {
            if (!dateString) {
                return null;
            }
            var date = new Date('1970-01-01T' + dateString);
            if (date.toString() === 'Invalid date') {
                date = new Date();
            }

            return date;
        }

        return {
            restrict: 'E',
            require: ['storeTimePicker', 'ngModel'],
            scope: {
                ngDisabled: '=',
            },
            template: template,
            link: link,
            controller: ctrl,
            controllerAs: 'ctrl',
            bindToController: true,
        };
    }

    function storeDateTimePicker() {
        function template(tElement, tAttrs) {
            var text = '<div style="float: right;"><uib-timepicker show-meridian="false" show-spinners="false" ng-disabled="ctrl.ngDisabled" ng-model="ctrl.date" ng-change="ctrl.changed()"></uib-timepicker></div>\n';

            var date = '<input class="form-control" ng-disabled="ctrl.ngDisabled" ng-model="ctrl.date" uib-datepicker-popup="{{ctrl.format}}" is-open="ctrl.isOpen" ng-change="ctrl.changed()" ng-click="ctrl.toggleOpen($event)" />\n';
            if (tAttrs['hasButton'] && tAttrs['hasButton'] === 'true') {
                date = '<div class="input-group">\n'
                    + '<input class="form-control" ng-disabled="ctrl.ngDisabled" ng-model="ctrl.date" uib-datepicker-popup="{{ctrl.format}}" is-open="ctrl.isOpen" ng-change="ctrl.changed()" />\n'
                    + '<div class="input-group-btn">\n'
                    + '<button class="btn btn-default" type="button" ng-disabled="ctrl.ngDisabled" ng-click="ctrl.toggleOpen($event)"><span class="glyphicon glyphicon-calendar"></span></button>\n'
                    + '</div>\n'
                    + '</div>\n';
            }

            text += '<div style="padding: 0 118px 0 0;">' + date + '</div>';

            return text;
        }

        function link(scope, element, attrs, ctrls) {
            var storeDatePickerCtrl = ctrls[0];
            var ngModelCtrl = ctrls[1];

            storeDatePickerCtrl.init(ngModelCtrl);
        }

        ctrl.$inject = ['$element', '$attrs', '$filter'];
        function ctrl($element, $attrs, $filter) {
            var _this = this;
            _this.isOpen = false;

            if (!_this.format) {
                // localized view format
                _this.format = 'yyyy.MM.dd.';
            }

            if (!_this.ngDisabled) {
                _this.disabled = false;
            }

            //------------------------
            _this.changed = function () {
                _this.ngModel.$setViewValue($filter('date')(_this.date, 'yyyy-MM-ddTHH:mm:ss'));
            };

            _this.init = function (_ngModel) {
                _this.ngModel = _ngModel;

                _this.ngModel.$formatters.push(formatter);
            };

            _this.toggleOpen = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                _this.isOpen = !_this.isOpen;
            };

            function formatter(value) {
                if (value) {
                    _this.date = convertDate(value);
                }
            }
        }

        function convertDate(dateString) {
            if (!dateString) {
                return null;
            }
            var date = new Date(dateString);
            if (date.toString() === 'Invalid date') {
                date = new Date();
            }

            return date;
        }

        return {
            restrict: 'E',
            require: ['storeDateTimePicker', 'ngModel'],
            scope: {
                format: '@',
                hasButton: '@',
                ngDisabled: '=',
            },
            template: template,
            link: link,
            controller: ctrl,
            controllerAs: 'ctrl',
            bindToController: true,
        };
    }

    function storeFormatAsTime() {
        return function (value) {
            if (!isNaN(value) && value >= 0) {
                var hours = Math.floor(value / 60);
                var minutes = value % 60;

                return (hours.toString().length === 1 ? '0' + hours : hours) + ':' + (minutes.toString().length === 1 ? '0' + minutes : minutes);
            }

            return value;
        };
    }

    MainCtrl.$inject = ['$scope', '$rootScope', '$uibModal', 'usSpinnerService'];
    function MainCtrl($scope, $rootScope, $uibModal, usSpinnerService) {
        // Snap set
        $scope.snapOpts = {
            disable: 'right'
        };

        // Spinner loader set
        usSpinnerService.stop('headerLoader');

        $scope.NewPartnerModal = function () {
            $rootScope.isFromModal = true;
            var modalInstance = $uibModal.open({
                templateUrl: '/Home/EditPartnerTemplate',
                controller: 'EditModalCtrl',
                size: 'lg',
                windowClass: 'modal_extlg',
            });
        }
        $rootScope.isFromModal = false;
        $scope.NewProductModal = function () {
            $rootScope.isFromModal = true;
            var modalInstance = $uibModal.open({
                templateUrl: '/Home/EditProductTemplate',
                controller: 'EditModalCtrl',
                size: 'lg',
                windowClass: 'modal_extlg',
            });
        };
    }

    EditModalCtrl.$inject = ['$scope', '$uibModalInstance'];
    function EditModalCtrl($scope, $uibModalInstance) {
        $scope.$uibModalInstance = $uibModalInstance;
    }

    Account_controller.$inject = ['$rootScope', '$window', '$scope', 'siteurl'];
    function Account_controller($rootScope, $window, $scope, siteurl) {
        /**** menu bind ****/
        $scope.siteurl = siteurl;
        /*******************/

        $scope.initRoles = function () {
            var wid = window.location.href.substring(window.location.href.lastIndexOf("/"));
            if (!((wid == "/Index") || (wid == "/"))) {
                if ($window.sessionStorage.getItem('userid') == null) {
                    window.location.href = siteurl.index;
                }
            }

            if ($scope.ApplicantUser == null) {
                $scope.ApplicantUser =
                    {
                        Username: $window.sessionStorage.getItem('username')
                    };
                var userID = $window.sessionStorage.getItem('userid');
                $scope.roles = $window.sessionStorage.getItem('roleids');
                if ($scope.roles != null) {
                    $scope.roles = $scope.roles.split(',');
                    $scope.claims = [];
                    for (var i = 0; i < $scope.roles.length; i++) {
                        var v = parseInt($scope.roles[i]) - 1;
                        $scope.claims[v] = true;
                    }
                }
                $rootScope.claims = $scope.claims;
            }

            $scope.loggedUser = function () {
                if (userID == null) {
                    return false;
                } else {
                    return true;
                }
            };
        }

        $scope.logout = function () {
            //$window.sessionStorage.removeItem('token');
            $window.sessionStorage.removeItem('username');
            $window.sessionStorage.removeItem('userid');
            $window.sessionStorage.removeItem('roleids');
            window.location.href = siteurl.index;
        };
    }

    LoginController.$inject = ['$window', '$scope', '$rootScope', 'AUTH_EVENTS', 'Session', '$http', 'siteurl', 'apiurl'];
    function LoginController($window, $scope, $rootScope, AUTH_EVENTS, Session, $http, siteurl, apiurl) {
        if ($window.sessionStorage.getItem('username') != null) {
            window.location.href = siteurl.home;
        }

        $scope.LoginDto = {
            Username: '',
            Password: ''
        };

        $scope.Login = function (LoginDto) {
            var loginData = angular.copy($scope.LoginDto);
            loginData.Password = Sha1.hash(loginData.Password);
            $http.post(apiurl.user.login, loginData).success(function (data) {
                Session.create(data.Id, data.Username);
                $window.sessionStorage.setItem('username', data.Username);
                $window.sessionStorage.setItem('userid', data.Id);
                $window.sessionStorage.setItem('roleids', data.RolesIds);
                $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                window.location.href = siteurl.home;
            }).error(function (data) {
                alert(data.Message);
                $scope.LoginDto = {
                    Username: '',
                    Password: ''
                };
            });
        };
    }

})();
