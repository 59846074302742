(function () {

    angular.module('IMasterApp')
        .controller('ContactInteractionCtrl', ContactInteractionCtrl)
        .controller('EditContactInteraction', EditContactInteraction);

    ContactInteractionCtrl.$inject = ['$scope', '$http', '$location', 'siteurl', 'apiurl', 'toasterService'];
    function ContactInteractionCtrl($scope, $http, $location, siteurl, apiurl, toasterService) {
        var partnerId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

        if (isNaN(partnerId)) {
            partnerId = "EditPartner";
        }

        $scope.siteurl = siteurl;
        $scope.actUrl = siteurl.editPartner;

        if (partnerId == 'EditPartner') {
            return;
        }

        $scope.currentPage = 0;
        $scope.pageSize = 25;
        $scope.actualPage = 0;
        $scope.selectedPageSize = 25;
        $scope.SearchObj = { sortIndex: "", orderDirection: true };

        //Szűrési dto összeállítása
        var createFilterContactInteraction = function () {
            return {
                sortIndex: $scope.SearchObj.sortIndex, // A rendezéshez szükséges oszlop neve
                orderDirection: $scope.SearchObj.orderDirection // A rendezéshez szükséges irány
            };
        }

        //Kapcsolatfelvételek letöltésének elkezdése
        $scope.loadAllContactInteraction = function (sortIndex) {
            if (!angular.isUndefined(sortIndex)) {
                if (sortIndex === $scope.SearchObj.sortIndex) {
                    $scope.SearchObj.orderDirection = !$scope.SearchObj.orderDirection;
                }
                else {
                    $scope.SearchObj.orderDirection = true;
                    $scope.SearchObj.sortIndex = sortIndex;
                }
            }

            //Szűrési dto összeállítása
            var filterContactInteractions = createFilterContactInteraction();

            loadAllContactInteractionFinish(filterContactInteractions);
        };

        //Kapcsolatfelvételek letöltésének befejezése
        var loadAllContactInteractionFinish = function (filterContactInteractions) {
            $http.post(apiurl.contactInteraction.allContactInteractionUrl + $scope.currentPage + '/' + $scope.pageSize + '/' + partnerId, filterContactInteractions).success(function (data) {
                $scope.ContactInteractions = data.ContactInteractionList;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        };

        //Interakció visszanyitása
        $scope.EditContactInteraction = function (index) {
            location.href = siteurl.editContactInteraction + "/" + index.Id;
        };

        //Új interakció hozzáadása
        $scope.NewContactInteraction = function () {
            location.href = siteurl.editContactInteraction + "/PartnerId" + partnerId;
        };

        //Email sablon létrehozása
        $scope.CreateEmailTemplate = function () {
            window.location.href = siteurl.editEmailTemplate + '?url=' + $scope.actUrl;
        };

        $scope.loadAllContactInteraction();

        //Kapcsolatfelvétel törlése
        $scope.deleteContactInteraction = function (index) {
            if (confirm("Valóban törölni akarja?")) {
                $http.delete(apiurl.contactInteraction.deleteContactInteractionUrl + index.Id).success(function () {
                    alert("A kapcsolatfelvétel sikeresen törölve!");
                    $scope.ContactInteractions.splice(index, 1);
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            }
        };

        //Kapcsolatfelvételek lapozás
        $scope.pageChanged = function () {
            $scope.currentPage = $scope.current - 1;
            $scope.loadAllContactInteraction();
        };

        //Kapcsolatfelvételek számának beállítása 1 oldalon
        $scope.changeSelected = function () {
            if ($scope.selectedPageSize > 0) {
                $scope.pageSize = $scope.selectedPageSize;
                $scope.currentPage = 0;
                $scope.loadAllContactInteraction();
            }
        };
    }

    EditContactInteraction.$inject = ['$scope', '$http', '$location', '$filter', 'siteurl', 'apiurl', 'toasterService'];
    function EditContactInteraction($scope, $http, $location, $filter, siteurl, apiurl, toasterService) {
        var cIdPos = window.location.href.indexOf("PartnerId");
        var urlId = "";
        $scope.siteurl = siteurl;
        $scope.actUrl = siteurl.editContactInteraction;

        //init
        $scope.disabledElement = false;
        $scope.contactInteractionData = {
            CompanyId: '',
            ContactId: '',
            TypeId: '',
            DateOfContact: '',
            NextDateOfRequest: '',
            ResultStatusId: '',
            Comment: ''
        };

        if (cIdPos != -1) {
            $scope.IsNewContactInteraction = true;
            $scope.ContactInteractionTitle = "Új kapcsolatfelvétel";
            urlId = window.location.href.substring(window.location.href.lastIndexOf("PartnerId") + 9);
            $scope.contactInteractionData.CompanyId = urlId;
        } else {
            $scope.IsNewContactInteraction = false;
            $scope.ContactInteractionTitle = "Kapcsolatfelvétel szerkesztése";
            urlId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        }

        //Email küldés
        $scope.sendEmail = function () {
            if ($scope.IsNewContactInteraction) {
                window.location.href = siteurl.sendEmail + '?contactInteractionId=' + urlId + '?url=' + $scope.actUrl;
            } else {
                window.location.href = siteurl.sendEmail + '?url=' + $scope.actUrl;
            }
        };

        //Új kapcsolatfelvétel
        if ($scope.IsNewContactInteraction) {
            $http.get(apiurl.contactInteraction.getContactInteractionBaseDataUrl + urlId).success(function (data) {
                //interakció típusok
                $scope.contactInteractionTypes = data.ContactInteractionTypes;
                $scope.contactInteractionType = $scope.contactInteractionTypes[0];

                //interakció eredmények
                $scope.contactInteractionResultStatuses = data.ContactInteractionResultStatus;
                $scope.contactInteractionResultStatus = $scope.contactInteractionResultStatuses[0];

                //kapcsolattartók
                $scope.contactPersons = data.Contacts;
                $scope.contactPerson = $scope.contactPersons[0];
            });

            //kapcsolatfelvétel mentése
            $scope.save = function () {
                $scope.contactInteractionData.DateOfContact = $filter('date')($scope.datePickers.dateOfContact.date, 'yyyy.MM.dd');
                $scope.contactInteractionData.NextDateOfRequest = $filter('date')($scope.datePickers.nextDateOfRequest.date, 'yyyy.MM.dd');
                $scope.contactInteractionData.CompanyId = urlId;
                if (angular.isUndefined($scope.contactPerson) || $scope.contactPerson == null || $scope.contactPerson.Id == 0) {
                    toasterService.warningToast("Figyelem", "Nem adott meg kapcsolattartót!");
                    return;
                }
                $scope.contactInteractionData.ContactId = $scope.contactPerson.Id;
                $scope.contactInteractionData.TypeId = $scope.contactInteractionType.Id;
                $scope.contactInteractionData.ResultStatusId = $scope.contactInteractionResultStatus.Id;

                $scope.saveContactInteraction($scope.contactInteractionData);
            }

            $scope.Validate = function (contactInteractionData) {
                if (contactInteractionData.ContactId == null || contactInteractionData.ContactId == 0) {
                    toasterService.warningToast("Figyelem", "A kapcsolattartó kiválasztása kötelező!");
                    return false;
                }

                return true;
            }

            $scope.saveContactInteraction = function (contactInteractionData) {
                if (!confirm('Valóban menteni akarja a kapcsolatfelvételt?')) {
                    return;
                }

                if (!$scope.Validate(contactInteractionData)) {
                    return;
                }

                $scope.saveContactinteractionPost(contactInteractionData);
            }

            $scope.saveContactinteractionPost = function (data) {
                $http.post(apiurl.contactInteraction.saveContactInteractionUrl, data).success(function (data) {
                    toasterService.successToast("Mentés", "Kapcsolatfelvétel mentése sikeres!");
                    $scope.disabledElement = true;
                    urlId = data;
                }).error(function (data, status) {
                    toasterService.errorFromServerToast(data);
                });
            };
        } else {
            $scope.disabledElement = true;
            //Kapcsolatfelvétel visszanyitása
            $http.get(apiurl.contactInteraction.getContactInteractionUrl + urlId).success(function (data) {
                $scope.contactInteractionData = data.ContactInteraction;
                $scope.contactInteractionData.CompanyId = data.ContactInteraction.CompanyId;

                //interakció típus beállítása
                $scope.contactInteractionTypes = data.ContactInteractionTypes;
                angular.forEach($scope.contactInteractionTypes, function (value, key) {
                    if (value.Id === $scope.contactInteractionData.TypeId) {
                        $scope.contactInteractionType = value;
                    }
                });

                //interakció eredmény beállítása
                $scope.contactInteractionResultStatuses = data.ContactInteractionResultStatus;
                angular.forEach($scope.contactInteractionResultStatuses, function (value, key) {
                    if (value.Id === $scope.contactInteractionData.ResultStatusId) {
                        $scope.contactInteractionResultStatus = value;
                    }
                });

                //kapcsolattartó beállítása
                $scope.contactPersons = data.Contacts;
                angular.forEach($scope.contactPersons, function (value, key) {
                    if (value.Id === $scope.contactInteractionData.ContactId) {
                        $scope.contactPerson = value;
                    }
                });

                //dátumok beállítása
                $scope.datePickers.dateOfContact.date = $scope.contactInteractionData.DateOfContact;
                $scope.datePickers.nextDateOfRequest.date = $scope.contactInteractionData.NextDateOfRequest;
            }).error(function (data, status) {
                toasterService.errorFromServerToast(data);
            });
        }

        $scope.back = function () {
            location.href = siteurl.editPartner + '/' + $scope.contactInteractionData.CompanyId;
        };

        // New DatePicker settings
        $scope.today = function () {
            $scope.prdate2 = new Date();
            $scope.prdate2 = $filter('date')($scope.prdate2, 'yyyy.MM.dd');
        };
        $scope.today();

        $scope.clear = function () {
            $scope.prdate2 = null;
        };

        $scope.prdate2format = "yyyy.MM.dd";

        $scope.datePickers = {
            dateOfContact: { isOpen: false, date: new Date() },
            nextDateOfRequest: { isOpen: false, date: new Date() }
        };

        $scope.dtopen = function ($event, datePicker) {
            $event.preventDefault();
            $event.stopPropagation();

            if (!datePicker) {
                $scope.dtopened = true;

            } else {
                datePicker.isOpen = true;
            }
        };

        $scope.dtoptions = {
            startingDay: 1
        };
    }

})();
